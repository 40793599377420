import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformServer, isPlatformBrowser } from '@angular/common';
import { environment } from "src/environments/environment";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from "@angular/router";
import { AuthenticationService } from '../services/client/auth.service';
import { AuthenticationService as MemberAuthenticationService } from "../services/member/auth.services";
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService,
        private memberauthenticationservice:MemberAuthenticationService,
                @Inject(PLATFORM_ID) private platformId: any,
                private router:Router) { }
          
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            let error = "";
            const loginUrl = /rest-auth\/login/;
            if (err.status === 401) {
                // auto logout if 401 response returned from api
                
                if (isPlatformBrowser(this.platformId)) {
                    this.authenticationService.logout();
                    window.location.href = environment.loginPageUrl
                }
            }
            if(err.status === 403 && loginUrl.test(err['url'])){
                error = err.error.detail || err.error.message || err.statusText;
                this.memberauthenticationservice.accountdeactivated.next()
            }
            if(err.status === 403){
                error = err.error.detail || err.error.message || err.statusText;
                return throwError(error); 
            }
            try {
                error = err.error.message || err.statusText ;
            } catch (error) {
                
            }
            return throwError(error); 
        }));
    }
}
