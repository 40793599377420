
<ng-template #centerStripeModal let-modal="close">
    <div class="model-body p-1">
        <p><span (click)="modal('Cross click')" class="mdi mdi-close float-right font-20"></span></p>
        <app-ui-preloader [display]="loading"></app-ui-preloader>  
        <div class="p-2" [formGroup]="stripeForm">
            <label class="p-FieldLabel Label Label--empty">Nom et prénom</label>
            <input class="form-control mb-2"  i18n-placeholder placeholder="Nom et prénom" type="text" formControlName="name" />
            <label class="p-FieldLabel Label Label--empty">Email</label>
            <input class="form-control mb-2"  i18n-placeholder placeholder="Email" type="email" formControlName="email" />
            <ng-template [ngTemplateOutlet]="Stripe"  *ngIf="clientSecret"></ng-template>

            <button [ngClass]="{'btndisabled': stripeForm.invalid}" [disabled]="stripeForm.invalid" id="btnStripe" type="submit"  (click)="pay()">
                <span class="mr-1" i18n>Payer</span>
            </button>

            <div class="alert alert-danger mt-2 p-1" role="alert" *ngIf="cardError">
                <span class="mdi mdi-information-outline"></span>
                &nbsp;{{ cardError }}
            </div>
        </div>
        
    </div>

</ng-template>


<ng-template #Stripe>

    <ngx-stripe-payment [clientSecret]="clientSecret">
    </ngx-stripe-payment>
</ng-template>
















<!-- <ng-template #centerStripeModal let-modal="close">
    <div class="model-body p-1">
        <p><span (click)="modal('Cross click')" class="mdi mdi-close float-right font-20"></span></p>
        <app-ui-preloader [display]="loading"></app-ui-preloader>
        <div class="text-center mt-3"> <img class="icon-price" src="assets/images/companies/paiement.png"> </div>
        <p class="mb-0 pl-3 pt-1" i18n>Transfert d'argent sécurisé en utilisant votre compte bancaire. 
            Nous prenons en charge Mastercard, Visa, Discover et Stripe.</p>
        <form class="checkout" id="payment-form" >
            <label for="card-info" i18n>Paiement par carte bancaire</label>
        <div id="form-field">
                <div id="card-info" #cardInfo></div>
                <button id="submit-button" type="submit" >
                    <span class="mr-1" i18n>Payer</span> {{amount | currency:currency}}
                </button>
                <div class="alert alert-danger mt-2 p-1" role="alert" *ngIf="cardError">
                    <span class="mdi mdi-information-outline"></span>
                    &nbsp;{{ cardError }}
                </div>
            </div>
        </form>
    </div>

</ng-template> -->
