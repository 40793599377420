import {Company} from './company';
import { Site } from "./site";
import { Plan, PlanPricing, UserPlan } from "./plan.models";

export class User {
    id: number;
    username?: string;
    password: string;
    first_name: string;
    last_name: string;
    token?: string;
    app_key:string;
    secret_key:string;
    email: string;
    companies: Array<Company> = [];
    profile:Profile
    is_verify:boolean
    constructor(data?:User){
        if (data != undefined && data != null){
            this.id = data.id
            this.email = data.email
            this.first_name = data.first_name
            this.last_name = data.last_name
            this.is_verify = data.is_verify
        }
       

    }

    setCompany(company:Array<Company>){
        if(company){
            company.forEach(element => {
                let item = new Company()
                item.address = element.address
                item.company_name =  element.company_name
                item.id = element.id
                item.phone = element.phone
                item.email = element.email
                item.siret =  element.siret
                item.sites = element.sites 
                item.selected = element.selected   
                if(element.plan){
                    let planpricing = new PlanPricing(element.plan.details)
                    let plan = new UserPlan(element.plan)
                    plan.details = planpricing
                    item.plan = plan
                }        
               
                this.companies.push(item)
    
            });
        }
       


    }


    public getSites():Site[]{
        let sites = []
        this.companies.forEach(element => {
            for (let index = 0; index < element.sites.length; index++) {
                var site = element.sites[index];
                let is_not_in = true;
              
                for (let index = 0; index < sites.length; index++) {
                    if(site.id == sites[index].id){
                        is_not_in = false;
                        break
                    }
                    
                }
                if(is_not_in){
                    site.company = element
                    sites.push(site)
                }
                
                
            }
          });
        return sites
    }

    public selectSite(id:number){
        let found = false;
        this.companies.forEach(element => {
            for (let index = 0; index < element.sites.length; index++) {
                if(element.sites[index].id == id){
                    element.sites[index].selected = true;
                    found = true;
                }else{
                    element.sites[index].selected =false
                }
            }
        });
        if(!found){
            this.selectfirstSite()
        }
    }

    public selectCompany(id:number){
        let found = false;
        for (let index = 0; index <  this.companies.length; index++) {
            if(this.companies[index].id == id){
                this.companies[index].selected = true;
                found = true;
            }else{
                this.companies[index].selected =false
            }
        }
        if(!found){
            this.selectfirstCompany()
        }
    }

    public selectfirstCompany(){

        for (let index = 0; index < this.companies.length; index++) {
            const element = this.companies[index]
            element.selected = true
            break
        }
        

     
    }



    public getselectCompany():Company{
        let selectcompany;
        for (let index = 0; index < this.companies.length; index++) {
            const element = this.companies[index];
            if(element.selected  == true ){
                selectcompany= element
                break
            }
            
        }
        return selectcompany

    }

    public selectfirstSite(){
        
        for (let index = 0; index < this.companies.length; index++) {
            const element = this.companies[index]
            element.sites[0].selected = true
            break
        }
      
    }
    public getselectSite():Site{
        let selectSite;
        this.companies.forEach(element => {

            for (let index = 0; index < element.sites.length; index++) {
                if(element.sites[index].selected  == true ){
                    selectSite= element.sites[index]
                    break;
                }
        
            }
        });
        return selectSite

    }



}

export class Profile {
    id:number
    first_name: string ;
    last_name: string ;
    username: string;
    is_client:boolean;
    is_member:boolean;
    avatar:string


    constructor(data?){
        if (data != undefined && data != null){
            this.id =  data.id
            this.first_name=  data.first_name
            this.last_name=  data.last_name
            this.username=  data.username
            this.is_client=  data.is_client
            this.is_member=  data.is_member
            this.avatar =data.avatar
        }
       
    }

  }
  


export  class LoginData{
    key:string
    user:User
    profile:Profile
    company:Array<Company>
}