import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule,LOCALE_ID, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import { SentryService } from "src/app/core/services/sentry/sentry.service";
import localeFr from '@angular/common/locales/fr';
import { environment } from "src/environments/environment";
import { ErrorInterceptor } from './core/helpers/error.interceptor';
import { JwtInterceptor } from './core/helpers/jwt.interceptor';
import { TransferHttpCacheModule} from '@nguniversal/common';
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import { FacebookLoginProvider, GoogleLoginProvider} from 'angularx-social-login';
import { LayoutsModule } from './certishopping/layouts/layouts.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { LazyLoadImageModule, LAZYLOAD_IMAGE_HOOKS, ScrollHooks } from 'ng-lazyload-image';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { ActivateAccountComponent } from "src/app/certishopping/members/auth/activate-account/activate-account.component";
registerLocaleData(localeFr);

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json?cb='+ new Date().getTime());
}



@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
   // BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserModule.withServerTransition({ appId: 'angular' }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
          useFactory: createTranslateLoader, // exported factory function needed for AoT compilation
          deps: [HttpClient]
      }
    }),
    BrowserAnimationsModule,
    HttpClientModule,
    LayoutsModule,
    AppRoutingModule,
    TransferHttpCacheModule,
    SocialLoginModule,
    LazyLoadImageModule,
    NgbModalModule
  ],

  providers: [
    
    
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    
    {provide:LOCALE_ID,useValue:"fr-FR"},
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(`${environment.fbAppId}`),
          },
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(`${environment.googleAppId}`),
          },
        ],
      } as SocialAuthServiceConfig,
    },
    {provide: ErrorHandler, useClass: SentryService},
    { provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks },
  ],
  entryComponents:[ActivateAccountComponent],
  bootstrap: [AppComponent]
})
export class AppModule {

 }

