import { ChangeDetectorRef, Component,OnInit, ElementRef, Output, ViewChild, Input, EventEmitter} from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { StripeService,StripePaymentElementComponent  } from 'ngx-stripe';
import { FormBuilder, Validators } from '@angular/forms';
import { User } from "src/app/core/models/auth.models";
import {
  StripeElementsOptions,
  PaymentIntent,
  
} from '@stripe/stripe-js';


@Component({
  selector: 'app-stripe',
  templateUrl: './stripe.component.html',
  styleUrls: ['./stripe.component.scss']
})
export class StripeComponent implements OnInit {
  loading = false 
  @Input() User:User;
  @Input() amount:number;
  @Input() currency:string
  @Input()clientSecret;
  activeModal:NgbActiveModal;
  cardError:string;
  @ViewChild("centerStripeModal",{static:false}) StripeModal;
  @Output() onSuccessEvent=  new EventEmitter<any>();

  @ViewChild(StripePaymentElementComponent,{static:false}) paymentElement: StripePaymentElementComponent;
  elementsOptions: StripeElementsOptions = {
    locale: 'auto'
  };

  stripeForm = this.fb.group({
    name: ['', [Validators.required]],
    email:['', [Validators.required]],
  });

  constructor( private cd: ChangeDetectorRef, private modalService:NgbModal,private fb: FormBuilder,
               private stripeService: StripeService) { }

  ngOnInit() {
  }

  checkout() {
    // Check the server.js tab to see an example implementation
    if(this.activeModal){this.activeModal.dismiss()}
    this.activeModal = this.modalService.open(this.StripeModal, { centered: true, });
  }

  ngOnChanges(changes: any): void {
    if (changes.User){
      if(changes.User.currentValue){
        this.stripeForm.controls.email.setValue(changes.User.currentValue.email)
      }
   
    }
  }

  pay() {
      this.loading = true
      this.stripeService.confirmPayment({
        elements: this.paymentElement.elements,
        confirmParams: {
          payment_method_data: {
            billing_details: {
              name: this.stripeForm.get('name').value,
              email: this.stripeForm.get('email').value
            }
          }
        },
        redirect: 'if_required'
      }).subscribe(result => {
        this.loading = false
        if (result.error) {
          this.cardError = result.error.message;
        } else {
          this.cardError = null
          if (result.paymentIntent.status === 'succeeded') {
            window.location.reload()
          }
        }
      });
  }


}
