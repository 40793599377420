import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDropdownModule,NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

import { UIModule } from '../shared/ui/ui.module';
import { LayoutComponent } from './layout.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { TopbarComponent } from './topbar/topbar.component';
import { FooterComponent } from './footer/footer.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { PaymentModule } from "src/app/certishopping/pages/payment/payment.module";
import { CoreModule } from "src/app/core/core.module";
import { InvoiceAlertComponent } from "./invoice-alert/invoice-alert.component";
import { IntercomModule } from 'ng-intercom';
@NgModule({
  declarations: [LayoutComponent, SidebarComponent, TopbarComponent, FooterComponent, InvoiceAlertComponent],
  imports: [
    CommonModule,
    RouterModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgbModalModule,
    UIModule,
    PaymentModule,
    NgSelectModule,
    FormsModule, ReactiveFormsModule ,
    CoreModule,
    IntercomModule.forRoot({
      appId: 'zcq5qlr4', 
      updateOnRouterChange: true
    })
  ]
})
export class LayoutsModule { }
