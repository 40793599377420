<!-- Topbar Start -->
<div class="navbar-custom  d-print-none">
  <ul class="list-unstyled topnav-menu float-right mb-0">

    <li class="d-none d-sm-block" *ngIf="UserPlan?.details.plan_type =='FREE' && UserPlan?.active">
      <a class="btn btn-warning mt-3 mr-2 p-0 pl-1 pr-1" [routerLink]="[ '/pricing/upgrade']" >
        <span class="mdi mdi-arrow-up-bold-hexagon-outline mr-1"></span>
        <span i18n>Optimiser votre pack</span>
      </a>
    </li>

    <li class="d-none d-sm-block" *ngIf="UserPlan && !UserPlan?.active">
      <a class="btn btn-warning mt-3 mr-2 p-0 pl-1 pr-1" [routerLink]="[ '/pricing/invoices']">
        <i class="mdi mdi-alert-outline mr-1"></i>
        <span i18n>Plan désactivé</span>
      </a>
    </li>


    <li class="nocaret dropdown  topbar-dropdown" ngbDropdown>
      <a class="nav-link dropdown-toggle arrow-none waves-effect waves-light" ngbDropdownToggle id="local"
          href="javascript: void(0);" role="button" aria-haspopup="false" aria-expanded="false">
          <!-- <img *ngIf="'fr' == CurentLocale" src="assets/images/flag/fr.png" alt="user-image"  height="16">
          <img *ngIf="'en' == CurentLocale" src="assets/images/flag/en.png" alt="user-image"  height="16"> -->
          <span class="mdi mdi-translate"></span>
        </a>
        <div class="dropdown-menu dropdown-menu-right"  x-placement="bottom-end"
              aria-labelledby="local" ngbDropdownMenu>

              <a href="javascript:void(0);" (click)="switchLang('fr')"  class="dropdown-item notify-item">
                <span  class="ml-1"  [ngClass]="{'text-success': 'fr' == CurentLocale}" i18n>Français (France)</span>
              </a>
              <a href="javascript:void(0);" (click)="switchLang('en')" class="dropdown-item notify-item">
                <span class="ml-1" i18n [ngClass]="{'text-success': 'en' == CurentLocale}">English (US)</span>
              </a>

        </div>
    </li>



    <li class=" nocaret dropdown notification-list" ngbDropdown>
      <a class="nav-link dropdown-toggle  waves-effect waves-light" href="javascript: void(0);" role="button"
        aria-haspopup="false" aria-expanded="false" ngbDropdownToggle id="notificationDropdown">
        <i class="mdi mdi-bell-outline noti-icon"></i>
        <span *ngIf="Unread != 0" class="badge badge-danger rounded-circle noti-icon-badge">{{Unread}}</span>
      </a>
      <div class="dropdown-menu dropdown-menu-right dropdown-menu-animated dropdown-lg"
        aria-labelledby="notificationDropdown" ngbDropdownMenu>

        <!-- item-->
        <div class="dropdown-item noti-title" ngbDropdownItem>
          <h5 class="m-0">
            <span class="float-right">
              <a (click)="mark_all_as_read()" class="text-dark">
                <small i18n>Lis Tout</small>
              </a>
            </span><span i18n>Notification</span>
          </h5>
        </div>

        <div id="notification-items" class="slimscroll noti-scroll" appSlimScroll>
          <!-- item-->
          <a (click)="redirectTo(notification)" *ngFor="let notification of notificationData;"
            class="dropdown-item notify-item  " ngbDropdownItem [ngClass]="{'active': notification?.unread}">

            <div *ngIf="notification?.data?.category=='Video'" class="notify-icon bg-soft-info text-info">
              <i class="mdi mdi-video"></i>
            </div>

            <div *ngIf="notification?.data?.category=='Review'" class="notify-icon bg-soft-info text-info">
              <i class="mdi mdi-message-bulleted"></i>
            </div>
            <div *ngIf="notification?.data?.category=='Like'" class="notify-icon bg-soft-info text-info">
              <i class="mdi mdi-thumb-up-outline"></i>
            </div>
            <div *ngIf="notification?.data?.category=='Helpful'" class="notify-icon bg-soft-info text-info">
              <i class="mdi mdi-flag-variant"></i>
            </div>

            <div *ngIf="notification?.data?.category=='Comment'" class="notify-icon bg-soft-info text-info">
              <i class="mdi mdi-comment-multiple-outline"></i>
            </div>
            <div *ngIf="notification?.data?.category=='Invoice'" class="notify-icon bg-soft-info text-info">
              <i class="mdi mdi-file-document-box"></i>
            </div>

            <div *ngIf="notification?.data?.category=='Template_settings'" class="notify-icon bg-soft-danger text-danger">
              <i class="mdi mdi-email-alert"></i>
            </div>

            <div *ngIf="notification?.data?.category=='Manual_Invitation_Sent'" class="notify-icon bg-soft-success text-success">
              <i class="mdi mdi-email-check-outline"></i>
            </div>

            <div *ngIf="notification?.data?.category=='Manual_Invitation_Fail'" class="notify-icon bg-soft-danger text-danger">
              <i class="mdi mdi-email-alert"></i>
            </div>

            <p class="notify-details" *ngIf="notification?.data?.category!='Invoice' && notification?.data?.category!='Template_settings' && notification?.data?.category!='Video'">
              <span class="font-weight-bold">{{ notification?.actor?.name }}</span> {{ notification?.verb }}
              <small class="text-muted" >{{ notification?.timestamp }}</small>
            </p>
            <p class="notify-details" *ngIf="notification?.data?.category=='Invoice' || notification?.data?.category=='Template_settings' || notification?.data?.category=='Video'">
              {{ notification?.verb }}
              <small class="text-muted" >{{ notification?.timestamp }}</small>
            </p>
          </a>

        </div>

  
      </div>
    </li>


    <li class="nocaret dropdown notification-list" ngbDropdown>
      <a class="nav-link dropdown-toggle mr-0 waves-effect waves-light" ngbDropdownToggle id="profileDropdown"
        href="javascript: void(0);" role="button" aria-haspopup="false" aria-expanded="false">
        <span class="mdi mdi-account-circle-outline "></span> 
      </a>
      <div class="dropdown-menu dropdown-menu-right profile-dropdown" aria-labelledby="profileDropdown" ngbDropdownMenu>
       
        <a [routerLink]="[ '/settings/profile']" class="dropdown-item notify-item">
          <i class="far fa-user font-15 mr-2"></i>
          <span i18n class="mt-1">Paramètres du compte</span>
        </a>


        <a [routerLink]="[ '/settings/personalization']" class="dropdown-item notify-item">
          <i class="mdi mdi-store font-15 mr-2"></i>
          <span i18n class="mt-1">Paramètres du site</span>
        </a>

        <div class="dropdown-divider"></div>


        <a href="javascript:void(0);" class="dropdown-item notify-item" (click)="logout()">
          <i class="mdi mdi-logout mr-2"></i>
          <span i18n>Déconnexion</span>
        </a>

      </div>
    </li>


  </ul>

  <div class="logo-box">
    <a [routerLink]="[ '/dashboard']" class="logo text-center">
      <span class="logo-lg">
        <img src="assets/images/certishopping-logo-white.png" alt="Certishopping" width="120">
      </span>
      <span class="logo-sm">
        <img src="assets/images/favicon.png" alt="" width="40" >
      </span>
    </a>
  </div>

  <ul class="list-unstyled topnav-menu topnav-menu-left m-0">
    <li>
      <button class="button-menu-mobile waves-effect waves-light" (click)="toggleMobileMenu($event)">
        <i class="mdi mdi-menu"></i>
      </button>
    </li>

    <!-- <li class="dropdown d-none d-lg-block" ngbDropdown>
      
        <a class="nav-link dropdown-toggle waves-effect waves-light dropdown-site" href="javascript: void(0);"
        ngbDropdownToggle id="extraMenu" role="button"
         aria-haspopup="false" aria-expanded="false">
         <span class="mdi mdi-store mr-1"></span> <span class="mr-1">{{Site?.trademark | titlecase}}</span>
        
       </a>
     
     
      
      <div class="dropdown-menu" ngbDropdownMenu aria-labelledby="extraMenu">
 
        <a (click)="ChangeSite(item)"   href="javascript:void(0);" class="dropdown-item" *ngFor="let item of Company?.sites">
          <span class="mdi mdi-check-circle" [hidden]="!item?.selected" [ngClass]="{'text-success': item.selected}" >
          </span> 
          <span class="mdi mdi-check-circle"  [hidden]="item.selected" >
          </span> 
          {{item?.trademark}}
        </a> 

        <div class="dropdown-divider"></div>
        <a class="dropdown-item" href="javascript:void(0);">{{Company?.company_name | titlecase}}</a>

      </div>
    </li> -->

    <li class="dropdown d-none d-lg-block" ngbDropdown>
      <form class="app-site-choice" [formGroup]="siteForm">
        <div class="app-site-choice-box ">
          <div class="form-group mb-0"  >
            <ng-select [items]="siteList"   [searchable]="false" [clearable]="false" formControlName="siteId" bindLabel="trademark" bindValue="id">
              <ng-template ng-option-tmp let-item="item" let-index="index">
                  <div class="p-1">
                    <i class="mdi mdi-store" ></i>
                    <span class="site-trademark font-weight-bold ml-1">{{item?.trademark }}</span>
                    <p ><small>{{item?.company?.company_name }}</small></p>
                  </div>
              </ng-template>
          </ng-select>



          </div>
        </div>
      </form>
      
     
    </li>

  </ul>
</div>
<!-- end Topbar -->