<ng-template #PermissionModal let-modal="close">
    <div class="modal-body" *ngIf="option">
        <app-ui-preloader [display]="loading"></app-ui-preloader>
        <div class="d-flex justify-content-end">
            <button  type="button" class="close" (click)="close()" aria-hidden="true">×</button>
        </div>
        <div class="mt-2">
            <ng-template [ngTemplateOutlet]="PermissionTemplate"></ng-template>
            <ng-template [ngTemplateOutlet]="FormTemplate"></ng-template>
            
        </div>
    </div>
</ng-template>
<ng-template #FormTemplate>
    <div class="d-flex justify-content-end mt-2 mb-2">
        <button (click)="close()" class="btn btn-blue ml-1"  i18n>Ok</button> 
    </div>
</ng-template>


<ng-template #PermissionTemplate>
        <h3>
            <span  class="mdi mdi-shield-account text-danger"></span>
            <span class="text-muted ml-1" i18n>Accès refuse</span>
        </h3>
        <h5 class="mb-4" i18n>Vous n'avez pas la permission d'effectuer cette action. Contactez l'administrateur pour obtenir de l'assistance</h5>

</ng-template>
