<div id="wrapper">

    <app-topbar (settingsButtonClicked)="onSettingsButtonClicked()"
    (mobileMenuButtonClicked)="onToggleMobileMenu()"></app-topbar>

    <app-sidebar [isCondensed]="isCondensed" (sidebarClicked)="onToggleMobileMenu()"></app-sidebar>

    <div class="content-page">
        <app-invoice-alert></app-invoice-alert>
        <div class="content">
            <!-- content -->
            <router-outlet></router-outlet>
        </div>

        <!-- footer -->
        <app-footer></app-footer>
    </div>
</div>

<app-upgrade-dialog></app-upgrade-dialog>
<app-active-dialog></app-active-dialog>
<app-permissions></app-permissions>