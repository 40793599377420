import { Component, OnInit } from '@angular/core';
import { HttpParams } from "@angular/common/http";
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/core/services/client/api.service';
import { AuthenticationService } from "src/app/core/services/client/auth.service";
import { Site } from 'src/app/core/models/site';
import { environment } from "src/environments/environment";
import { User } from "src/app/core/models/auth.models";
import {TranslateService} from '@ngx-translate/core';
@Component({
  selector: 'app-invoicelists',
  templateUrl: './invoicelists.component.html',
  styleUrls: ['./invoicelists.component.scss']
})
export class InvoicelistsComponent implements OnInit {
  breadCrumbItems: Array<{}>;
  paginatedData: Array<any>;
  billingData:Array<any>
  submitted: boolean;
  User:User;
  Site:Site
  // page number
  page = 1;
  offset:number=0
  limit:number=15
  totalSize = 0;
  loading = false;
  // validation form
  validationform: FormGroup;
  TRANSLATEMESSAAGE:Array<any>
  constructor(private authenticationService:AuthenticationService,
    public formBuilder: FormBuilder,
    private translate:TranslateService,
    private modalService: NgbModal,
    private apiService:ApiService) { 
      this.User = this.authenticationService.currentUser()
      this.Site = this.User.getselectSite()

    }

  ngOnInit() {
    this.translate.get(['billing.label-1','billing.label-2']).subscribe((data:any)=> {
      this.TRANSLATEMESSAAGE = data
        // tslint:disable-next-line: max-line-length
        this.breadCrumbItems = [{ label: this.TRANSLATEMESSAAGE["billing.label-1"], path: '/' }, 
        { label: this.TRANSLATEMESSAAGE["billing.label-2"], path: '/', active: true }];
       
    })
       this._fetchData() 
  }


    /**
   * Modal Open
   * @param content modal content
   */
     openModal(content: string) {
      this.modalService.open(content, { centered: true });
    }
     /**
   * save the contacts data
   */
      saveData() {
        const name = this.validationform.get('name').value;
        const phone = this.validationform.get('phone').value;
        const location = this.validationform.get('location').value;
        const email = this.validationform.get('email').value;
        const currentDate = new Date();
    
        if (this.validationform.valid) {
        
        }
        this.submitted = true;
       
      }
    
      /**
       * paginatio onchange event
       * @param page page
       */
      onPageChange(page: any): void {
        this.offset = this.limit * page
        this._fetchData()
     
      }
      /**
       * fetches the customer value
       */
      private _fetchData() {
        if(!this.Site){return}
        let params = new HttpParams()
        params=params.set('limit',this.limit.toString())
        params=params.set('offset',this.offset.toString())
        params=params.set('site',this.Site.id.toString())
        this.loading = true
        this.apiService.billinglist(params).subscribe(
          data => {
            this.loading = false;
            this.totalSize = data['count'];
            this.billingData = data['results']
          },error => {
            this.loading = false;
    
          })
    
       
      }
    
}
