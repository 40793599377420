import { Component, OnInit, AfterViewInit,Inject, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';
import { CookieService } from "src/app/core/services/cookie.service";
import {TranslateService} from '@ngx-translate/core';
import { DOCUMENT, Location } from '@angular/common';
import { environment } from "src/environments/environment";
@Component({
  selector: 'app-ubold',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

    constructor( private translate:TranslateService,
      @Inject(PLATFORM_ID) private platformId: any,
      @Inject(DOCUMENT) private _document: any,
      private cookieservice:CookieService,private meta: Meta,) {
 
      this.translate.addLangs(['fr', 'en']);
      let local = this.cookieservice.getCurentLocale()
      if (isPlatformBrowser(this.platformId)) {
        const baseUrl = window.location.pathname;
        const matches = baseUrl.match(/^\/([a-z]{2}(?:-[A-Z]{2})?)\//);
        local = (matches && environment.supportedLocales.indexOf(matches[1]) !== -1) ? matches[1] : 'fr';
        this._document.documentElement.lang = local
        this.cookieservice.setCookie('Language', local,30)

      }else{
        const baseUrl = this._document.location.pathname;
        const matches = baseUrl.match(/^\/([a-z]{2}(?:-[A-Z]{2})?)\//);
        local = (matches && environment.supportedLocales.indexOf(matches[1]) !== -1) ? matches[1] : 'fr';
        this._document.documentElement.lang = local
      }
      if (local){
        this.translate.use( local).subscribe(() => {
          let tag = {"name": "description", "content": this.translate.instant('meta.description')}
          let ogTag = {"property": "og:description", "content": this.translate.instant('meta.description')}
          if(!this.meta.updateTag(tag)){
            this.meta.addTag(tag);
          }
          if(!this.meta.updateTag(ogTag)){
            this.meta.addTag(ogTag);
          }
        });
      }else{
        this.translate.use( 'fr').subscribe(() => {
          let tag = {"name": "description", "content": this.translate.instant('meta.description')}
          let ogTag = {"property": "og:description", "content": this.translate.instant('meta.description')}
          if(!this.meta.updateTag(tag)){
            this.meta.addTag(tag);
          }
          if(!this.meta.updateTag(ogTag)){
            this.meta.addTag(ogTag);
          }
        });
      }
     

      // 

      // the lang to use, if the lang isn't available, it will use the current loader to get them
      
  }
}
