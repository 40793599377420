import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { AuthenticationService } from "src/app/core/services/client/auth.service";
import { Site } from 'src/app/core/models/site';
import { environment } from "src/app/../environments/environment";
import { User } from "src/app/core/models/auth.models";
import { isPlatformBrowser } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { ApiService } from 'src/app/core/services/client/api.service';
@Component({
  selector: 'app-invoice-alert',
  templateUrl: './invoice-alert.component.html',
  styleUrls: ['./invoice-alert.component.scss']
})
export class InvoiceAlertComponent implements OnInit {
  User:User;
  Site:Site;
  show:boolean=false;
  constructor( private authenticationService:AuthenticationService,private activatedRoute:ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: any, 
    private apiService:ApiService,
    private router:Router) {
    this.User = this.authenticationService.currentUser()
    this.Site = this.User.getselectSite()


    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url.includes(environment.invoicePageUrl)){ this.show = false}else{
          if (isPlatformBrowser(this.platformId) && this.Site) {
            this.fetchData()
      
          }
        }
       
      }
    });
    
  }

  ngOnInit() {
    
    
  }


  fetchData(){
    let params = new HttpParams()
    params= params.set('site',this.Site.id.toString())
    this.apiService.invoice_notPayed(params).subscribe(
    result => {
        if(result){
          this.show = true;
        }else{
          this.show = false;
        }
    },error => {})
  }

}
