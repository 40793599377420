import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PlansComponent } from "./plans/plans.component";
import { InvoicelistsComponent } from "./invoicelists/invoicelists.component";
import { InvoiceComponent } from "./invoice/invoice.component";
const routes: Routes = [
  {
    path: 'invoices/:id',
    component: InvoiceComponent
  },
  {
    path: 'invoices',
    component: InvoicelistsComponent
  },  
  
  {
    path: 'upgrade',
    component: PlansComponent
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PaymentRoutingModule { }
