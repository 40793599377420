import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import {NgbModal, NgbActiveModal, NgbModalConfig} from '@ng-bootstrap/ng-bootstrap';
import { UpgradePlansService } from "src/app/core/services/plan/upgrade.plans.service";
import { Subscription } from "rxjs";
import { ApiService } from 'src/app/core/services/client/api.service';
import { User } from 'src/app/core/models/auth.models';
import { Site } from 'src/app/core/models/site';
import Swal from 'sweetalert2';
import { AuthenticationService } from 'src/app/core/services/client/auth.service';
@Component({
  selector: 'app-upgrade-dialog',
  templateUrl: './upgrade-dialog.component.html',
  styleUrls: ['./upgrade-dialog.component.scss'],
  providers: [NgbModalConfig, NgbModal]
})
export class UpgradeDialogComponent implements OnInit {
  activeModal:NgbActiveModal= null;
  subscription:Subscription;
  contactform: FormGroup; 
  loading:boolean=false;
  option:string;
  Site:Site;
  User :User;
  @ViewChild('UpgradeModal',{static:false}) UpgradeModal: any;
  constructor(private modalService: NgbModal,config: NgbModalConfig, private apiService:ApiService,
    private upgradePlansService:UpgradePlansService,
    public formBuilder: FormBuilder,
    private authenticationService:AuthenticationService) { 

      this.User = this.authenticationService.currentUser();
      this.Site= this.User.getselectSite(); 
      config.backdrop = 'static';
      config.keyboard = false;

      this.upgradePlansService.openDialog.subscribe(
        (option)=>{
          this.option = option;
          this.open()
        }
      )
      this.contactform = this.formBuilder.group({
        firstName: ['', [Validators.required]],
        lastName: ['', [Validators.required]],
        email: ['', [Validators.required, Validators.email]],
        phone: ['', [Validators.required]],
      });
  }
  ngOnInit() {
  }
  ngOnDestroy(): void {
    if(this.subscription){
      this.subscription.unsubscribe();
    }  
  }
  open() {
    if(this.activeModal == null){
      this.activeModal = this.modalService.open(this.UpgradeModal, { centered: true, windowClass: 'upgrade-dialog'});
    }

  }
  close() {
    this.activeModal.close();
    this.activeModal = null;
  }
  contactSubmit(){
    this.loading = true;
    let params =  this.contactform.value
    params['site'] =  this.Site.id
    this.apiService.ContactSupport(params).subscribe(
      data => {
        this.close();
        this.loading = false;
        if (data['message'] !=  undefined){
          Swal.fire({
            text: data['message'],
            type:"success",
            showCancelButton: false,
         })
        }
      },error=>{
        this.loading = false

  
    })
  }
  
}
