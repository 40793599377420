import { Component, OnInit, AfterViewInit, PLATFORM_ID,Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { CookieService } from "src/app/core/services/cookie.service";
import {TranslateService} from '@ngx-translate/core';
import { User,Profile } from "src/app/core/models/auth.models";
import { AuthenticationService } from 'src/app/core/services/client/auth.service';
import { Intercom } from 'ng-intercom';
// declare global {
//   interface Window {
//     Intercom:any;
//   }
// }
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})

export class LayoutComponent implements OnInit, AfterViewInit {

  isCondensed = false;
  User:User;
  constructor(@Inject(PLATFORM_ID) private platformId: any,
  private translate: TranslateService, private authenticationService:AuthenticationService,
                private CookieService:CookieService,  public intercom: Intercom) { 
     this.User = this.authenticationService.currentUser();
     if(this.User){
      let name = ""
      if(this.User.last_name && this.User.first_name){
        name = `${this.User.first_name} ${this.User.last_name}`
      }
      // window.Intercom('boot', {
      //   app_id: 'zcq5qlr4',
      //   email: this.User.email,
      //   name: name,
      // });
     }
     this.translate.addLangs(['fr', 'en']);
     if (isPlatformBrowser(this.platformId)) {
      this.translate.use(this.CookieService.getCurentLocale());
     }else{
      this.translate.use('fr');
     }
    
  }

  ngOnInit() {

    this.intercom.boot({
      app_id: "zcq5qlr4",
      email: this.User.email,
      name: name,
      widget: {
        "activator": "#intercom" 
      }
    });

  }

  isMobile() {
    const ua = navigator.userAgent;
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua);
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      document.body.classList.remove('authentication-bg');
      document.body.classList.remove('authentication-bg-pattern');
  
      if (!this.isMobile()) {
        document.body.classList.add('sidebar-enable');
      }

    }
   
  }

  /**
   * on settings button clicked from topbar
   */
  onSettingsButtonClicked() {
    if (isPlatformBrowser(this.platformId)) {
      document.body.classList.toggle('right-bar-enabled');
    }
  
  }

  /**
   * On mobile toggle button clicked
   */
  onToggleMobileMenu() {
    if (isPlatformBrowser(this.platformId)) {
      document.body.classList.toggle('sidebar-enable');
      if (!this.isMobile()) {
        document.body.classList.toggle('enlarged');
        this.isCondensed = !this.isCondensed;
      }
    }
   
  }
}
