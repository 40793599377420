import { User } from "./auth.models";
import { Company } from "./company";

export const PlanType = {
    'free':'FREE', 
    'booster':'BOOSTER', 
    'pro':'PRO',
    'enterprise': 'ENTREPRISE', 
    'starter': 'STARTER',
    'seo': 'SEO',
    'sea': 'SEA',
    'web': 'WEB'
}

export class Option{
    code:string
    name:string
    limit:number
}


export class Plan{
    
    id:number
    name:string
    type:string
    options:Array<Option>
    description:string
    available:boolean
    visible:boolean
    created_at:string


}

export class Period{
    id:number
    interval_count:number
    interval:string
}

export class PlanPricing {

    plan_type:string
    period:Period
    trial_period_days:number
    price:number
    currency:string
    max_number_invitation:number
    orders_monthly?:number
    options:Array<Option>
    auto_renewal:Boolean

    constructor(data=null){

        if (data!= null && data!=undefined){
            this.plan_type = data.plan_type
            this.period =  data.period
            this.trial_period_days = data.trial_period_days
            this.price = data.price
            this.currency = data.currency
            this.max_number_invitation = data.max_number_invitation
            this.options = data.options
            this.orders_monthly = data.orders_monthly
            this.auto_renewal = data.auto_renewal
        }
       
    }

}

export class Order{
    amount: string
    company: number
    currency: string
    discount: string
    discount_amount: string
    plan: null
    plan_pricing: PlanPricing
    reference: null
    status: 1
    tax: string
    tax_amount: string
    total: string
}

export class UserPlan{
    id?:number
    details:PlanPricing
    company:number;
    nbr_mail_used:number;
    start:string;
    expire:string;
    active:boolean;
    demo:boolean;
    use_limit:boolean;
    percent_nbr_mail_used:number;

    constructor(data=null){
        if (data != null && data != undefined){
            this.id =  data.id
            this.details = data.details
            this.company = data.company
            this.nbr_mail_used = data.nbr_mail_used
            this.start = data.start
            this.expire =  data.expire
            this.active =  data.active
            this.demo =  data.demo
            this.use_limit = data.use_limit
            this.percent_nbr_mail_used = data.percent_nbr_mail_used
        }
      
    }

}

export class Details{

    address: string
    city: string
    company: string
    country: string
    email: string
    id: string
    last_name: string
    name: string
    payment: string
    phone: string
    state: string
    vat_number: string
    zipcode: string
    siret:string
    display_name:boolean
}

export class Billing{
    id:number;
    reference:string
    selling_date:string
    payment_date:string
    amount:number
    tax:number
    tax_amount:number
    discount:number
    discount_amount:number
    total:number
    currency:string
    status:string
    details:Details
    plan_type:string
    order:Order
    expired_at?:string
    options?:Array<Option>
   
}