import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import {NgbModal, NgbActiveModal, NgbModalConfig} from '@ng-bootstrap/ng-bootstrap';
import { PermissionsService } from "src/app/core/services/permissions/permissions";
import { Subscription } from "rxjs";
import { ApiService } from 'src/app/core/services/client/api.service';
import { User } from 'src/app/core/models/auth.models';
import { Site } from 'src/app/core/models/site';
import Swal from 'sweetalert2';
import { AuthenticationService } from 'src/app/core/services/client/auth.service';
@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.scss']
})
export class PermissionsComponent implements OnInit {
  activeModal:NgbActiveModal= null;
  subscription:Subscription;
  contactform: FormGroup; 
  loading:boolean=false;
  option:string;
  Site:Site;
  User :User;
  @ViewChild('PermissionModal',{static:false}) permissionModal: any;
  constructor(private modalService: NgbModal,config: NgbModalConfig, private apiService:ApiService,
    private permissionsService:PermissionsService,
    public formBuilder: FormBuilder,
    private authenticationService:AuthenticationService) { 
      this.User = this.authenticationService.currentUser();
      this.Site= this.User.getselectSite(); 
      config.backdrop = 'static';
      config.keyboard = false;

      this.permissionsService.openDialog.subscribe(
        (option)=>{
          this.option = option;
          this.open()
        }
      )
    }

    ngOnInit() {
    }
    ngOnDestroy(): void {
      if(this.subscription){
        this.subscription.unsubscribe();
      }  
    }
    open() {
      if(this.activeModal == null){
        this.activeModal = this.modalService.open(this.permissionModal, { centered: true, windowClass: 'upgrade-dialog'});
      }
  
    }
    close() {
      this.activeModal.close();
      this.activeModal = null;
    }
}
