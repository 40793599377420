import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDropdownModule,NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { UpgradeDialogComponent } from "src/app/core/dialog/upgrade-dialog/upgrade-dialog.component";
import { ActiveDialogComponent } from "src/app/core/dialog/active-dialog/active-dialog.component";
import { PermissionsComponent } from "src/app/core/dialog/permissions/permissions.component";
import { UIModule } from 'src/app/certishopping/shared/ui/ui.module';
@NgModule({
  declarations: [UpgradeDialogComponent, ActiveDialogComponent, PermissionsComponent],
  imports: [
    CommonModule,
    NgbModalModule,
    UIModule,
    FormsModule, ReactiveFormsModule ,
  ],
  exports:[UpgradeDialogComponent, ActiveDialogComponent, PermissionsComponent]
})
export class CoreModule { }
