<ng-template #UpgradeModal let-modal="close">
    <div class="modal-body" *ngIf="option">
        <app-ui-preloader [display]="loading"></app-ui-preloader>
        <div class="d-flex justify-content-end">
            <button  type="button" class="close" (click)="close()" aria-hidden="true">×</button>
        </div>
        <div class="mt-2">
            <ng-template [ngTemplateOutlet]="GoogleReview" *ngIf="option=='google-review'"></ng-template>
            <ng-template [ngTemplateOutlet]="FacebookReview" *ngIf="option=='facebook-review'"></ng-template>
            <ng-template [ngTemplateOutlet]="ManualCollection" *ngIf="option=='manual-site-review-requests'"></ng-template> 
            <ng-template [ngTemplateOutlet]="AutomaticRequest" *ngIf="option=='automatic-review-requests'"></ng-template> 
            <ng-template [ngTemplateOutlet]="FacebookInstagramAds" *ngIf="option=='facebook-instagram-ads'"></ng-template>
            <ng-template [ngTemplateOutlet]="GoogleShopping" *ngIf="option=='google-shopping'"></ng-template>
            <ng-template [ngTemplateOutlet]="GroupingProducts" *ngIf="option=='product-grouping'"></ng-template>
            <ng-template [ngTemplateOutlet]="ProductsBlocklist" *ngIf="option=='product-blocklist'"></ng-template> 
            <ng-template [ngTemplateOutlet]="UpTo3domains" *ngIf="option=='up-to-3-domains'"></ng-template> 
            <ng-template [ngTemplateOutlet]="ReviewTranslate" *ngIf="option=='reviews-translate'"></ng-template> 
            <ng-template [ngTemplateOutlet]="reviewsTranslatepack" *ngIf="option=='reviews-translate-pack'"></ng-template> 
            <ng-template [ngTemplateOutlet]="CatalogsPinterest" *ngIf="option=='pinterest-catalogs'"></ng-template> 
            <ng-template [ngTemplateOutlet]="AutoReply" *ngIf="option=='auto-reply'"></ng-template> 
            <!-- Formulaire -->
            <ng-template [ngTemplateOutlet]="FormTemplate"></ng-template>
            
        </div>
    </div>
</ng-template>
<ng-template #FormTemplate>
    <form class="needs-validation"  [formGroup]="contactform">
        <div class="form-row">
            <div class="form-group col-md-6">
                <input type="text" formControlName="firstName" class="form-control" id="firstName" i18n-placeholder placeholder="Nom">
            </div>
            <div class="form-group col-md-6">
                <input type="text" formControlName="lastName" class="form-control" id="lastName" i18n-placeholder  placeholder="Prénom">
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-md-6">
                <input type="email" formControlName="email" class="form-control" id="email" i18n-placeholder  placeholder="Email">
            </div>
            <div class="form-group col-md-6">
                <input type="tel" formControlName="phone" class="form-control" id="phone"  i18n-placeholder placeholder="Tél">
            </div>
        </div>
    </form>
    <div class="d-flex justify-content-end mt-2 mb-2">
        <button (click)="close()" routerLink="/pricing/upgrade" class="btn btn-link text-blue" i18n>Optimiser votre pack</button>
        <button (click)="contactSubmit()" [disabled]="contactform.invalid" class="btn btn-blue ml-1"  i18n>Contactez-nous</button> 
    </div>
</ng-template>


<ng-template #GoogleReview>
        <h5>
            <span  class="mdi mdi-arrow-up-bold-hexagon-outline text-warning"></span>
            <span class="text-muted ml-1" i18n>Pack Booster</span>
        </h5>
        <h1 i18n>Google my business</h1>
        <h5 class="mb-4" i18n>Surclassez la concurrence dans la recherche locale avec notre outil de gestion de la réputation - le moyen facile de collecter et de répondre aux avis Google My Business.</h5>

</ng-template>


<ng-template #FacebookReview>
    <h5>
        <span  class="mdi mdi-arrow-up-bold-hexagon-outline text-warning"></span>
        <span class="text-muted ml-1" i18n>Pack Booster</span>
    </h5>
    <h1 i18n>Gérez vos avis Facebook</h1>
    <h5 class="mb-4" i18n>Nous vous donnons les outils dont vous avez besoin pour collecter, publier et promouvoir les avis Facebook - des outils pour augmenter facilement votre score d’avis et transformer les commentaires des clients en preuve sociale qui attire l’attention.</h5>

</ng-template>

<ng-template #ManualCollection>
    <h5>
        <span  class="mdi mdi-arrow-up-bold-hexagon-outline text-warning"></span>
        <span class="text-muted ml-1">Pack Booster</span>
    </h5>
    <h1 i18n>Invitation manuelle</h1>
    <h5 class="mb-4" i18n>Nos méthodes d'invitation manuelle sont rapides et faciles à utiliser et requièrent peu de connaissances techniques.</h5>

</ng-template>

<ng-template #AutomaticRequest>
    <h5>
        <span  class="mdi mdi-arrow-up-bold-hexagon-outline text-warning"></span>
        <span class="text-muted ml-1">Pack Starter</span>
    </h5>
    <h1 i18n>Envoyer des invitations automatiques</h1>
    <h5 class="mb-4" i18n>Les invitations automatiques sont des e-mails qui vous aident à collecter automatiquement les avis sur le site.</h5>

</ng-template>

<ng-template #FacebookInstagramAds>
    <h5>
        <span  class="mdi mdi-arrow-up-bold-hexagon-outline text-warning"></span>
        <span class="text-muted ml-1" i18n>Pack Booster</span>
    </h5>
    <h1 i18n>Facebook & Instagram Ads</h1>
    <h5 class="mb-4" i18n>Le moyen le plus simple de créer des publicités Facebook et Instagram efficaces.</h5>

</ng-template>

<ng-template #GoogleShopping>
    <h5>
        <span  class="mdi mdi-arrow-up-bold-hexagon-outline text-warning"></span>
        <span class="text-muted ml-1" i18n>Pack Pro</span>
    </h5>
    <h1 i18n>Google Shopping</h1>
    <h5 class="mb-4" i18n>Intégrez les avis sur les produits au flux Google Shopping. Les avis sur les produits apparaissent sur les produits répertoriés dans Google Shopping et sont mis en évidence.</h5>

</ng-template>

<ng-template #CatalogsPinterest>
    <h5>
        <span  class="mdi mdi-arrow-up-bold-hexagon-outline text-warning"></span>
        <span class="text-muted ml-1" i18n>Pack Booster</span>
    </h5>
    <h1 i18n>Catalogues Pinterest</h1>
    <h5 class="mb-4" i18n>Présentez vos produits à 400 millions de personnes sur Pinterest. les utilisateurs de Pinterest peuvent facilement découvrir, enregistrer et acheter des produits depuis votre site Web.</h5>

</ng-template>

<ng-template #GroupingProducts>
    <h5>
        <span  class="mdi mdi-arrow-up-bold-hexagon-outline text-warning"></span>
        <span class="text-muted ml-1">Pack Booster</span>
    </h5>
    <h1 i18n>Regroupement de produits</h1>
    <h5 class="mb-4" i18n>Le regroupement de produits permet de créer des groupes de produits et de partager les avis entre le même groupe de produits.</h5>

</ng-template>

<ng-template #ProductsBlocklist>
    <h5>
        <span  class="mdi mdi-arrow-up-bold-hexagon-outline text-warning"></span>
        <span class="text-muted ml-1" i18n>Pack Booster</span>
    </h5>
    <h1 i18n>Liste de blocage des produits</h1>
    <h5 class="mb-4" i18n>Vous pouvez empêcher les produits sélectionnés d'être inclus dans l'e-mail de demande d'avis.</h5>

</ng-template>


<ng-template #UpTo3domains>
    <h5>
        <span class="mdi mdi-arrow-up-bold-hexagon-outline text-warning"></span>
        <span class="text-muted ml-1">Pack Pro</span>
    </h5>
    <h1 i18n>Jusqu'à 3 domaines</h1>
    <h5 class="mb-4" i18n>Collectez et affichez les avis de plusieurs domaines.</h5>

</ng-template>

<ng-template #ReviewTranslate>
    <h5>
        <span class="mdi mdi-arrow-up-bold-hexagon-outline text-warning"></span>
        <span class="text-muted ml-1">Pack Booster</span>
    </h5>
    <h1 i18n>Traduction automatique des avis</h1>
    <h5 class="mb-4" i18n>Activez l'auto traduction des avis selon la géolocalisation.</h5>

</ng-template>


<ng-template #AutoReply>
    <h5>
        <span class="mdi mdi-arrow-up-bold-hexagon-outline text-warning"></span>
        <span class="text-muted ml-1">Pack Booster</span>
    </h5>
    <h1 i18n>Réponse automatique </h1>
    <h5 class="mb-4" i18n>Avec l'option réponse automatique, répondre aux avis de manière efficace et évolutive devient un jeu d'enfant. Grâce à L'utilisation de l'IA, cette option vous permet de répondre aux avis en quelques secondes.</h5>

</ng-template>

<ng-template #reviewsTranslatepack>
  
    <h2 i18n>Contactez-nous !</h2>
  

</ng-template>
