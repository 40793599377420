
import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformServer, isPlatformBrowser } from '@angular/common';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Account,IAccount } from 'src/app/core/models/account';
import { UserPlan, Plan } from "src/app/core/models/plan.models";
import { CookieService } from 'src/app/core/services/cookie.service';
import { User,Profile,LoginData } from 'src/app/core/models/auth.models';
import { SocialUser } from "src/app/core/models/social.user";
import { error } from 'protractor';
@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  apiUrl      = environment.apiUrl;
  frontUrl    = environment.frontUrl;
  user = new User();
  profile = new Profile();
  accountdeactivated: ReplaySubject<any> = new ReplaySubject();

    private currentAccountSubject: BehaviorSubject<any>;
    constructor(private http: HttpClient, private cookieService: CookieService, 
      @Inject(PLATFORM_ID) private platformId: any,
      private router: Router) {
        this.startStorageEventListener()
        this.apiUrl      = environment.apiUrl +`/${this.cookieService.getCurentLocale()}/api`;
    


    }

    ngOnDestroy() {
      this.stopStorageEventListener()
    }
    accountdeactivated$(): Observable<any> {
      return this.accountdeactivated.asObservable();
    }



    public  CheckCurrentUserLogin(): User {
      this.user =  this.cookieService.parseUser()
      if(this.user){
        try {
          if(this.user.profile.is_member){return this.user}
        } catch (error) {}
      }
      return  null;
    }

    public  currentMember(): User {
      this.user =  this.cookieService.parseMember()    
      if(this.user == null){
        this.logout()
        window.location.href = environment.loginPageUrlMember
      }
      return this.user;
    }

    public  SocialAccount(): SocialUser {
      try {
        let social_user =  JSON.parse(this.cookieService.getCookie('socialaccount'));
        return social_user;
      } catch (error) {
        return null
      }
        
    
    }

    public JWTToken(){
      const currentUser = this.cookieService.parseUser()
      if (currentUser && currentUser.token) {
        return currentUser.token
      }
      return null
    }

    public RefrechUser(){
      this.syc_account().subscribe(
        data=>{
              this.CacheUserData(data)
        }
      )
    }
  

    CacheUserData(data){
      this.user = new User(data.user);
      this.user.token = data.key;
      let profile = new Profile(data.profile)
      this.user.profile = profile
      this.user.first_name = profile.first_name
      this.user.last_name = profile.last_name
      this.user.setCompany(data.company)
      this.user.selectfirstCompany()
      let site_id = null
      try {
        let str = JSON.parse(this.cookieService.getCookie('site_id'))
        site_id = Number(str)
      } catch (error) {
        site_id = null
      }
    
      if (site_id){
        this.user.selectSite(site_id)
      }else{
        this.user.selectfirstSite()
      }
      let social_account = data['social_account']
      this.cookieService.setCookie('socialaccount', JSON.stringify(social_account), 30)
      this.cookieService.setCookie('Confirm', this.user.is_verify.toString(), 30)
      this.cookieService.setCookie('user', JSON.stringify(this.user), 30)
    }





    public get currentAccountValue(): Account {
        return this.currentAccountSubject.value;
    }




    login(email: string, password: string) {
        return this.http.post<any>(`${this.apiUrl}/rest-auth/login/`, { email, password })
          .pipe(map((data:LoginData) => {
            this.user = new User(data.user);
            this.user.token = data.key;
            let profile = new Profile(data.profile)
            this.user.profile = profile
            this.user.first_name = profile.first_name
            this.user.last_name = profile.last_name
            let social_account = data['social_account']


            this.cookieService.setCookie('socialaccount', JSON.stringify(social_account), 30)
            this.cookieService.setCookie('user', JSON.stringify(this.user), 30)

            return this.user;
          },error=>{
          }));
    }

    checkSession(data) {
      return this.http.post<any>(`${this.apiUrl}/rest-auth/members/session/check/`, data)
        .pipe(map((data:any) => {
          this.user = new User(data.user);
          this.user.token = data.key;
          let profile = new Profile(data.profile)
          this.user.profile = profile
          this.user.first_name = profile.first_name
          this.user.last_name = profile.last_name
          let social_account = data['social_account']
          this.cookieService.setCookie('socialaccount', JSON.stringify(social_account), 30)
          this.cookieService.setCookie('user', JSON.stringify(this.user), 30)

          return this.user;
        },error=>{
        }));
  }

    Faceboooklogin(data) {
      return this.http.post<any>(`${this.apiUrl}/facebook-rest-auth/login/`, data)
        .pipe(map((data:LoginData) => {
          
          this.user = new User(data.user);
          this.user.token = data.key;
          let profile = new Profile(data.profile)
          this.user.profile = profile
          this.user.first_name = profile.first_name
          this.user.last_name = profile.last_name
          this.user.setCompany(data.company)
          this.user.selectfirstCompany()
          this.user.selectfirstSite()
          let social_account = data['social_account']


          this.cookieService.setCookie('socialaccount', JSON.stringify(social_account), 30)
          this.cookieService.setCookie('Confirm', this.user.is_verify.toString(), 30)
          this.cookieService.setCookie('user', JSON.stringify(this.user), 30)
          return this.user;
        }));
    }

    logout() {
      if (this.user){
        try {
          if(this.user.profile.is_member){
            this.cookieService.deleteCookie('user');
            this.cookieService.deleteCookie('business');
            this.cookieService.deleteCookie('fbtoken');
            this.cookieService.deleteCookie('token_time');
            this.cookieService.deleteCookie('adaccount');
            this.cookieService.deleteCookie('Confirm');
            this.cookieService.deleteCookie('quick_start');
            this.cookieService.deleteCookie('shopifycode');
            this.cookieService.deleteCookie('facebookpage');
            this.cookieService.deleteCookie('site_id');
            this.cookieService.deleteCookie('socialaccount');
            this.cookieService.deleteCookie('Language');
            this.cookieService.deleteCookie('queryParams');
            this.user = null;
          }
        } catch (error) {}
      
       
      }
        
    }



 


    public  checkemail( data: any ): Observable <any> {
      return this.http.post<any>(`${this.apiUrl}/profiles/check_email/`, data)
      .pipe(map(data => {
        
        return data
      }));
    }



    public  verifyemail( data: any, key:any ): Observable <any> {
      return this.http.post<any>(`${this.apiUrl}/rest-auth/registration/account-confirm-email/${key}/`, data)
      .pipe(map(data => {
        return data
      }));
    }

    public  passwordrest( data: any ): Observable <any> {
      return this.http.post<any>(`${this.apiUrl}/rest-auth/password/reset/`, data)
      .pipe(map(data => {
        return data
      }));
    }

    public  verifypasswordrest( data: any): Observable <any> {
      return this.http.post<any>(`${this.apiUrl}/rest-auth/password/reset/check/`, data)
      .pipe(map(data => {
        return data
      }));
    }

    public  confirmepasswordrest( data: any ,uidb64:string, token:string): Observable <any> {
      return this.http.post<any>(`${this.apiUrl}/password_reset_confirm/${uidb64}/${token}/`, data)
      .pipe(map(data => {
        return data
      }));
    }


    public  syc_account( ): Observable <any> {
      return this.http.get<any>(`${this.apiUrl}/profiles/syc_account/`)
      .pipe(map(data => {
        return data
      }));
    }


    private startStorageEventListener(): void {
      window.addEventListener("storage", this.storageEventListener.bind(this));
    }

    private stopStorageEventListener(): void {
      window.removeEventListener("storage", this.storageEventListener.bind(this));
    }
  
  

    private storageEventListener(event: StorageEvent) {
     
      if (event.storageArea == localStorage) {
        if (event.key && event.key == 'member-logout-event') {
          this.logout()
          window.location.href = environment.loginPageUrlMember
        }
      }
    }


}

