import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { UiSwitchModule } from 'ngx-ui-switch';
import { PaymentRoutingModule } from './payment-routing.module';
import { StripeComponent } from "./stripe/stripe.component";
import { PlansComponent } from './plans/plans.component';
import { Ng5SliderModule } from 'ng5-slider';
import { NgbModalModule, NgbPaginationModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbProgressbarModule, NgbTabsetModule } from '@ng-bootstrap/ng-bootstrap';
import { UIModule } from 'src/app/certishopping/shared/ui/ui.module';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from 'src/environments/environment';
import { NgxPayPalModule } from 'ngx-paypal';
import { PaypalcheckoutComponent } from './paypalcheckout/paypalcheckout.component';
import { InvoicelistsComponent } from './invoicelists/invoicelists.component';
import { EditinvoiceComponent } from "./editinvoice/editinvoice.component";
import { InvoiceComponent } from "./invoice/invoice.component";
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [PlansComponent,StripeComponent, PaypalcheckoutComponent, InvoicelistsComponent, InvoiceComponent, EditinvoiceComponent],
  imports: [
    CommonModule,
    PaymentRoutingModule,
    Ng5SliderModule,
    UiSwitchModule,
    NgbModalModule, NgbPaginationModule,
    NgbTypeaheadModule,
    NgbProgressbarModule, NgbTabsetModule ,
    UIModule,
    FormsModule, ReactiveFormsModule,
    NgxPayPalModule,
    NgxStripeModule.forRoot(environment.StripePublicKey),
    NgbAlertModule
  ],
})
export class PaymentModule { }
