<div class="container-fluid">

    <div class="row">
        <div class="col-10 offset-1">
            <div class="card-box p-2 mt-4">
                <!-- Logo & title -->
                <app-ui-preloader [display]="loading"></app-ui-preloader>
                <div class="clearfix header">
                    <div class="float-left">
                        <img src="assets/images/certishopping-logo_003.png" alt="" class="logo">
                    </div>
                    <div class="float-right">
                        <h4 class="m-0 mt-3" ><span class="mr-1" i18n>Facture</span> {{ billing?.reference}}</h4>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6 col-sm-6 col-12">
                        <div class="mt-3">
                            <h3 class="m-0">DEVSOLUTION</h3>
                            <p class="m-0">32 Bd de strasbourg </p>
                            <p class="m-0">CS 30108 75468 PARIS Cedex 10</p>
                            <p class="m-0"><span i18n>SIRET :</span> <span class="ml-1">83010138200018</span></p>
                            <p class="m-0"><span i18n>TVA :</span> <span class="ml-1">FR72830101382</span></p>
                            <p class="m-0"><span class="mr-1" i18n>Tél :</span>+33 1 77 62 73 76</p>
                          
                        </div>

                    </div>
                     <!-- end col -->
                     <div class="col-md-4 offset-md-2 col-sm-6 col-12">
                        <div class="mt-3 invoice-info">
                            <!-- <p ><strong  class="mr-1" i18n>Date de commande:</strong> <span class="ml-1"> {{billing?.selling_date | date}}</span></p> -->
                            <p class="m-0"><strong><span  class="mr-1" i18n>FACTURE N°: </span>{{ billing?.reference}}</strong></p>
                            <p class="m-0"><strong><span  class="mr-1" i18n>Montant de la facture:</span> {{billing?.total | currency:billing?.currency}}</strong></p>
                            <p class="m-0" *ngIf="billing?.expired_at && billing?.payment_date">
                                <strong><span  class="mr-1" i18n>Période de facturation: </span></strong>
                                <span>{{billing?.payment_date | date}} </span>
                                <span i18n>au</span>
                                <span> {{billing?.expired_at | date}}</span>
                            </p>
                            <p *ngIf="billing?.status =='Paid'" i18n class="text-success">PAYÉ</p>
                        
                        </div>
                    </div>
                    <!-- <div class="col-md-6 col-sm-6 col-12">
                        <div class="mt-3">
                            <p><b>Bonjour, {{billing?.details?.name | titlecase}} {{billing?.details?.last_name | titlecase}}</b></p>
                            <p class="text-muted">Merci beaucoup pour vous continuez à acheter nos produits. Notre entreprise s'engage à vous fournir des produits de haute qualité ainsi qu'un service client exceptionnel pour chaque transaction. </p>
                        </div>

                    </div> -->
                   
                    <!-- end col -->
                </div>
                <!-- end row -->

                <div class="row mt-1">
                    <div class="col-md-6 col-sm-6 col-12">

                    </div>
                    <div class="col-md-4 offset-md-2 col-sm-6 col-12">
                        <div class="company-details ">
                            <h3 ><span class="text-dark">{{billing?.details?.company }} </span></h3>
                            <p class="text-dark m-0" *ngIf="billing?.details?.display_name">{{billing?.details?.name | titlecase}} {{billing?.details?.last_name | titlecase}}</p>
                            <p class="m-0" *ngIf="billing?.details?.address">{{billing?.details?.address }}</p> 
                            <p class="m-0" *ngIf="billing?.details?.city || billing?.details?.state || billing?.details?.country || billing?.details?.zipcode">{{billing?.details?.city }} {{billing?.details?.state }} {{billing?.details?.country }} {{billing?.details?.zipcode }}</p>
                            <p class="m-0" *ngIf="billing?.details?.siret"><span class="mr-1" i18n>SIRET:</span> {{billing?.details?.siret}} </p>
                            <p class="m-0" *ngIf="billing?.details?.vat_number"><span class="mr-1" i18n>TVA:</span> {{billing?.details?.vat_number}} </p>
                            <p class="m-0" *ngIf="billing?.details?.phone"><abbr title="Phone" i18n  *ngIf="billing?.details?.phone ">Tél:</abbr>  {{billing?.details?.phone }}</p>
                        </div>
                        
                            
                       
                    </div>

                </div>

                <div class="row mt-1">
                    <div class="col-12">
                        <div class="table-responsive">
                            <table class="table mt-2 table-centered">
                                <thead>
                                    <tr>
                                        <th i18n style="padding: 10px 0px;">Description</th>
                                        <th i18n>Unités</th>
                                        <th i18n style="width: 16%">Montant</th>
                                        <!-- <th  style="width: 10%"><span class="mr-1" i18n>Tax</span><span *ngIf="billing?.tax_amount">({{billing?.tax | number}}%)</span></th> -->
                                        <th i18n style="width: 15%" class="text-right">TOTAL HT</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style="border-bottom: 1px solid #dee2e6;padding: 10px 0px;">
                                            <h4 class="p-0 m-0"><span i18n class="mr-1">Pack</span> {{ PlanPricing?.plan_type}} </h4>
                                            <p class="p-0 m-0" *ngIf="PlanPricing?.plan_type == PlanType.booster || PlanPricing?.plan_type == PlanType.pro || PlanPricing?.plan_type == PlanType.enterprise || PlanPricing?.plan_type == PlanType.starter">
                                                <span class="option-item" >
                                                    <span i18n>Jusqu’à</span>
                                                    <span class="ml-1 mr-1">{{PlanPricing?.orders_monthly}}</span> 
                                                    <span i18n>commandes</span>
                                                </span>
                                            </p>
                                            
                                        </td>
                                        <td  style="border-bottom: 1px solid #dee2e6;">1</td>
                                        <td> {{ billing?.amount | currency:currency }}</td>
                                        <!-- <td *ngIf="billing?.tax_amount"> {{ billing?.tax_amount | currency:currency }}</td>
                                        <td *ngIf="!billing?.tax_amount"> --</td> -->
                                        <td class="text-right font-weight-bold text-black">
                                            <h5>{{ billing?.amount | currency:currency }}</h5>
                                            
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="border-bottom: 1px solid #dee2e6;padding: 10px 0px;">
                                            <div class="options">
                                                <div class="option-item">
                                                    <ng-container *ngFor="let option of billing?.options; let index=index">
                                                        <span *ngIf="index < (billing?.options?.length /2)">
                                                            <span>{{option?.name}}</span>
                                                        </span>
                                                    </ng-container>
                                    
                                                </div>
                                                <div class="option-item">
                                                    <ng-container *ngFor="let option of billing?.options; let index=index">
                                                        <span *ngIf="index > (billing?.options?.length /2)">
                                                            <span>{{option?.name}}</span>
                                                        </span>
                                                    </ng-container>
                                                </div>
  
                                            </div>
                                               
                                            
                                           
                                            <!-- <div class="options">
                                                <span class="option-item" *ngFor="let option of billing?.options; let index=index">
                                                    <span>{{option}}</span>
                                                </span>
                                            </div> -->
                                        </td>
                                        <td  style="border-bottom: 1px solid #dee2e6;"></td>
                                        <td></td>
                                        <td class="text-right font-weight-bold text-black"> 
                                        </td>
                                    </tr>
                                    <tr *ngIf="billing?.discount !='0.00'">
                                        <td style="border-top: unset;"></td>
                                        <td style="border-top: unset;"></td>
                                        <td>
                                            <span i18n class="m-0 p-0" i18n>TOTAL HT</span>
    
                                        </td>
                                        <td class="text-right font-weight-bold text-black">
                                            <h5>{{ billing?.amount | currency:currency }}</h5>
                                        </td>

                                    </tr>
                                    <tr *ngIf="billing?.discount !='0.00'">
                                        <td style="border-top: unset;"></td>
                                        <td style="border-top: unset;"></td>
                                        <td>
                                            <span i18n class="m-0 p-0" i18n>Remise</span>
                                            <span> {{billing?.discount | number:'1.0-0'}} % </span>
                                        </td>
                                        <td class="text-right font-weight-bold text-black">
                                            <h5>-{{billing?.discount_amount | currency:billing?.currency}}</h5>
                                            
                                        </td>

                                    </tr>
                                    <tr>
                                        <td style="border-top: unset;"></td>
                                        <td style="border-top: unset;"></td>
                                        <td>
                                            <span i18n class="m-0 p-0" i18n>TVA</span>
                                            <span> {{billing?.tax | number}}%</span>
                                        </td>
                                        <td class="text-right font-weight-bold text-black">
                                            <h5>{{ billing?.tax_amount | currency:currency }}</h5>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td style="border-top: unset;"></td>
                                        <td style="border-top: unset;"></td>
                                        <td>
                                            <h4 i18n class="m-0 p-0" i18n>TOTAL TTC</h4>
                                        </td>
                                        <td class="text-right font-weight-bold text-black">
                                            <h4>{{billing?.total | currency:billing?.currency}}</h4>
                                            
                                        </td>

                                    </tr>


                                </tbody>
                            </table>
                        </div>

                    </div>

                </div>

                <div class="row">
                    <div class="col-sm-6">
                    </div>

                    <div class="col-sm-6">
                        <div class="float-right">
                            <!-- <h5 class="mb-4" *ngIf="billing?.tax_amount">
                                <span i18n class="mr-1" i18n>TVA</span>
                                <span>{{billing?.tax | number}}%</span>
                                <span> {{ billing?.tax_amount | currency:currency }}</span>
                            </h5> -->
                            <!-- <h5 class="mb-4" *ngIf="billing?.discount !='0.00'">
                                <b>
                                <span i18n class="mr-1">Remise</span><span class="mr-1">{{billing?.discount | number:'1.0-0'}} % </span>
                                </b> 
                                <span class="float-right"><b> {{billing?.discount_amount | currency:billing?.currency}}</b></span>
                            </h5> -->
                            <!-- <h4  class="text-left"><span i18n class="mr-1">TOTAL TTC  </span>{{billing?.total | currency:billing?.currency}}</h4> -->

                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <!-- <div class="col-12"> 
                        <ng-container  [ngTemplateOutlet]="Options"></ng-container>
                       
                    </div> -->

                    <!-- <div class="col-12">
                        <div class="table-responsive">
                            <table class="table mt-4 table-centered">
                                <thead>
                                    <tr>
                                        <th style="width: 100%; padding: 10px 0px;" i18n>Fonctionnalités</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                        <div class="options">
                            <span class="option-item" >
                                <span i18n>Jusqu’à</span>
                                <span class="ml-1 mr-1">{{PlanPricing?.orders_monthly}}</span> 
                                <span i18n>commandes</span>
                            </span>
                            <span class="option-item" *ngFor="let option of PlanPricing?.options; let index=index">
                                <span>{{option?.name}}</span>
                            </span>
                        </div>
                    </div> -->
                    
                    <!-- <div class="col-12"> 
                        <ng-container  [ngTemplateOutlet]="Options"></ng-container>
                       
                    </div> -->
                    
                    
                    <!-- end col -->
                    <div class="col-sm-6"></div>
                    <div class="col-sm-6 d-flex justify-content-center">
                        <p class="paid " *ngIf="billing?.status =='Paid'" i18n>PAYÉ</p>
                    </div>
                </div>


                <div class="row mt-5 mb-3">
                    <div class="col-12">
                        <p class="m-0"><strong i18n>Merci pour votre confiance</strong></p>
                        <p  class="m-0" i18n>Site : www.certishopping.com service commercial : info@certishopping.com support : support@certishopping.com</p>
                    </div>
                </div>

                <div class="mt-4 mb-1">
                    <div class="text-right d-print-none d-none d-md-block">
                        <button   (click)="EditInfo()" class="btn btn-light mr-1"><i class="mdi mdi-pencil mr-1"></i><span i18n>Modifier</span></button>
                        <a  href="javascript:window.print()" class="btn btn-light"><i class="mdi mdi-printer mr-1"></i> <span i18n>Imprimer</span></a>
                        <button  *ngIf="billing?.status =='Sent'" (click)="centerModal(PaymentModal)" class="btn btn-blue ml-1"><span i18n>Payer</span></button>
                    </div>

                    <div class="text-right d-print-none d-block d-sm-none">
                        <button   (click)="EditInfo()" class="btn btn-light mr-1 w-100 mt-1"><i class="mdi mdi-pencil "></i><span i18n>Modifier</span></button>
                        <a  href="javascript:window.print()" class="btn btn-light  w-100  mt-1"><i class="mdi mdi-printer"></i> <span i18n>Imprimer</span></a>
                        <button  *ngIf="billing?.status =='Sent'" (click)="centerModal(PaymentModal)" class="btn btn-blue mt-1  w-100"><span i18n>Payer</span></button>
                    </div>
                </div>
            </div>
            <!-- end card-box -->
        </div>
        <!-- end col -->
    </div>
    <!-- end row -->
    <app-stripe [clientSecret]="clientSecret" [User]="User" [amount]="amount"></app-stripe>
    <app-paypalcheckout  [Site]="Site" [amount]="amount" [name]="planName" (CapturePayPalOrder)="CapturePayPalOrder($event)"></app-paypalcheckout>
    <app-editinvoice [Site]="Site" [billing]="billing"></app-editinvoice>
</div>



<ng-template #PaymentModal let-modal="close">

    <div class="modal-body p-1">
        <app-ui-preloader [display]="loading_pay"></app-ui-preloader>  
      <p class="text-right font-20"><span (click)="modal('Cross click')" class="mdi mdi-close"></span></p>
      <div class="row m-0 p-1">

            <div class="col-md-6 col-xl-6">
                <div class="widget-rounded-circle card-box" (click)="OpenStripeCardModal()">
                    <div class="row align-items-center m-0 p-2">
                        <div class="col-12">
                        <div class="avatar text-center">
                            <img src="assets/images/cards/card.svg" width="60"  class="img-fluid rounded" />
                        </div>
                        </div>
                        <div class="col">
                        <p class="mb-2 mt-1 text-center" i18n>Payez avec une carte de crédit</p>
                        </div>
                    </div> 
                </div> 
            </div>

            <div class="col-md-6 col-xl-6">
                <div class="widget-rounded-circle card-box"  (click)="PaypalCheckout()">
                    <div class="row align-items-center m-0 p-2">
                        <div class="col-12">
                        <div class="avatar text-center">
                            <img src="assets/images/cards/paypal.svg" width="60" class="img-fluid rounded"  />
                        </div>
                        </div>
                        <div class="col">
                        <p class="mb-2 mt-1 text-center" i18n>Payez avec Paypal</p>
                        </div>
                    </div> 
                </div> 
            </div>
            
        </div>
    </div>
  
</ng-template> 




<ng-template #Options>
    <div class="row">
        <div class="col-6">
            <p class="ml-2 mb-2 border-bottom font-weight-bold"><span i18n>Jusqu’à</span><span class="ml-1 mr-1">{{PlanPricing?.orders_monthly}}</span> <span i18n>commandes</span></p>
        </div>
        <div class="col-6" *ngFor="let option of PlanPricing?.options">
            <p class="ml-2 mb-2 border-bottom font-weight-bold" >{{option?.name}}</p>
        </div>
    </div>
</ng-template>
