import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PLAN_DETAILS,PLAN_TYPE, WIX_PLAN_DETAILS } from "../plan.model";
import { Company } from "src/app/core/models/company";
import { AuthenticationService } from "src/app/core/services/client/auth.service";
import { Site, Provider } from 'src/app/core/models/site';
import { User } from "src/app/core/models/auth.models";
import { PaypalcheckoutComponent } from "../paypalcheckout/paypalcheckout.component";
import { StripeComponent} from '../stripe/stripe.component';
import { ApiService } from 'src/app/core/services/client/api.service';
import { HttpParams } from '@angular/common/http';
import { UserPlan, PlanPricing } from "src/app/core/models/plan.models";
import Swal from 'sweetalert2';
@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.scss']
})
export class PlansComponent implements OnInit {
  User:User;
  Site:Site;
  Provider=Provider;
  Company:Company;
  setting:any;
  planDetails=PLAN_DETAILS
  selectedPlan:any
  slider=  {
    option: {
        floor: 200,
        ceil: 1050,
        disabled: false,
        step:50,
        translate: (value: number): string => {
          if(value == 1050){
            return '+' + 1000;
          }else{
            return value.toString()
          }
         
        }
    }
  }
  orderNumber:number=200;
  planName:string=PLAN_DETAILS.starter.name;
  currency:string="euro";
  billing:string="yearly";
  PlanPricing:PlanPricing
  UserPlan:UserPlan
  price:any=0;
  amount:any=0;
  totalHt:any=0;
  vat:any=0
  vatAmount:any=0;
  paymentForm: FormGroup;
  paymentOption:string="stripe";
  withDiscount:boolean=true;
  monthNb:number=12;
  activeModal:NgbActiveModal
  clientSecret:string;
  loading:boolean = false;
  isNewAppVersion:boolean=false;
  @ViewChild(StripeComponent,{static:false}) StripeComponent;
  @ViewChild(PaypalcheckoutComponent,{static:false}) PaypalcheckoutComponent;
  constructor(public formBuilder: FormBuilder,    private apiService:ApiService,
    private authenticationService:AuthenticationService,
    private modalService:NgbModal,) {

    this.User = this.authenticationService.currentUser()
    this.Company = this.User.getselectCompany()
    this.Site = this.User.getselectSite()
    
    
    if(this.Company){
      this.UserPlan = this.Company.plan   
     
      if (this.UserPlan){
        this.PlanPricing = this.UserPlan.details;
        if (this.PlanPricing.plan_type  != PLAN_TYPE.free.name){
          this.orderNumber = this.PlanPricing.orders_monthly
          this.planName = this.PlanPricing.plan_type
          this.withDiscount =  false;
          this.changePlan(this.planName)
        }
      }
    }

    if(this.Site.provider ==  Provider.wix){
      if (this.PlanPricing.plan_type  == PLAN_TYPE.free.name){
        this.billing = "monthly"
      }
      this.slider.option.disabled = true;
       this.withDiscount =  false;
      this.currency = "dollar";
      this.getCompanyplan()
      this.planDetails = WIX_PLAN_DETAILS


    }

    if(this.Site.provider ==  Provider.shopify){
      this.withDiscount =  false;
      this.slider.option.disabled = true;
      this.currency = "dollar";
      this.getCompanyplan()
    }

    this.paymentForm = this.formBuilder.group({
      orders: [200],
    });

    if( this.Site.provider ==  Provider.shopify){
      this.getConfig()
    }
    this.getVatRates()

  }

  ngOnInit() {
      if( this.planName == this.planDetails.starter.name){
        this.selectedPlan =  this.planDetails.starter
        this.planDetails.starter.selected = true;
        this.price = this.planDetails.starter.price[this.billing][this.currency];
      }
      if(this.Site.provider ==  Provider.wix && this.PlanPricing.auto_renewal){

        this.planDetails.pro.disabled = false;
        this.planDetails.booster.disabled = false;
        this.planDetails.starter.disabled = true;

        if( this.planName == this.planDetails.pro.name){
          this.selectedPlan =  this.planDetails.booster
          this.planDetails.booster.disabled = true;
        }
        if( this.planName == this.planDetails.starter.name){
          this.selectedPlan =  this.planDetails.starter
          this.planDetails.starter.disabled = false;
        }

      }
  }

  onBillingChange(event){
   
    if(event){
      this.billing="yearly"
    }else{
      this.billing="monthly"
    }
    this.changePlan(this.planName)
  }

  onOrdersChange(value){
    if(this.withDiscount){
      this.monthNb = 10
    }else{
      this.monthNb = 12
    }
 

    if(this.Site.provider ==  Provider.wix && this.PlanPricing.auto_renewal){
      this.planDetails.entreprise.disabled = false;
      this.planDetails.pro.disabled = false;

      if(this.PlanPricing.plan_type == this.planDetails.entreprise.name ){
        this.selectedPlan =  this.planDetails.entreprise
        this.planDetails.booster.disabled = true;
        this.planDetails.starter.disabled = true;
        this.planDetails.pro.disabled = true;
      }
     
      if(this.PlanPricing.plan_type == this.planDetails.pro.name ){
        this.selectedPlan =  this.planDetails.pro
        this.planDetails.booster.disabled = true;
        this.planDetails.starter.disabled = true;
      }
      if(this.PlanPricing.plan_type == this.planDetails.booster.name){
        this.selectedPlan =  this.planDetails.booster
        this.planDetails.starter.disabled = true;
      }
    }else{
      this.planDetails.booster.disabled = false;
      this.planDetails.starter.disabled = false;
      this.planDetails.pro.disabled = false;
      this.planDetails.entreprise.disabled = false;
    }
   


    if(value == 200){
      this.price = this.planDetails.starter.price[this.billing][this.currency]
      this.selectedPlan =  this.planDetails.starter
    }
    if(value > 200 && value < 400){
      this.price = this.planDetails.starter.price[this.billing][this.currency]
      let nb = value - this.planDetails.starter.nborder
      this.price +=  parseFloat((this.planDetails.starter.orderPrice * nb).toFixed(2));
      this.selectedPlan =  this.planDetails.starter
      
    }
    if(value == 400){
      this.price = this.planDetails.booster.price[this.billing][this.currency]
      this.selectedPlan =  this.planDetails.booster
      
    }
    if(value > 400 && value < 600){
      let nb = value - this.planDetails.booster.nborder
      this.price =  this.planDetails.booster.price[this.billing][this.currency]
      this.price += parseFloat((this.planDetails.booster.orderPrice * nb).toFixed(2));
      this.selectedPlan =  this.planDetails.booster

    }
    if(value >= 600){
      this.price = this.planDetails.pro.price[this.billing][this.currency]
      this.selectedPlan =  this.planDetails.pro
      
    }
    if(value >= 1000){
      this.price = this.planDetails.entreprise.price[this.billing][this.currency]
      this.selectedPlan =  this.planDetails.entreprise
      
    }

    if(this.planName == this.planDetails.pro.name || this.planDetails.pro.selected){
      this.price = this.planDetails.pro.price[this.billing][this.currency]
      this.selectedPlan =  this.planDetails.pro
    }
    if(value>1000){
      this.planName = "ENTREPRISE"
      this.planDetails.booster.disabled = true;
      this.planDetails.starter.disabled = true;
      this.planDetails.pro.disabled = true;
      this.selectedPlan =  this.planDetails.entreprise

    }

    this.totalHt =  parseFloat((this.price * this.monthNb).toFixed(2));
    this.vatAmount = (this.totalHt /100) * this.vat
    this.amount = parseFloat(((this.price * this.monthNb)+ this.vatAmount).toFixed(2))

  }

  changePlan(plan){

    if(this.withDiscount){
      this.monthNb = 10
    }else{
      this.monthNb = 12
    }
    if(this.Site.provider ==  Provider.wix && this.PlanPricing.auto_renewal){

      this.planDetails.entreprise.disabled = false;
      this.planDetails.pro.disabled = false;
     
      if(this.PlanPricing.plan_type == this.planDetails.entreprise.name && plan != this.planDetails.entreprise.name){
        this.selectedPlan =  this.planDetails.entreprise
        this.planDetails.booster.disabled = true;
        this.planDetails.starter.disabled = true;
        this.planDetails.pro.disabled = true;
        this.planDetails.entreprise.selected = true;
        return
      }


      if(this.PlanPricing.plan_type == this.planDetails.pro.name ){
        this.planDetails.pro.selected = true;
        if(plan != this.planDetails.pro.name ){
          this.selectedPlan =  this.planDetails.pro
          this.planDetails.booster.disabled = true;
          this.planDetails.starter.disabled = true;
          return
        }

        
      }

      if(this.PlanPricing.plan_type == this.planDetails.booster.name && plan == this.planDetails.starter.name){
        this.selectedPlan =  this.planDetails.booster
        this.planDetails.starter.disabled = true;
        this.planDetails.booster.selected = true;
        return
      }


    }else{
      this.planDetails.booster.disabled = false;
      this.planDetails.starter.disabled = false;
      this.planDetails.entreprise.disabled = false;
    }

    this.planDetails.booster.selected = false;
    this.planDetails.starter.selected = false;
    this.planDetails.pro.selected = false;
    this.planDetails.entreprise.selected = false;


    let price = 0; let nborder = 0

    if(plan == this.planDetails.starter.name){
      this.selectedPlan =  this.planDetails.starter
      price =  this.planDetails.starter.price[`${this.billing}`][`${this.currency}`]
      nborder = this.planDetails.starter.nborder
    }
    if(plan == this.planDetails.booster.name){
      this.selectedPlan =  this.planDetails.booster
      price =  this.planDetails.booster.price[`${this.billing}`][`${this.currency}`]
      nborder = this.planDetails.booster.nborder
    }
    if(plan == this.planDetails.pro.name){
      this.selectedPlan =  this.planDetails.pro
      price =  this.planDetails.pro.price[`${this.billing}`][`${this.currency}`]
      nborder = this.planDetails.pro.nborder
    }
    if(plan == this.planDetails.entreprise.name){
      this.selectedPlan =  this.planDetails.entreprise
      price =  this.planDetails.entreprise.price[`${this.billing}`][`${this.currency}`]
      nborder = this.planDetails.entreprise.nborder
    }
    switch (plan) {

      case "ENTREPRISE":
        this.planName =  this.planDetails.entreprise.name;
        this.planDetails.entreprise.selected = true;
        this.selectedPlan =  this.planDetails.entreprise
        if(this.orderNumber == nborder){
          this.price = price 
        }else{
          this.orderNumber = nborder
          this.price = price;

        }
        break;

      case "BOOSTER":
        this.planName =  this.planDetails.booster.name;
        this.selectedPlan =  this.planDetails.booster
        this.planDetails.booster.selected = true;
        if(this.orderNumber == nborder){
          this.price = price 
        }else{
          this.orderNumber = nborder
          this.price = price;

        }
        break;

      case "PRO":
        this.planName =  this.planDetails.pro.name
        this.selectedPlan =  this.planDetails.pro
        this.planDetails.pro.selected = true;
        this.orderNumber = nborder
        this.price = price ;
        break;
      default:
        this.planName =  this.planDetails.starter.name
        this.selectedPlan =  this.planDetails.starter
        this.planDetails.starter.selected = true;
        if(this.orderNumber == nborder){
          this.price = price

        }else if(this.orderNumber > 200 && this.orderNumber < 400){
          this.price = price
          let nb = this.orderNumber - nborder
          this.price += parseFloat((this.planDetails.starter.orderPrice * nb).toFixed(2));
        }else{
          this.orderNumber = nborder
          this.price = price

        }
        break;


    }

    this.totalHt =  parseFloat((this.price *  this.monthNb).toFixed(2));
    this.vatAmount = (this.totalHt /100) * this.vat
    this.amount = parseFloat(((this.price * this.monthNb)+ this.vatAmount).toFixed(2))

   

  }
  
  onOptionChange(value){
    this.paymentOption = value
  }

  Checkout(){
    if(this.Site.provider !=  Provider.wix && this.Site.provider !=  Provider.shopify){
      if(this.paymentOption == 'stripe'){
        this.createStripeCheckout()
      }else{
        this.PaypalcheckoutComponent.checkout()
      }

    }
    if(this.Site.provider ==  Provider.shopify){
      this.getShopifyCheckoutUrl()

    }
    
    if(this.Site.provider ==  Provider.wix){
      this.getWixCheckoutUrl()
    }
   
  }
  CapturePayPalOrder(authorization){
    this.loading = true
    let params = new HttpParams()
    params= params.set('site',this.Site.id.toString())
    params= params.set('amount',this.amount)
    params= params.set('vat',this.vat)
    params= params.set('orders_monthly',this.orderNumber.toString())
    params= params.set('plan',this.planName)
    params= params.set('price',this.price)
    params= params.set('month',this.monthNb.toString())
    params= params.set('payment_intent',authorization.orderID)
    params= params.set('payment_method', "paypal")
    this.apiService.payment_paypal_checkout(params).subscribe(
      data => {
        this.loading = false;
        if(this.activeModal){this.activeModal.dismiss()}
        if(data.code == 200){
          Swal.fire({
            text: data.messages,
            type:"success",
            showCancelButton: false,
            allowOutsideClick:false,
          }).then((result) => {
            if (result.value) {
              window.location.reload()
            }
        });
        }else{
          Swal.fire({
            text: data.messages,
            type:"error",
            showCancelButton: false,
          })
        }
        
      },error => {
        this.loading = false;
        Swal.fire({
          text: error,
          type:"error",
          showCancelButton: false,
        })
    })

  }
  
  createStripeCheckout(){
    this.loading = true
    let params = new HttpParams()
    params= params.set('site',this.Site.id.toString())
    params= params.set('amount',this.amount)
    params= params.set('vat',this.vat)
    params= params.set('orders_monthly',this.orderNumber.toString())
    params= params.set('plan',this.planName)
    params= params.set('price',this.price)
    params= params.set('month',this.monthNb.toString())
    this.apiService.create_payment_stripe_checkout(params).subscribe(
      result => {
        this.clientSecret = result.clientSecret
        this.loading = false;
        if(this.activeModal){this.activeModal.dismiss()}
        this.StripeComponent.checkout()
      },error => {
        this.loading = false;

      })
  }

  getWixCheckoutUrl(){
    this.loading = true
    let params = new HttpParams()
    params= params.set('site',this.Site.id.toString())
    params= params.set('plan',this.planName)
    params= params.set('billing_cycle', this.billing.toUpperCase()) 
    params= params.set('successUrl', window.location.href)
    this.apiService.getWixCheckoutUrl(params).subscribe(
      result => {
        this.loading = false;
        if(result.connected){
          window.open(result.url,'_blank')
        }else{
          Swal.fire({
            text: result.message,
            type: 'warning'                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               ,
            showCancelButton: false,
          })
        }
      },error => {
        this.loading = false;

      })
  }

  getShopifyCheckoutUrl(){
    this.loading = true
    let params = new HttpParams()
    params= params.set('site',this.Site.id.toString())
    params= params.set('plan',this.planName)
    params= params.set('price',this.totalHt)
    this.apiService.getShopifyCheckoutUrl(params).subscribe(
      result => {
        this.loading = false;
        if(result.connected){
          window.open(result.url,"_self")
        }else{
          Swal.fire({
            text: result.message,
            type: 'warning'                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                               ,
            showCancelButton: false,
          })
        }
      },error => {
        this.loading = false;

      })
  }

  getVatRates(){
    let params = new HttpParams()
    params= params.set('site',this.Site.id.toString())
    this.apiService.getVatRates(params).subscribe(
      result => {
        if(result.vat){
          this.vat = result.vat 
         
        }
        if(this.withDiscount){
          this.monthNb = 10
        }else{
          this.monthNb = 12
        }
        this.vat = 20
        this.totalHt =  parseFloat((this.price * this.monthNb).toFixed(2));
        this.vatAmount = (this.totalHt /100) * this.vat
        this.amount = parseFloat(((this.price * this.monthNb)+ this.vatAmount).toFixed(2))
      },error => {
        this.loading = false;
      })
  }


    /**
   * check Recurring Plan
   */

     private getCompanyplan() {
      this.loading =  true
      this.apiService.checkRecurringPlan(this.UserPlan.id,this.Site.id).subscribe(
          data => {
            if(data.details){
              this.PlanPricing = new PlanPricing(data.details)
              let userPlan = new UserPlan(data)
              userPlan.details = this.PlanPricing
              if (userPlan.active != this.UserPlan.active || this.UserPlan.details.plan_type != this.UserPlan.details.plan_type 
                || userPlan.details.price != this.UserPlan.details.price){
                  this.UserPlan = userPlan
                  this.orderNumber = this.PlanPricing.orders_monthly
                  this.planName = this.PlanPricing.plan_type
                  this.withDiscount =  false;
                  this.changePlan(this.planName)
                  this.authenticationService.RefrechUser()
              }
            }
           
            this.loading = false;
            
          },
          error => {this.loading = false;}
      )
    
    
    }

    /**
   * Config Shopify
   */
     private getConfig() {
      this.apiService.getShopifyConfig(this.Site.id.toString()).subscribe(
          data => {
            this.setting = data
            if( this.Site.provider ==  Provider.shopify && this.setting.app_version == "v2"){
              this.isNewAppVersion = true;
              this.changePlan(this.planName)
            }
           
          },
          error => {}
      )
    
    
    }
  

}
