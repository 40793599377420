<div class="container-fluid">

  
    <div class="row">
      <div class="col-12">
        <div class="card mt-4">
          <div class="card-body">
            <h4 class="mb-4">Facture</h4>
            <app-ui-preloader [display]="loading"></app-ui-preloader>
             <div class="table-responsive">
              <table class="table table-centered table-bordered" >
                <thead>
                  <tr>
                    <th class="text-center" i18n>#Reference</th>
                    <!-- <th class="text-center"  i18n>Date</th> -->
                    <th class="text-center"  i18n>Montant</th>
                    <th class="text-center"  i18n>Total</th>
                    <th class="text-center"  i18n>Payé le</th>
                    <th class="text-center"  i18n>Statut</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let billing of billingData;let i = index">
                    <td class="text-center" >
                      <a [routerLink]="[ '/pricing/invoices',billing?.id]">{{ billing?.reference }}</a>
                    </td>
                    <!-- <td class="text-center" >
                      {{ billing?.selling_date|date }} 
                    </td> -->
                    <td class="text-center" >
                      {{ billing?.amount | currency:billing.currency }}
                    </td>
                    <td class="text-center" >
                      {{ billing?.total | currency:billing.currency }}
                    </td>
                    <td class="text-center" >
                      {{ billing?.payment_date|date }} 
                    </td>
                   
                    <td class="text-center" >
                      <a [routerLink]="[ '/pricing/invoices',billing?.id]" class="btn btn-danger" *ngIf="billing?.status == 'Sent'" i18n>Payez</a>
                      <span class="badge bg-soft-danger text-danger p-2" *ngIf="billing?.status == 'Canceled'" i18n>Annulé</span>
                      <span class="badge bg-soft-danger text-danger p-2" *ngIf="billing?.status == 'Expired'" i18n>Expiré</span>
                      <span class="text-success p-2" *ngIf="billing?.status == 'Paid'" i18n>PAYÉ</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <ul class="pagination pagination-rounded justify-content-end my-2" *ngIf="billingData?.length !=0">
              <ngb-pagination [disabled]='loading' [maxSize]="5" [collectionSize]="totalSize" (pageChange)="onPageChange($event)"
              [(page)]="page" [pageSize]="limit"></ngb-pagination>
            </ul>
          </div>
        </div>
      </div>
    </div>
</div>
  
  
  