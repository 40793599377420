import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformServer, isPlatformBrowser } from '@angular/common';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { environment } from "src/environments/environment";
import { AuthenticationService } from 'src/app/core/services/client/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        @Inject(PLATFORM_ID) private platformId: any,
        private authenticationService: AuthenticationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authenticationService.currentUser();
        if (currentUser) {
            // logged in so return true
            return true;
        }

        if (isPlatformServer(this.platformId)) {
            return true
  
        }

        if (isPlatformBrowser(this.platformId)) {
            window.location.href = `${environment.loginPageUrl}?returnUrl=${state.url }`
        }

        // not logged in so redirect to login page with the return url
        
        return false;
    }
}