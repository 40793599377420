import { Component, OnInit, ViewChild } from '@angular/core';
import {NgbModal, NgbActiveModal, NgbModalConfig} from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from "rxjs";
import { User } from 'src/app/core/models/auth.models';
import { Site } from 'src/app/core/models/site';
import { AuthenticationService } from 'src/app/core/services/client/auth.service';
import { ActivePlansService } from "src/app/core/services/plan/active.plans.service";
@Component({
  selector: 'app-active-dialog',
  templateUrl: './active-dialog.component.html',
  styleUrls: ['./active-dialog.component.scss'],
  providers: [NgbModalConfig, NgbModal]
})
export class ActiveDialogComponent implements OnInit {
  activeModal:NgbActiveModal= null;
  subscription:Subscription;
  Site:Site;
  User :User;
  @ViewChild('ActiveAccountModal',{static:false}) activeAccountModal: any;
  constructor( private authenticationService:AuthenticationService,private activePlansService:ActivePlansService,
               private modalService: NgbModal,config: NgbModalConfig,) { 

    this.User = this.authenticationService.currentUser();
    this.Site= this.User.getselectSite(); 
    config.backdrop = 'static';
    config.keyboard = false;

    this.activePlansService.openDialog.subscribe(
      ()=>{
        this.open()
      }
    )

  }

  ngOnInit() {
  }

  ngOnDestroy(): void {

    if(this.subscription){
      this.subscription.unsubscribe()
    }
  
    
  }

  open() {
    if(this.activeModal == null){
      this.activeModal = this.modalService.open(this.activeAccountModal, { centered: true});
    }

  }
  close() {
    this.activeModal.close();
    this.activeModal = null;
  }

}
