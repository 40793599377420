import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformServer, isPlatformBrowser, DOCUMENT } from '@angular/common';
import { environment } from "../../../environments/environment";
import { Subject } from "rxjs";
import { User, Profile } from "../models/auth.models";

@Injectable({
  providedIn: 'root'
})
export class CookieService {
  private tokenKey:string = 'app_token';
  usersubject = new Subject<User>();
  constructor(@Inject(PLATFORM_ID) private platformId: any,@Inject(DOCUMENT) private _document: any,) { }



  /**
   * Returns the cookie value by name
   * @param name cookie name
   */
  public getCookie(name: string) {
    if (isPlatformBrowser(this.platformId)) {
      try {
        return JSON.parse(localStorage.getItem(name))
      } catch (error) {
        return null
      }
      
    }else{
      return null
    }
  }

  /**
   * Deletes the cookie with given name
   * @param name cookie name
   * @param path path of the domain
   */
  public deleteCookie(name: string) {
    if (isPlatformBrowser(this.platformId)) {
      this.setCookie(name, '', -1);
      localStorage.setItem(name, '');

    }


  }

  /**
   * Creates/sets the cookie
   * @param name name of cookie
   * @param value cookie value
   * @param days validity in days
   */
  public setCookie(name: string, value: string, days: number) {
    if (isPlatformBrowser(this.platformId)) {
      try {
        localStorage.setItem(name, JSON.stringify(value));
        if(name == "user" && value!= "" && value!= null && value!= undefined){
          let user = this.parseUser()
          this.usersubject.next(user)
        }
      } catch (error) {
      }
      
    }
   

  }

  public deletestorage(name: string) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(name, '');
    }
  }

  public setstorage(name: string, value: string) {

    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem(name, JSON.stringify(value));
      if(name == "user"){
        let user = this.parseUser()
        this.usersubject.next(user)
      }
    }
   
  }
  public getstorage(name:string){
    if (isPlatformBrowser(this.platformId)) {
      return localStorage.getItem(name);
    }else{
      return null
    }

  }

  getCurentLocale(): string {
    
    let language:string = 'fr'
    if (isPlatformBrowser(this.platformId)) {
      language = this.getCookie('Language')
      if(language == null || language == undefined || language == ""){
        let navigator_lang = navigator.language || window.navigator.language;
        language = "fr"
      }
    }else{
      const baseUrl =  this._document.location.pathname;
      const matches = baseUrl.match(/^\/([a-z]{2}(?:-[A-Z]{2})?)\//);
      language = (matches && environment.supportedLocales.indexOf(matches[1]) !== -1) ? matches[1] : 'fr';
    }
    return language
  }

  set_facebook_token(token){
    if (isPlatformBrowser(this.platformId)) {
        localStorage.setItem("fbtoken", token);
        const moonLanding = new Date();
        const after_1_day = moonLanding.getTime()+ 1 * 24 * 60 * 60 * 1000
        const expire_in = new Date(after_1_day)
        localStorage.setItem("token_time", expire_in.getTime().toString());
    }
  }
  
  is_expired_facebook_token(){
    if (isPlatformBrowser(this.platformId)) {
      let token =  localStorage.getItem('fbtoken');
      if (token  == null || token == ""){return true}
      let token_time =null
      try {
        token_time = parseInt(localStorage.getItem('token_time'));
      } catch (error) {
        return true
      }

      let now =  new Date()
      let expire_in = new Date(token_time)
      return now > expire_in
    }else{
      return true
    }

  }

  getwsUrl(){
    let currentUser:User = JSON.parse(this.getCookie('user'))
    return  `${environment.WsUrl}?token=${currentUser.token}`
  }

  public parseUser():User{
    try {

      if (isPlatformBrowser(this.platformId)) {
        let user = new User();
        let objson = JSON.parse(this.getCookie('user'))
        user.email = objson.email
        user.first_name = objson.profile.first_name
        user.last_name = objson.profile.last_name
        user.id = objson.id
        user.profile = objson.profile
        user.token = objson.token
        user.setCompany(objson.companies)
        return user

      }

      if(isPlatformServer(this.platformId)){
        let user = new User();
        user.email = ""
        user.first_name = ""
        user.last_name = ""
        user.id = 0
        user.profile = new Profile()
        user.token = ""
        user.setCompany([])
        return user
      }


    } catch (error) {
      return null
    }
     
  
  }


  public parseMember():User{
    try {

      if (isPlatformBrowser(this.platformId)) {
        let user = new User();
        let objson = JSON.parse(this.getCookie('user'))
        user.email = objson.email
        user.first_name = objson.profile.first_name
        user.last_name = objson.profile.last_name
        user.id = objson.id
        user.profile = objson.profile
        user.token = objson.token 
        return user

      }

      if(isPlatformServer(this.platformId)){
        let user = new User();
        user.email = ""
        user.first_name = ""
        user.last_name = ""
        user.id = 0
        user.profile = new Profile()
        user.token = ""
        return user
      }


    } catch (error) {
      return null
    }
     
  
  }

}
