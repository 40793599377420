

export const PLAN_DETAILS = {
    "starter":{"name": "STARTER", "price":{"monthly" :{"euro" : 29.99,"dollar": 29.99},"yearly": {"euro" : 29.99,"dollar": 29.99}}, "nborder":200, 'orderPrice':0.09, "selected":false, "disabled":false},
    "booster":{"name": "BOOSTER", "price":{"monthly" :{"euro" : 49.99,'dollar': 49.99},"yearly": {"euro" : 49.99,"dollar": 49.99}}, "nborder":400, 'orderPrice':0.08, "selected":false, "disabled":false},
    "pro":{"name": "PRO", "price":{"monthly" :{"euro" : 99.99,"dollar": 99.99},"yearly": {"euro" : 99.99,"dollar": 99.99}}, "nborder":1000,'orderPrice':0.07, "selected":false, "disabled":false},
    "entreprise":{"name": "ENTREPRISE", "price":{"monthly" :{"euro" : 199.99,"dollar": 199.99},"yearly": {"euro" : 199.99,"dollar": 199.99}}, "nborder":1100,'orderPrice':0.07, "selected":false, "disabled":false}
}

export const WIX_PLAN_DETAILS = {
    "starter":{"name": "STARTER", "price":{"monthly" :{"euro" : 29.99,"dollar": 29.99},"yearly": {"euro" : 23.99,"dollar": 23.99}}, "nborder":200,'orderPrice':0.09, "selected":false, "disabled":false},
    "booster":{"name": "BOOSTER", "price":{"monthly" :{"euro" : 49.99,"dollar": 49.99},"yearly": {"euro" : 39.99,"dollar": 39.99}}, "nborder":400, 'orderPrice':0.08, "selected":false, "disabled":false},
    "pro":{"name": "PRO", "price":{"monthly" :{"euro" : 99.99,"dollar": 99.99},"yearly": {"euro" : 79.99,"dollar": 79.99}}, "nborder":1000,'orderPrice':0.07, "selected":false, "disabled":false},
    "entreprise":{"name": "ENTREPRISE", "price":{"monthly" :{"euro" : 199.99,"dollar": 199.99},"yearly": {"euro" : 199.99,"dollar": 199.99}}, "nborder":1100,'orderPrice':0.07, "selected":false, "disabled":false}
}
export const PLAN_TYPE= {
    "free":{"name": "FREE"},
    "starter":{"name": "STARTER"},
    "booster":{"name": "BOOSTER"},
    "pro":{"name": "PRO"},
    "entreprise":{"name": "ENTREPRISE"}
}