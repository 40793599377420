import { Injectable } from '@angular/core';
import { Subject } from "rxjs";
@Injectable({
  providedIn: 'root'
})
export class UpgradePlansService {
  openDialog = new Subject<string>();  
  constructor() { }

  
  public open(option) {
  
    this.openDialog.next(option)
  }

}
