import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformServer, isPlatformBrowser } from '@angular/common';
import { environment } from "src/environments/environment";
import { Router } from '@angular/router';
import { HttpParams } from "@angular/common/http";
import * as moment from 'moment';
import { User,Profile } from "src/app/core/models/auth.models";
import { Site } from "src/app/core/models/site";
import { UserPlan, PlanType } from "src/app/core/models/plan.models";
import { Company } from "src/app/core/models/company";
import { AuthenticationService } from 'src/app/core/services/client/auth.service';
import { WebsocketService } from "src/app/core/services/ws/websocket.service";
import { CookieService } from "src/app/core/services/cookie.service";
import { Notification,Result,Channels } from "src/app/core/models/notifications";
import { ApiService } from 'src/app/core/services/client/api.service';

import { Subject,Subscription } from "rxjs";
import { map } from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import Swal from 'sweetalert2';
import { FormBuilder, FormGroup } from '@angular/forms';
@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {
  User:User
  Site:Site
  siteList:Array<Site>=[];
  siteForm: FormGroup;
  UserPlan:UserPlan;
  PlanType=PlanType;
  Company:Company
  result:Result
  notificationItems: Array<{}>;
  notificationData:Array<Notification>
  Unread:number=0
  limit:number=5
  offset:number=0
  languages: Array<{
    id: number,
    flag?: string,
    name: string
  }>;
  selectedLanguage: {
    id: number,
    flag?: string,
    name: string
  };
  translateMessage:any
  openMobileMenu: boolean;
  CurentLocale:string='fr';
  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();
  public messages: Subject<string>;
  public refrechUser: Subscription;
  constructor(private router: Router,
              private CookieService:CookieService,
              private apiService:ApiService,
              private translate:TranslateService,
              @Inject(PLATFORM_ID) private platformId: any,
              private WebsocketService:WebsocketService,
              private formBuilder: FormBuilder,
              private authService: AuthenticationService) { 
                this.CurentLocale= this.CookieService.getCurentLocale();
                this.translate.get(['moment.now']).subscribe((data:any)=> {this.translateMessage = data})
                this.siteForm = this.formBuilder.group({siteId: ''});
                this.siteForm.valueChanges.subscribe(
                  data=>{
                      for (let index = 0; index <  this.siteList.length; index++) {
                        const element =  this.siteList[index];
                        if (element.id == data.siteId){
                          this.changeSite(element)
                          break;
                        }
                        
                      }
                    
                  }

                )
                
              }

  ngOnInit() {

      if (isPlatformBrowser(this.platformId)) {
          // get the notifications
        this.messages = <Subject<string>>this.WebsocketService.connect().pipe(map((response: MessageEvent): Channels => {
          return  response.data
        }))
        this.messages.subscribe((msg:string) => {
            let message = JSON.parse(msg)
            if(message['type'] == 'Notification'){
              let natification = this.date_diff([message['event_data']])
              if(natification){
                this.notificationData.unshift(natification[0])
                this.Unread +=1
                this.ShowAlert(natification[0])
              }
            
            }


          })


        this.refrechUser = this.CookieService.usersubject.subscribe(
          (value:User) =>{
            this.User = value 
            this.Company = this.User.getselectCompany()
            this.Site = this.User.getselectSite()
            if(this.Site){
              this.siteForm.controls.siteId.setValue(this.Site.id,{emitEvent:false})
            }
            
            if ( this.Company){
              this.UserPlan = this.Company.plan
              if (this.UserPlan){
                this.UserPlan.details.plan_type
              }
            
            }
          
            
          }
        )


        this.openMobileMenu = false;
        this.User = this.authService.currentUser()
        this.authService.RefrechUser()
        this.Company = this.User.getselectCompany()
        this.siteList = this.User.getSites();
        this.Site = this.User.getselectSite()
        if(this.Site){
          this.siteForm.controls.siteId.setValue(this.Site.id, {emitEvent:false})
        }
        if ( this.Company){
          this.UserPlan = this.Company.plan
          this.UserPlan.details.plan_type
        }
      
        this.fetchNotifications();
      }
   
  }


  ngOnDestroy(): void {
    if( this.refrechUser){
      this.refrechUser.unsubscribe()
    }
  
  }


  /**
   * Change the language
   * @param language language
   */
  changeLanguage(language) {
    this.selectedLanguage = language;
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Logout the user
   */
  logout() {
    window.localStorage.setItem('logout-event', Math.random().toString())
    this.authService.logout();
    window.location.href = environment.loginPageUrl
  }
  /**
   * 
   * @param natification 
   */

  redirectTo(notification:Notification){
      notification.unread = false;
      let params = new HttpParams()
      params = params.set('site', this.Site.id.toString())
      this.apiService.notificationUnread(notification.id, params).subscribe(
        (result:any)=>{
          if (this.Unread> 0){
            this.Unread --
          }
        
        },error=>{
  
        }
      )
      switch (notification.data.category) {
        case 'Comment':
        case 'Review':
        case 'Like':
        case 'Helpful':
          this.router.navigate(['/moderation/gestionAvis']);
        break;
        case 'Template_settings':
          this.router.navigate(['/collectreview/automaticCollection']);
          break;
        case 'Invoice':
          this.router.navigate(['/pricing/invoices']);
        break;
      
      }

      return false
  }

  ShowAlert(notification){
    if (notification.data.category=='Manual_Invitation_Fail'){
      Swal.fire({
        toast:true,
        text: notification.verb,
        type: 'error',
        timer: 1500,
        position:"top-right",
        showConfirmButton:false
      });
    }
    if (notification.data.category=='Manual_Invitation_Sent'){
      Swal.fire({
        toast:true,
        text: notification.verb,
        type: 'success',
        timer: 1500,
        position:"top-right",
        showConfirmButton:false
      });
    }
  }

  mark_all_as_read(){
    let params = new HttpParams()
    params = params.set('site', this.Site.id.toString())
    this.apiService.notificationUnreadAll(params).subscribe(
      (result:any)=>{
        
        this.notificationData.forEach(element => {
          element.unread = false
        });
        this.Unread = 0
      },error=>{

      }
    )
    return false
  }

  /**
   * Fetches the notification
   * Note: For now returns the hard coded notifications
   */
  fetchNotifications() {
    try {
      let params = new HttpParams()
      params =  params.set('limit',this.limit.toString())
      params =  params.set('offset',this.offset.toString())
      params =  params.set('site',this.Site.id.toString())
      this.apiService.notificationList(params).subscribe(
        (result:Result)=>{
          this.result= result
          this.notificationData = this.date_diff(this.result.results)
        },error=>{
  
        }
      )  
    } catch (error) {
      
    }
 

  }

  switchLang(lang: string) {
    this.translate.use(lang);
    this.CookieService.setCookie('Language', lang,30)
    this.CookieService.getCurentLocale()
    location.replace(`/${lang}${this.router.url}`)
    return false
  }

  date_diff(data:Array<Notification>) {
    this.Unread = 0
    data.forEach(element => {
      if (element.unread){
        this.Unread ++
      }
      let review_dat = element.timestamp
      let days = this.get_days(review_dat)
      let hours = this.get_hours(review_dat)
      let minute = this.get_minute(review_dat)
      let now = moment();
      if (days >= 1){
        let reviewdat = moment(review_dat).locale(this.CookieService.getCurentLocale()) 
        element.timestamp = reviewdat.format('MMMM Do YYYY, h:mm a')       
      }
      if(days < 1 && hours >= 1){
        element.timestamp = `${hours} h`
      }

      if(days < 1 && hours < 1 && minute >= 1){
        element.timestamp = `${minute} min`
      }
      if(days < 1 && hours < 1 && minute <= 1){
        element.timestamp = this.translateMessage['moment.now']
      }



    });
    return data
  }




  get_days(date) {
    let now = new Date();
    let reviewdat = new Date(date);
    let year =  Math.floor((Date.UTC(now.getFullYear(), now.getMonth(), now.getDate()) -Date.UTC(reviewdat.getFullYear(), reviewdat.getMonth(), reviewdat.getDate()) ) /(1000 * 60 * 60 * 24));
    return year
  }

  get_hours(date) {
    let now = new Date();
    let reviewdat = new Date(date);
    var diff =(now.getTime() - reviewdat.getTime()) / 1000;
    diff /= (60 * 60);
    let hours =  Math.abs(Math.round(diff))  
    return hours
  }

  get_minute(date) {
    let now = new Date();
    let reviewdat = new Date(date);
    var diff =(now.getTime() - reviewdat.getTime()) / 1000;
    diff /= 60;
    let minuts =  Math.abs(Math.round(diff));
    return minuts
  }
  changeSite(item:Site){
    this.User.selectSite(item.id)
    this.User.selectCompany(item.company.id)
    this.CookieService.setCookie('site_id', item.id.toString(), 30);
    this.CookieService.setCookie('company_id', item.company.id.toString(), 30);
    this.CookieService.setCookie('user', JSON.stringify(this.User), 30);
    window.location.reload()
  }
}
