import { Component, OnInit, AfterViewInit, ElementRef, ViewChild, Input,Inject, OnChanges,PLATFORM_ID, Output, EventEmitter, HostListener, Renderer2 } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import MetisMenu from 'metismenujs/dist/metismenujs';
import { UserPlan, PlanType } from "src/app/core/models/plan.models";
import { AuthenticationService } from "src/app/core/services/client/auth.service";
import { CookieService } from "src/app/core/services/cookie.service";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { User,Profile } from "src/app/core/models/auth.models";
import { Site } from "src/app/core/models/site";
import { FormBuilder, FormGroup } from '@angular/forms';
import { Company } from "src/app/core/models/company";
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, AfterViewInit, OnChanges {
  UserPlan:UserPlan
  PlanType=PlanType;
  @Input() isCondensed = false;
  siteForm: FormGroup;
  siteList:Array<Site>=[];
  menu: any;
  User:User
  Company:Company
  Site:Site
  @ViewChild('sideMenu', { static: false }) sideMenu: ElementRef;
  @Output() sidebarClicked = new EventEmitter();
  public refrechUser: Subscription;
  constructor(private authenticationService:AuthenticationService,
              private modalService: NgbModal,
              private renderer: Renderer2,
              private elementRef: ElementRef,
              private authService: AuthenticationService,
              private CookieService:CookieService,
              private formBuilder: FormBuilder,
              @Inject(PLATFORM_ID) private platformId: any,) {
                 
                // this.User = this.authService.currentUser()
                // this.Site = this.User.getselectSite()
                // this.Company = this.User.getselectCompany()
                // this.siteList = this.User.getSites();
                

    
      this.siteForm = this.formBuilder.group({siteId: ''});
      if(this.Site){
        this.siteForm.controls.siteId.setValue(this.Site.id, {emitEvent:false})
      }
      this.siteForm.valueChanges.subscribe(
        data=>{
            for (let index = 0; index <  this.siteList.length; index++) {
              const element =  this.siteList[index];
              if (element.id == data.siteId){
                this.changeSite(element)
                break;
              }
              
            }
          
        }

      )
  }

  ngOnInit() {

    if (isPlatformBrowser(this.platformId)) {


      this.refrechUser = this.CookieService.usersubject.subscribe(
        (value:User) =>{
          this.User = value 
          this.Company = this.User.getselectCompany()
          this.Site = this.User.getselectSite()
          if(this.Site){
            this.siteForm.controls.siteId.setValue(this.Site.id,{emitEvent:false})
          }
          
          
        }
      )

      this.User = this.authService.currentUser()
      this.authService.RefrechUser()
      this.Company = this.User.getselectCompany()
      this.siteList = this.User.getSites();
      this.Site = this.User.getselectSite()
      if(this.Site){
        this.siteForm.controls.siteId.setValue(this.Site.id, {emitEvent:false})
      }



      this.UserPlan = this.authenticationService.CurrentUserPlan()
      this.CookieService.usersubject.subscribe(
        (value) =>{
          this.UserPlan = this.authenticationService.CurrentUserPlan()
        }
      )



    }


    
       
  
  }
  ngOnDestroy(): void {
    if( this.refrechUser){
      this.refrechUser.unsubscribe()
    }
  
  }

  ngAfterViewInit() {
    this.menu = new MetisMenu(this.sideMenu.nativeElement);
    this._activateMenuDropdown();

  }

  ngOnChanges() {
    if (!this.isCondensed && this.sideMenu || this.isCondensed) {
      setTimeout(() => {
        this.menu = new MetisMenu(this.sideMenu.nativeElement);
      });
    } else if (this.menu) {
      this.menu.dispose();
    }
  }



  /**
   * Toggle the menu bar when having mobile screen
   */
   toggleSideBarMenu(event: any) {
    event.preventDefault();
    this.sidebarClicked.emit();
  }


  /**
   * Activates the menu dropdown
   */
  _activateMenuDropdown() {
    if (isPlatformBrowser(this.platformId)) {
      const links = document.getElementsByClassName('side-nav-link-ref');
      let menuItemEl = null;
      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < links.length; i++) {
        // tslint:disable-next-line: no-string-literal
        if (window.location.pathname === links[i]['pathname']) {
          menuItemEl = links[i];
          break;
        }
      }

      if (menuItemEl) {
        menuItemEl.classList.add('active');
  
        const parentEl = menuItemEl.parentElement;
        if (parentEl) {
          parentEl.classList.add('active');
  
          const parent2El = parentEl.parentElement;
          if (parent2El) {
            parent2El.classList.add('in');
          }
  
          const parent3El = parent2El.parentElement;
          if (parent3El) {
            parent3El.classList.add('active');
            parent3El.firstChild.classList.add('active');
          }
        }
      }
  
    }
  

    
  }
  changeSite(item:Site){
    this.User.selectSite(item.id)
    this.User.selectCompany(item.company.id)
    this.CookieService.setCookie('site_id', item.id.toString(), 30);
    this.CookieService.setCookie('company_id', item.company.id.toString(), 30);
    this.CookieService.setCookie('user', JSON.stringify(this.User), 30);
   
    window.location.reload()
  }

  /**
   * Open cconformation
   * @param centerDataModal center modal data
   */
  centerModal(centerDataModal: string) {
    this.modalService.open(centerDataModal, { centered: true,  windowClass: 'login-dialog',backdrop : 'static' });
    return false;
    
  }
  CopySitekey(){
    var el = document.createElement('textarea');
    el.value = this.Site.site_key;
    el.setAttribute('readonly', '');
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }
 
  


}
