import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ActivePlansService {

  openDialog = new Subject<any>();  
  constructor() { 

  
  }

  
  public open() {
    this.openDialog.next("open")
  }
}
