import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {IPayPalConfig,ICreateOrderRequest } from 'ngx-paypal';
import { Site } from 'src/app/core/models/site';
import { environment } from "src/app/../environments/environment";
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/core/services/client/api.service';
@Component({
  selector: 'app-paypalcheckout',
  templateUrl: './paypalcheckout.component.html',
  styleUrls: ['./paypalcheckout.component.scss']
})
export class PaypalcheckoutComponent implements OnInit {
  @Input() Site:Site;
  @Input() amount:number;
  @Input() name:string;
  @Output() CapturePayPalOrder= new EventEmitter<any>();
  authorizationID:string;
  orderID:string
  loading:boolean = false;
  payment_method:string='stripe';
  public payPalConfig ? : IPayPalConfig;
  paypal_showSuccess = true;
  paypal_showCancel = true
  paypal_showError = true
  payer_id:string 
  order_id:string
  activeModal:NgbActiveModal
  @ViewChild("PaypalModal",{static:false}) PaypalModal;
  constructor(private apiService:ApiService,private modalService:NgbModal) { }

  ngOnInit(): void {
    
  }

  checkout() {
    // Check the server.js tab to see an example implementation
    if(this.activeModal){this.activeModal.dismiss()}
    this.activeModal = this.modalService.open(this.PaypalModal, { centered: true, });
    this.initConfig();
    return false; 
  }

  private initConfig(): void {
    this.payPalConfig = {
        currency: 'EUR',
        clientId: environment.PayPalClientId,
        createOrderOnClient: (data) => < ICreateOrderRequest > {
            intent: 'CAPTURE',
            purchase_units: [{
                amount: {
                    currency_code: 'EUR',
                    value: this.amount.toString(),
                    breakdown: {
                        item_total: {
                            currency_code: 'EUR',
                            value: this.amount.toString()
                        }
                    }
                },
                items: [{
                    name: this.name,
                    quantity: '1',
                    category: 'DIGITAL_GOODS',
                    unit_amount: {
                        currency_code: 'EUR',
                        value: this.amount.toString(),
                    },
                }]
            }]
        },
        advanced: {
            commit: 'true'
        },
        style: {
            label: 'paypal',
            layout: 'vertical'
        },
        onApprove: (data, actions) => {
            actions.order.get().then(details => {
            });

        },
        onClientAuthorization: (authorization) => {
            this.orderID = authorization['id']

            this.paypal_showSuccess = true;
            this.CapturePayPalOrder.emit({orderID:this.orderID})
        },
        onCancel: (data, actions) => {
            this.paypal_showCancel = true;

        },
        onError: err => {
            this.paypal_showError = true;
        },
        onClick: (data, actions) => {
         
        }
    };
  }

 

}
