import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from "src/app/core/services/cookie.service";
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
   CurentLocale:string='fr';
   date:Date = new Date()
  constructor(private translateservice:TranslateService,
              private CookieService:CookieService,
              private router: Router,  ) { 
              }

  ngOnInit() {
    this.CurentLocale= this.CookieService.getCurentLocale()
  }

  switchLang(lang: string) {
    this.translateservice.use(lang);
    this.CookieService.setCookie('Language', lang,30)
    this.CookieService.getCurentLocale()
    location.replace(`/${lang}${this.router.url}`)
    return false
  }
}
