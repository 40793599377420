import {Site} from './site';
import { UserPlan } from "./plan.models";

export class Company {
  id: number;
  company_name: string;
  siret: string;
  vat?:string;
  address: Address;
  phone: string;
  email?:string;
  language?:string;
  sites = [new Site()];
  plan:UserPlan
  selected:boolean
  constructor(id=null, company_name=null, siret=null, address=null, 
              phone=null, sites=null, plan=null, email=null){
      this.id = id
      this.company_name= company_name
      this.siret = siret
      this.address =  address
      this.phone = phone
      this.email= email;
      this.sites = sites
      this.plan = plan
      this.selected = false
  }

}
export class Address{
  id?: number  ​​​
  address_line: string  ​​​
  city: string  ​​​
  country: string ​
  zip_code: number

  constructor(id=null, address_line=null, city=null, country=null, 
              zip_code=null){
    this.id=id​​​
    this.address_line = address_line
    this.city = city
    this.country= country
    this.zip_code = zip_code
  }


}
​​​
