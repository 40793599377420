import { Injectable } from '@angular/core';
import { AuthenticationService } from "../client/auth.service";
import { CookieService } from "../cookie.service";
import { Observable } from 'rxjs'
import { Subject } from "rxjs";
import { map } from "rxjs/operators"; 
import {Observer} from "rxjs";
@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  socket:WebSocket
  constructor(private auth : AuthenticationService,
              private CookieService:CookieService) { }

   connect(): Subject<MessageEvent> {
    this.socket = new WebSocket(this.CookieService.getwsUrl());
 
    let observable = Observable.create(
      (obs: Observer<MessageEvent>) => {
        this.socket.onmessage = obs.next.bind(obs);
        this.socket.onerror = obs.error.bind(obs);
        this.socket.onclose = obs.complete.bind(obs);
        return this.socket.close.bind(this.socket);
      })
    let observer = {
      next: (data: string) => {
        if (this.socket.readyState === WebSocket.OPEN) {
          this.socket.send(data);
          if(data=="stop")
          this.socket.close(1000,"bye")
        }
      }
    }
    return Subject.create(observer, observable);
  }


  disconnected(){
    this.socket.close();
  }
}
