import { Injectable , ErrorHandler, Injector} from '@angular/core';
import { PLATFORM_ID, Inject } from '@angular/core';
import { environment } from "src/environments/environment";
import { isPlatformServer, isPlatformBrowser } from '@angular/common';
import * as Sentry from "@sentry/angular";
import { Integrations } from "@sentry/tracing";


@Injectable({
  providedIn: 'root'
})
export class SentryService implements ErrorHandler{

  constructor(private injector:Injector,@Inject(PLATFORM_ID) private platformId: any) { 


    if (isPlatformBrowser(this.platformId)) {
      Sentry.init({
        ignoreErrors: [
          "Non-Error exception captured",
          "Non-Error promise rejection captured",
          "Site introuvable",
          "Unknown Error",
          "AbortError: The operation was aborted",
          "AbortError: Share canceled",
          "Not logged in"
        ],
        dsn: environment.dsn,
          beforeSend(event, hint) {
            /* tslint:disable:no-string-literal only-arrow-functions */
            if (hint.originalException === "Timeout") return null;
            const error = hint.originalException;
            const chunkFailedMessage = /Loading chunk [\d]+ failed/;
            if (
              error &&
              error['message'] &&
              error['message'].match('Non-Error exception captured') &&  
              chunkFailedMessage.test(error['message'])) {
              return null;
            }
            return event;
        }
      });
    }
    // if (isPlatformBrowser(this.platformId) && !environment.production ) {
    //   Sentry.init({
    //     ignoreErrors: [
    //       "Non-Error exception captured",
    //       "Non-Error promise rejection captured",
    //       "Site introuvable",
    //       "Unknown Error"
    //     ],
    //     dsn: "https://9855827b2e244df49578b97220d32f80@o429304.ingest.sentry.io/6035974",
    //       beforeSend(event, hint) {
    //         if (hint.originalException === "Timeout") return null;
    //         const error = hint.originalException;
    //         const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    //         if (
    //           error &&
    //           error['message'] &&
    //           error['message'].match('Non-Error exception captured') &&  
    //           chunkFailedMessage.test(error['message'])) {
    //           return null;
    //         }
    //         return event;
    //     },
    //     integrations: [
    //       new Integrations.BrowserTracing({
    //         tracingOrigins: ["localhost", environment.frontUrl],
    //         routingInstrumentation: Sentry.routingInstrumentation,
    //       }),
    //     ],
    //     tracesSampleRate: 1.0,
    //   });
    // }
   
  }
  handleError(error: any): void {

    const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    if (chunkFailedMessage.test(error.message)) {
      window.location.reload();
    }
    Sentry.captureException(error.originalError || error.error || error)
   
  }
}
