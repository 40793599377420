<!-- ========== Left Sidebar Start ========== -->
<ng-template #contentTemplate>
    <!--- Sidemenu -->
    <div id="sidebar-menu" >
        <ul class="metismenu" id="side-menu" #sideMenu >
            <li class="menu-title" i18n>Sites</li>
            <li class="d-none d-lg-block">
                <a href="javascript: void(0);" class="waves-effect">
                    <i class="mdi mdi-briefcase"></i>
                    <span *ngIf="Site?.trademark.length <=20">{{Site?.trademark }}</span>
                    <span *ngIf="Site?.trademark.length >=20"> {{ Site?.trademark | slice:0:20 }}..</span>
                    <span class="menu-arrow"></span>
                </a>
                <ul class="nav-second-level collapse" aria-expanded="false">
                    <li>
                        <a href="{{Site?.url}}" target="_blank" class="side-nav-link-ref pl-1"><span class=" mdi mdi-web mr-1"></span><span i18n>Voir le site </span>
                           </a>
                    </li>
                    <li>
                        <a href="{{Site?.page_profile}}" target="_blank" class="side-nav-link-ref pl-1"><span class="mdi mdi-clipboard-account mr-1"></span> <span i18n>Page de profil</span></a>
                    </li>
                    <li>
                        <a href="javascript: void(0);" (click)="CopySitekey()" placement="top" i18n-ngbTooltip ngbTooltip="Cliquez pour copier la clé du site" class="side-nav-link-ref pl-1"><span><span class="mdi mdi-shield-key mr-1"></span>{{Site?.site_key}}</span></a>
                    </li>
                    <li *ngIf="Site?.provider">
                        <a href="javascript: void(0);" class="side-nav-link-ref pl-1"><span><span class="mdi mdi-power-plug mr-1"></span>{{Site?.provider| titlecase}}</span></a>
                    </li>
                </ul>
            </li>


            <li class="dropdown d-block d-lg-none" ngbDropdown>
                <form class="app-site-choice" [formGroup]="siteForm">
                  <div class="app-site-choice-box ">
                    <div class="form-group mb-0"  >
                      <ng-select [items]="siteList"   [searchable]="false" [clearable]="false" formControlName="siteId" bindLabel="trademark" bindValue="id">
                        <ng-template ng-option-tmp let-item="item" let-index="index">
                            <div class="p-1">
                              <i class="mdi mdi-store" ></i>
                              <span class="site-trademark font-weight-bold ml-1">{{item?.trademark | titlecase }}</span>
                              <p ><small>{{item?.company?.company_name | titlecase }}</small></p>
                            </div>
                        </ng-template>
                    </ng-select>
          
          
          
                    </div>
                  </div>
                </form>
                
               
            </li>



            <li class="menu-title" i18n>Navigation</li>
            <li>
                <a routerLink="/dashboard" routerLinkActive="active" class="side-nav-link-ref">
                    <i class="mdi mdi-home"></i>

                    <span i18n>Dashboard</span>
                </a>

            </li>

            <li>
                <a href="javascript: void(0);" class="waves-effect">
                    <i class="mdi mdi-star"></i>
                    <span i18n>Modération</span>
                    <span class="menu-arrow"></span>
                </a>
                
                <ul class="nav-second-level collapse" aria-expanded="false">
                  
                    <li>
                        <a routerLink="/moderation/gestionAvis" routerLinkActive="active" class="side-nav-link-ref" i18n>Gestion Des Avis</a>
                    </li> 
                    <li>
                        <a routerLink="/moderation/claims" routerLinkActive="active" class="side-nav-link-ref" i18n>Réclamations</a>
                    </li>
                    <li>
                        <a routerLink="/moderation/auto-reply" routerLinkActive="active" class="side-nav-link-ref" >
                            <span i18n>Réponse automatique</span>
                            <span *ngIf="UserPlan?.details?.plan_type != PlanType.booster && UserPlan?.details?.plan_type != PlanType.pro && UserPlan?.details?.plan_type != PlanType.enterprise" class="mdi mdi-arrow-up-bold-hexagon-outline float-right text-warning" placement="top" >
                        </span>

                        </a>
                    </li> 
                    <li>
                        <a routerLink="/moderation/visual-ugc" routerLinkActive="active" class="side-nav-link-ref" i18n>Galerie UGC</a>
                    </li>
                    <li>
                        <a routerLink="/moderation/googlereviews" routerLinkActive="active" class="side-nav-link-ref">
                            <span i18n>Avis Google</span>
                            <span *ngIf="UserPlan?.details?.plan_type != PlanType.booster && UserPlan?.details?.plan_type != PlanType.pro && UserPlan?.details?.plan_type != PlanType.enterprise" class="mdi mdi-arrow-up-bold-hexagon-outline float-right text-warning" 
                                placement="top" >
                            </span>
                        </a>
                    </li>
                    <li>
                        <a routerLink="/moderation/facebookreviews" routerLinkActive="active" class="side-nav-link-ref" >
                            <span i18n>Avis Facebook</span>
                            <span *ngIf="UserPlan?.details?.plan_type != PlanType.booster && UserPlan?.details?.plan_type != PlanType.pro && UserPlan?.details?.plan_type != PlanType.enterprise" class="mdi mdi-arrow-up-bold-hexagon-outline float-right text-warning" 
                                placement="top" >
                            </span>
                        </a>
                    </li>
                    <li>
                        <a routerLink="/moderation/import-review" routerLinkActive="active" class="side-nav-link-ref" i18n>Importer vos notes</a>
                    </li>
                   
                   

                </ul>
            </li>





            <li>
                <a href="javascript: void(0);" class="waves-effect">
                    <i class="mdi mdi-message-text-outline"></i>
                    <span i18n> Collecte des avis </span>
                    <span class="menu-arrow"></span>
                </a>
                <ul class="nav-second-level collapse" aria-expanded="false">

                    <li>
                        <a routerLink="/collectreview/automaticCollection" routerLinkActive="active" class="side-nav-link-ref" >
                            <span i18n>Collecte Automatique</span>
                        </a>

                    </li>
                    <li>
                        <a routerLink="/collectreview/manualCollectionlist" routerLinkActive="active" class="side-nav-link-ref" >
                            <span i18n>Collecte Manuelle</span>
                            
                        </a>
                    </li>
                    <li>
                        <a routerLink="/collectreview/requestForm" routerLinkActive="active" class="side-nav-link-ref" >
                            <span i18n>Formulaire d'évaluation</span>
                        </a>

                    </li>
                    <li>
                        <a routerLink="/collectreview/qr-code" routerLinkActive="active" class="side-nav-link-ref" >
                            <span i18n>QR code</span>
                        </a>

                    </li>
                    <li>
                        <a routerLink="/collectreview/messenger" routerLinkActive="active" class="side-nav-link-ref" >
                            <span i18n>Bot Messenger</span>
                            <span class="badge bg-primary rounded-pill ml-1">Beta</span>
                        </a>

                    </li>
                    <li>
                        <a routerLink="/collectreview/emailHistory" routerLinkActive="active" class="side-nav-link-ref" i18n>Invitations</a>
                    </li>

                </ul>
            </li>

            <li>
                <a href="javascript: void(0);" class="waves-effect">
                    <i class="mdi mdi-magnet"></i>
                    <span i18n> Marketing </span>
                    <span  class="menu-arrow"></span>
                </a>
                <ul class="nav-second-level collapse" aria-expanded="false">
                    <li>
                        <a routerLink="/socialads" routerLinkActive="active" class="side-nav-link-ref" >
                            <span i18n>Social Ads</span>
                            <span *ngIf="UserPlan?.details?.plan_type != PlanType.booster && UserPlan?.details?.plan_type != PlanType.pro && UserPlan?.details?.plan_type != PlanType.enterprise" class="mdi mdi-arrow-up-bold-hexagon-outline float-right text-warning" 
                                placement="top"  >
                            </span>
                        </a>
                    </li>
                    <li>
                        <a routerLink="/marketing/review-image" routerLinkActive="active" class="side-nav-link-ref" >
                            <span i18n>Éditeur d'images</span>
                            <span class="badge bg-primary rounded-pill ml-1">Beta</span>
                        </a>
                    </li>
                    <li>
                        <a routerLink="/marketing/socialPush" routerLinkActive="active" class="side-nav-link-ref" i18n>Social Push</a>
                    </li>
                    <li>
                        <a routerLink="/marketing/facebooktab" routerLinkActive="active" class="side-nav-link-ref" i18n>Facebook Tab</a>
                    </li>
                    <li>
                        <a routerLink="/marketing/googleShopping" routerLinkActive="active" class="side-nav-link-ref">
                            <span class="mr-1">Google Shopping</span>
                            <span *ngIf="UserPlan?.details?.plan_type != PlanType.booster && UserPlan?.details?.plan_type != PlanType.pro && UserPlan?.details?.plan_type != PlanType.enterprise" class="mdi mdi-arrow-up-bold-hexagon-outline float-right text-warning" 
                                placement="top"  >
                            </span>
                        </a>

                    </li>
                    <li>
                        <a routerLink="/marketing/catalog" routerLinkActive="active" class="side-nav-link-ref">
                            <span class="mr-1" i18n>Flux Catalogues</span>
                            <span *ngIf="UserPlan?.details?.plan_type != PlanType.booster && UserPlan?.details?.plan_type != PlanType.pro && UserPlan?.details?.plan_type != PlanType.enterprise" class="mdi mdi-arrow-up-bold-hexagon-outline float-right text-warning" 
                                placement="top"  >
                            </span>
                        </a>

                    </li>
                    <li>
                        <a routerLink="/products/list" routerLinkActive="active" class="side-nav-link-ref" i18n>Liste des produits</a>
                        <a routerLink="/products/group" routerLinkActive="active" class="side-nav-link-ref" ><span i18n>Regroupement de produits</span>
                            <span *ngIf="UserPlan?.details?.plan_type != PlanType.booster && UserPlan?.details?.plan_type != PlanType.pro && UserPlan?.details?.plan_type != PlanType.enterprise" class="mdi mdi-arrow-up-bold-hexagon-outline float-right text-warning" 
                                placement="top"  >
                            </span>
                        </a>
                    </li>
                </ul>
            </li>

            <li>
                <a href="javascript: void(0);" class="waves-effect">
                    <i class="mdi mdi-flash-circle"></i>
                    <span i18n>SEO</span>
                    <span class="menu-arrow"></span>
                </a>
                <ul class="nav-second-level collapse" aria-expanded="false">
                    <li>
                        <a routerLink="/seo/page-seo" routerLinkActive="active" class="side-nav-link-ref" i18n>Page seo</a>
                    </li>
                </ul>
            </li>

            <li>
                <a href="javascript: void(0);" class="waves-effect">
                    <i class="mdi mdi-chart-bar"></i>
                    <span i18n> Statistique </span>
                    <span class="menu-arrow"></span>
                </a>
                <ul class="nav-second-level collapse" aria-expanded="false">
                    <li>
                        <a routerLink="/analytics/avis" routerLinkActive="active" class="side-nav-link-ref" i18n>Avis</a>
                    </li>
                    <li>
                        <a routerLink="/analytics/seo" routerLinkActive="active" class="side-nav-link-ref" >Seo</a>
                    </li>
                    <li>
                        <a routerLink="/analytics/performance" routerLinkActive="active" class="side-nav-link-ref"  i18n>Performance</a>
                    </li>
                    <li>
                        <a routerLink="/analytics/shopping-feed" routerLinkActive="active" class="side-nav-link-ref"  i18n>Google Shopping</a>
                    </li>
                    <li>
                        <a routerLink="/analytics/google-ads" routerLinkActive="active" class="side-nav-link-ref"  i18n>Google ads</a>
                    </li>

                </ul>

            </li>


            <li>
                <a routerLink="/integrationCMS/e-commerce" routerLinkActive="active" class="side-nav-link-ref">
                    <i class="mdi mdi-power-plug"></i>
                    <span i18n>Intégration</span>
                </a>

            </li>

            <li>
                <a routerLink="/widgets"  routerLinkActive="active" href="javascript: void(0);" class="waves-effect">
                    <i class="mdi mdi-widgets"></i>
                    <span i18n>Widgets</span>
                
                    <span class="menu-arrow"></span>
                </a>
                <ul class="nav-second-level collapse" aria-expanded="false">
                    <li>
                        <a routerLink="/widgets/multilangue" routerLinkActive="active" class="side-nav-link-ref"> <span i18n>Multilingue</span>
                            <span *ngIf="UserPlan?.details?.plan_type != PlanType.booster && UserPlan?.details?.plan_type != PlanType.pro && UserPlan?.details?.plan_type != PlanType.enterprise" class="mdi mdi-arrow-up-bold-hexagon-outline float-right text-warning" 
                            placement="top" >
                        </span>
                        </a>
                   
                    </li>
                    <li>
                        <a routerLink="/widgets/badge-certification" routerLinkActive="active" class="side-nav-link-ref"> 
                            <span i18n>Badge d'excellence</span>
                        </a>
                    </li>
                    <li>
                        <a routerLink="/widgets/starratings" routerLinkActive="active" class="side-nav-link-ref"> <span i18n>Star ratings</span></a>
                    </li>
                    <li>
                        <a routerLink="/widgets/carousel" routerLinkActive="active" class="side-nav-link-ref"> <span i18n>Carrousel</span></a>
                    </li>
                    <li>
                        <a routerLink="/widgets/photo-galleries" routerLinkActive="active" class="side-nav-link-ref"> 
                            <span i18n>Carrousel UGC</span>
                            <span class="badge bg-primary rounded-pill ml-1">Beta</span>
                        </a>
                    </li>
                    <li>
                        <a routerLink="/widgets/badge" routerLinkActive="active" class="side-nav-link-ref"> <span i18n>Badges</span></a>
                    </li>
                    <li>
                        <a routerLink="/widgets/productswidget" routerLinkActive="active" class="side-nav-link-ref"> <span i18n>Widget produits</span> </a>
                    </li>
                    <li>
                        <a routerLink="/widgets/certiwidget" routerLinkActive="active" class="side-nav-link-ref"> <span i18n>Certi widgets</span></a>
                    </li>
                    <li>
                        <a routerLink="/widgets/reviews-page" routerLinkActive="active" class="side-nav-link-ref"> <span i18n>Page d'avis</span></a>
                    </li>
                </ul>
            </li>






            <li>
                <a routerLink="/settings/personalization" routerLinkActive="active" class="side-nav-link-ref">
                    <i class="mdi mdi-settings-outline"></i>
                    <span i18n>Paramètres</span>
                </a>

            </li>
            <li>
                <a href="javascript: void(0);" class="waves-effect ">
                    <i class="mdi mdi-bank"></i>
                    <span i18n>Paiement</span>
                    <span class="menu-arrow"></span>
                </a>
                <ul class="nav-second-level collapse" aria-expanded="false">
                    <li>
                        <a routerLink="/pricing/upgrade" routerLinkActive="active" class="side-nav-link-ref" i18n>Packs</a>
                    </li>
                    <li>
                        <a routerLink="/pricing/invoices" routerLinkActive="active" class="side-nav-link-ref"> <span i18n>Factures</span></a>
                    </li>

                </ul>
            </li>


            <li>
                <a class="side-nav-link-ref" (click)="centerModal(centerSupporteModal)">
                    <i class="mdi mdi-information-variant"></i>
                    <span i18n>Support</span>
                </a>

            </li>



        </ul>

    </div>
    <!-- End Sidebar -->

    <div class="sidebar-menu-bottom pb-2" id="sidebar-menu">
        <ul class="metismenu" id="side-menu" #sideMenu >
            <li class="pl-2 pr-2" *ngIf="UserPlan?.active">
                <span class="text-light font-14 pl-1 pr-1 ">Votre pack:</span>
                <span class="text-light font-14 "><b>{{UserPlan?.details.plan_type}}</b></span>
            </li>
            <li class="pl-2 pr-2" *ngIf="UserPlan?.active && UserPlan?.details.plan_type =='FREE'">
                    <a class="btn btn-success mt-1 p-1 font-13" [routerLink]="[ '/pricing/upgrade']" i18n>Optimisez votre pack</a>
            </li>  
            <li class="pl-2 pr-2" *ngIf="UserPlan?.active &&  UserPlan?.details.plan_type =='STARTER'">
                <a class="btn btn-success mt-1 p-1 font-13" [routerLink]="[ '/pricing/upgrade']" i18n>Passez au pack Booster</a>
            </li> 
            <li class="pl-2 pr-2" *ngIf="UserPlan?.active &&  UserPlan?.details.plan_type =='BOOSTER'">
                <a class="btn btn-success mt-1 p-1 font-13" [routerLink]="[ '/pricing/upgrade']" i18n>Passez au pack Pro</a>
            </li>
            

            <li class="pl-2 pr-2" *ngIf="UserPlan?.active &&  UserPlan?.details.plan_type =='PRO'">
                <a class="btn btn-success mt-1 p-1 font-13" [routerLink]="[ '/pricing/upgrade']" i18n>Passez au pack Entreprise</a>
            </li>   
            <li class="pl-2 pr-2" *ngIf="!UserPlan?.active && UserPlan?.details.plan_type !='FREE'">
                    <a class="btn btn-danger mt-1 p-1 font-13" [routerLink]="[ '/pricing/invoices']" i18n>Compte desactivé </a>
            </li>
        </ul>
    </div>
    <div class="clearfix">  
    
    </div>
</ng-template>


<div class="left-side-menu d-print-none">
    <div class="slimscroll-menu " appSlimScroll *ngIf="!isCondensed">
        <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>

    <div class="slimscroll-menu" *ngIf="isCondensed" >
        <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
</div>
<!-- Left Sidebar End -->

<!-- Center Modal -->
<ng-template #centerSupporteModal let-modal="close">
    <div class="modal-header p-2">
        <button type="button" class="close" aria-label="Close" (click)="modal('Cross click')">
        <span class="mdi mdi-close"></span>
      </button>
    </div>
    <div class="modal-body p-2 text-center">
        <h3 class="text-center mb-4" i18n>Besoin d'aide ? Contactez-nous !</h3>
        <h5><span class="mdi mdi-phone m-2 text-primary"></span> +33 1 77 62 73 76 <span class="mdi mdi-email m-2 text-primary ml-2"></span> support@certishopping.com</h5>
        <p i18n>Nous vous conseillons personnellement et répondons à toutes vos questions.
          
        </p>
        <button  class="btn btn-blue"><a href="https://calendly.com/certishopping/30min?month=2024-04" class="calendly"><span i18n>Planifiez votre rendez-vous</span></a></button>
    </div>
</ng-template>

<script type="text/javascript">
    (function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "bfqgyabop2");
</script>