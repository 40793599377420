import { Company } from "./company";
import { Template } from "./template.models";
export interface JwtToken{
  app_key:string;
  secret_key:string;
}
export const Provider = {"shopify":"shopify", "prestashop" :"prestashop", "woocommerce" :"woocommerce", "opencart" :"opencart", "magento 1" :"magento 1", "magento 2" :"magento 2", "wix" :"wix", "autre": "autre", "epages":"epages"}

export class Site {
  id: number;
  url: string;
  trademark: string;
  company:Company
  logo: string;
  capture_site: string;
  about: string;
  site_key: string;
  selected:boolean=false;
  categories:Array<any>;
  subcategories?:Array<any>
  jwt_token:JwtToken
  JwtToken: JwtToken;
  can_export_review:boolean=false
  partners:Array<any>; 
  provider: "shopify" | "prestashop" | "woocommerce" | "opencart" | "magento 1" | "magento 2" | "wix" | "autre" | "epages" | "";
  page_profile?:string;
  contact_email?:string;
  language?:string;
  is_owner?:boolean
  permissions?:Array<any>
}


export class SubCategory{
  id:number
  name:string
  slug:string


}

export class Category{
  id:number
  name:string
  slug:string
  sub_category:Array<SubCategory>

}

export class Settings{

        site: number
        bbc: string
        sender_name: string
        reply_to_email: string
        invitation_order: string
        order_status:[]
        provider: string
        cms_token: string
        cms_secret: string
        url_shop: string
        send_delay: number
        command_pattern: string
        templates: Array<Template>
        template_id:number
        slack_token: string
        include_product: boolean 
        invite_past_customer: number
        apply_getmail: boolean
        integration: boolean
        plugin: boolean
        send_mail_to_developer: boolean
        widget_color: string
        subject: string

}