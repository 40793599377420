<ng-template #Model role="document" let-modal="close" >
    <div class="modal-header p-2">
        <h5 class="modal-title" i18n>Activation de compte </h5>
        <button type="button" class="close" aria-hidden="true" (click)="modal('Cross click')">×</button>
    </div>
    <div class="modal-body">
        <h5 i18n>Votre compte est désactivé </h5>
        <p class="text-dark font-14" i18n>Voulez vous activer votre compte certishopping ?</p>
     
    </div>
    <div class="modal-footer p-2">
        <button type="button" class="btn btn-light waves-effect" (click)="modal('Cross click')"
            (click)="modal('Cross click')">Fermer</button>
        <button type="button" (click)="centerModal(centerDataModal)" class="btn btn-success" i18n>Oui</button>
    </div>
</ng-template>




<!-- Center Modal -->
<ng-template #centerDataModal let-modal="close" >
    <div class="modal-body ">
      <h4 class="mb-4" i18n>Entrez votre email</h4>
        <div class="form-group mb-2" [formGroup]="ConfirmFormvalidation">
            <div class="input-group">
              <input type="email" formControlName="email" class="form-control" 
             id="email" placeholder="Email" [ngClass]="{'is-invalid': confirm.email.dirty && confirm.email.errors}" />
             <div *ngIf="confirm.email.dirty  && confirm.email.errors" class="invalid-feedback">
                <span *ngIf="confirm.email.errors.required" i18n>Veuillez Entrez votre email.</span>
                <span *ngIf="confirm.email.errors.email" i18n>Veuillez Entrez un email valide.</span>
            </div>
            </div>
          </div>


        <div class="modal-footer ">

            <button (click)="modal('Cross click') " type="button " class="btn btn-light " i18n>Annuler</button>
            <button  (click)="ActivateAccount()" type="button " class="btn btn-success " i18n>Confirmer</button>
        </div>
    </div>
</ng-template>
