<ng-template #PaypalModal let-modal="close">
    <div class="model-body p-1">
        <p><span (click)="modal('Cross click')" class="mdi mdi-close float-right font-20"></span></p>
        <app-ui-preloader [display]="loading"></app-ui-preloader>
        <div id="form-field mt-3" *ngIf="payPalConfig">
            <ngx-paypal [config]="payPalConfig"></ngx-paypal>
        </div>

    </div>

</ng-template>
