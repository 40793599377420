import { Component, OnInit,Input,ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormGroup,FormControl,FormArray } from '@angular/forms';
import { HttpParams } from '@angular/common/http';
import { ApiService } from 'src/app/core/services/client/api.service';
import { Site } from 'src/app/core/models/site';
import {NgbModal, NgbModalConfig,NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { Billing } from "src/app/core/models/plan.models";
@Component({
  selector: 'app-editinvoice',
  templateUrl: './editinvoice.component.html',
  styleUrls: ['./editinvoice.component.scss']
})
export class EditinvoiceComponent implements OnInit {
  @Input() Site:Site;
  @Input() billing:Billing
  loading:boolean=false;
  Infoform: FormGroup;
  activeModal:NgbActiveModal
  @ViewChild('ModelEdit', {static: false }) ModelEdit;
  constructor(public formBuilder: FormBuilder,private modalService: NgbModal,
    private modalConfig: NgbModalConfig,private apiService:ApiService,) { 


      this.Infoform = this.formBuilder.group({
        name:['',Validators.required],
        last_name:['',Validators.required],
        company:['', Validators.required],
        city:['', Validators.required],
        state:['', Validators.required],
        country:['',Validators.required],
        phone: ['', [Validators.required, Validators.pattern("^\\+[0-9]{11,12}")]],
        siret: ['', [Validators.required, Validators.minLength(9)]],
        vat_number: ['', [Validators.required, Validators.minLength(4)]],
        address: ['', [Validators.required, Validators.minLength(6)]],
        zipcode: ['', []],
        display_name: [false]
      })
     }

  ngOnInit(){
    this.modalConfig.backdrop = 'static';
    this.modalConfig.keyboard =false
  }

  ngOnChanges(changes: any) {
    if(changes.billing.currentValue){
      let details = this.billing.details
      if(details){
        this.f.name.setValue(details.name,{emitEvent:false})
        this.f.last_name.setValue(details.last_name,{emitEvent:false})
        this.f.city.setValue(details.city,{emitEvent:false})
        this.f.state.setValue(details.state,{emitEvent:false})
        this.f.country.setValue(details.country,{emitEvent:false})
        this.f.siret.setValue(details.siret,{emitEvent:false})
        this.f.vat_number.setValue(details.vat_number,{emitEvent:false})
        this.f.address.setValue(details.address,{emitEvent:false})
        this.f.zipcode.setValue(details.zipcode,{emitEvent:false})
        this.f.display_name.setValue(details.display_name,{emitEvent:false})
      }

    }
    
  }
   /**
  * Returns form
  **/
    get f() {
      return this.Infoform.controls;
    }

    OpenModel() {
      this.activeModal = this.modalService.open(this.ModelEdit, {windowClass: '' });
    }
  

    UpdateInfo(){
      let data = this.Infoform.value
      data['site'] = this.Site.id
      this.loading = true;
      if(this.billing.details){
        this.apiService.updatebillingInformation(this.billing.details.id,data).subscribe(
          data => {
            this.loading = false;
            this.billing.details = data
            if(this.activeModal){this.activeModal.dismiss()}
          },error => {})
      }
     
    
    }
  

}
