import { Component, ViewEncapsulation, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import {NgbModal, NgbModalConfig,NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/core/services/member/auth.services';
import { FormBuilder, Validators, FormGroup,FormControl,FormArray } from '@angular/forms';
import { MemberService } from 'src/app/core/services/member/api.member.service';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { ActivatedRoute,Router } from "@angular/router";
@Component({
  selector: 'app-activate-account',
  templateUrl: './activate-account.component.html',
  styleUrls: ['./activate-account.component.scss']
})
export class ActivateAccountComponent implements OnInit {

  @ViewChild('Model',{static:false}) Model;
  activeModal:NgbActiveModal;
  Subscription:Subscription;
  ConfirmFormvalidation:FormGroup;
  basicsubmit: boolean;
  loading:false;
  constructor(private authenticationService:AuthenticationService, private modalService: NgbModal,public formBuilder: FormBuilder,private memberService:MemberService, private router:Router,) {
    this.authenticationService.accountdeactivated$().subscribe(
      ()=>{
          this.open();
      }
    )
  
  }

  ngOnInit(): void {
  /**
     * Basic form validation
     */
   this.ConfirmFormvalidation = this.formBuilder.group({
    email: ['', [Validators.required,Validators.email]],
   
  });

  this.ConfirmFormvalidation.valueChanges.subscribe(
    ()=>{
      if(!this.ConfirmFormvalidation.invalid){
        this.basicsubmit = false
      }else{
        this.basicsubmit = true
      }
    }
  )

  this.basicsubmit = true;

  }
  get confirm(){
    return this.ConfirmFormvalidation.controls;
    
    }
  // open modal
  open(): void {
    
    this.activeModal = this.modalService.open(this.Model, {centered: true,ariaLabelledBy: 'modal-basic-title', });
  }
  // close modal
  close(): void {
     if(this.activeModal){this.activeModal.close()}
  }

/**
   * Open cconformation
   * @param centerDataModal center modal data
   */
 centerModal(centerDataModal: string) {

  this.activeModal = this.modalService.open(centerDataModal, { centered: true,  windowClass: 'confirme-dialog' });
}

ActivateAccount(){
  let value = this.ConfirmFormvalidation.value

  this.memberService.activate(value).subscribe(data=>{

     if(data.code != 200){
        Swal.fire({
          text: data['message'],
          type: 'error',
        })
      }else{ 
        Swal.fire({
          text: data['message'],
          type: 'success',
        }).then((data) => {
          window.location.href = 'members/auth/login'
        })
      }
    },error=>{
      this.loading = false
    }) 
  }
}
