import { ChangeDetectorRef, Component,OnInit, ElementRef, Inject, OnDestroy, ViewChild} from '@angular/core';
import {  PLATFORM_ID } from '@angular/core';
import {  isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/core/services/client/api.service';
import { Billing } from "src/app/core/models/plan.models";
import { Company } from "src/app/core/models/company";
import { AuthenticationService } from "src/app/core/services/client/auth.service";
import { Site, Provider } from 'src/app/core/models/site';
import { PaypalcheckoutComponent } from "../paypalcheckout/paypalcheckout.component";
import { User } from "src/app/core/models/auth.models";
import {IPayPalConfig,ICreateOrderRequest } from 'ngx-paypal';
import Swal from 'sweetalert2';
import {TranslateService} from '@ngx-translate/core';
import { EditinvoiceComponent } from "../editinvoice/editinvoice.component";
import { StripeService } from 'ngx-stripe';
import { StripeComponent } from "../stripe/stripe.component";
import { UserPlan, PlanPricing, PlanType } from "src/app/core/models/plan.models";

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {
  User:User;
  Site:Site;
  PlanType=PlanType;
  breadCrmbItems: Array<{}>;
  pk:number
  billing:Billing
  activeModal:NgbActiveModal
  loading = false
  loading_pay=false
  Company:Company
  basicFormvalidation: FormGroup;
  amount: number;  
  currency:string="euro";
  clientSecret:any
  PlanPricing:PlanPricing
  planName:string;
  UserPlan:UserPlan
  payment_method:string='stripe';
  payer_id:string 
  order_id:string
  TRANSLATEMESSAAGE:Array<any>
  @ViewChild(EditinvoiceComponent,{static:false}) Editinvoice;
  @ViewChild(PaypalcheckoutComponent,{static:false}) PaypalcheckoutComponent;
  @ViewChild(StripeComponent,{static:false}) StripeComponent;
  constructor(private router:Router,
              private cd: ChangeDetectorRef,
              @Inject(PLATFORM_ID) private platformId: any,
              private authenticationService:AuthenticationService,
              private apiService:ApiService,
              private translate:TranslateService,
              private modalService:NgbModal,
              public formBuilder: FormBuilder,
              private stripeService: StripeService,
              private activateroute:ActivatedRoute) { 
                this.User = this.authenticationService.currentUser()
                this.Company = this.User.getselectCompany()
                this.Site = this.User.getselectSite()
                if(this.Site.provider ==  Provider.wix || this.Site.provider ==  Provider.shopify){
                  this.currency = "dollar";
                }

                this.UserPlan = this.Company.plan    
                if (this.UserPlan){
                  this.PlanPricing = this.UserPlan.details;
                  
                }

                this.activateroute.params.subscribe(
                  params=>{
                      try {
                        this.pk = parseInt(params['id'])   
                      } catch (error) {
                        window.location.href = '/facture'
                      }
                    
            
                  }
                )


              }

  ngOnInit() {
    this.translate.get(['facturation.submit', 'facturation.pay']).subscribe((data:any)=> {
      this.TRANSLATEMESSAAGE = data
    })
    this.fetchData()
  }


   /**
   * Open cconformation
   * @param centerDataModal center modal data
   */
  centerModal(centerDataModal: string) {
    if(this.activeModal){this.activeModal.dismiss()}
    this.activeModal = this.modalService.open(centerDataModal, { centered: true, });
    return false;
  }

   /**
   * Open conformation
   * @param centerDataModal center modal data
   */
  OpenStripeCardModal() {
    this.createStripeCheckout()
  }

  onSuccess(token) {
    this._SubmitData()
  }

  PaypalCheckout(){
    if(this.activeModal){this.activeModal.dismiss()}
    this.PaypalcheckoutComponent.checkout()
  }


  fetchData(){
    this.loading = true
    let data = {site:this.Site.id}
      this.apiService.billingItem(this.pk, data).subscribe(
        (data:any) => {
          this.loading = false;
          this.billing=data
          this.PlanPricing = this.billing.order.plan_pricing
          this.amount = this.billing.total
          this.currency = this.billing.currency
          if(this.PlanPricing){
            this.planName = this.PlanPricing.plan_type
          }
          
         
        },error => {

          this.loading = false;

        })
  }

  createStripeCheckout(){
      this.loading_pay = true
      let params = {site:this.Site.id}
      this.apiService.create_stripe_checkout(this.pk, params).subscribe(
        result => {
          this.clientSecret = result.clientSecret
          this.loading_pay = false;
          if(this.activeModal){this.activeModal.dismiss()}
          this.StripeComponent.checkout()
        },error => {
          this.loading_pay = false;

        })
  }



  protected _SubmitData(){
   
    let data = {site:this.Site.id ,payment_method:'stripe' }
    this.loading_pay = true
    this.apiService.updatebillingItem(this.pk,data).subscribe(
      result => {
        this.loading_pay = false;
        this.loading =  false;
        if(this.activeModal){this.activeModal.dismiss()}
        if(result.code == 200){
          this.billing = result.data
          Swal.fire({
            text: result.message,
            type:"success",
            showCancelButton: false,
            allowOutsideClick:false,
          }).then((result) => {
            if (result.value) {
              this.router.navigate([])
            }
          });
        }else{
          Swal.fire({
            text: result.message,
            type:"error",
            showCancelButton: false,
          }).then((result) => {
            if (result.value) {
              this.router.navigate([])
            }
          });
        }
      },error => {
        this.loading_pay = false;
        this.loading =  false
      })

  }


  CapturePayPalOrder(authorization){
    this.payment_method = 'paypal'
    let data = {site:this.Site.id ,payment_method:this.payment_method,order_id:authorization.orderID}
    this.loading_pay = true
    this.apiService.updatebillingItem(this.billing.id,data).subscribe(
      data => {

        this.loading_pay = false;
        this.billing = data
        if(this.activeModal){this.activeModal.dismiss()}
        Swal.fire({
          text: data.message,
          type:"success",
          showCancelButton: false,
          allowOutsideClick:false,
        }).then((result) => {
          if (result.value) {
            window.location.reload()

          }
      });
      },error => {

        this.loading_pay = false;
        Swal.fire({
          text: error,
          type:"error",
          showCancelButton: true,
        })

      })

  }

 
  EditInfo(){
    this.Editinvoice.OpenModel()
  }

}
