<div class="container-fluid pt-2">
    <div class="row">
        <div class="col-12 col-md-9 col-xl-9" >
            <div class="row">
                <div class="col-12">
                    <ngb-alert  [dismissible]="false" type="warning" *ngIf="!UserPlan?.active">
                        <i class="mdi mdi-alert-outline mr-1"></i>
                        <span><span class="mr-1 font-weight-bold" i18n>Plan désactivé.</span>
                          <span class="ml-1" i18n>Veuillez résoudre les problèmes de paiement associés à votre compte!</span>
                        </span><br>
                    </ngb-alert>

                    <div class="card" translate="no">
                        <div class="card-body">
                            <app-ui-preloader [display]="loading"></app-ui-preloader> 
                            <div class="row">
                                <div class="col-12">
                                    <h3   class="d-flex">
                                        <span i18n>Mon Plan</span>
                                    </h3>
                                    <div class="d-flex justify-content-between" *ngIf="UserPlan?.details.plan_type =='FREE'">
                                        <p>Certishopping {{UserPlan?.details?.plan_type}}</p>
                                        <p>
                                            <span>{{0 | currency:UserPlan?.details?.currency}}</span>
                                        </p>
                                    </div>
                                    
                                    <div class="d-flex justify-content-between" *ngIf="UserPlan?.details.plan_type !='FREE'">
                                        <p>Certishopping {{UserPlan?.details?.plan_type}}</p>
                                        <p><span>{{UserPlan?.details?.price | currency:UserPlan?.details?.currency}}</span></p>
                                    </div>
                                    <div  *ngIf="UserPlan?.details.plan_type !='FREE'">
                                        <div class="d-flex justify-content-between">
                                            <p class="mb-1" i18n>Commandes mensuelles estimées</p>
                                            <p >{{UserPlan?.details?.orders_monthly}}</p>
                                        </div>
                                       
                                        <div class="d-flex justify-content-between">
                                            <p class="mb-1" i18n>Votre abonnement sera expiré le</p>
                                            <p >{{UserPlan?.expire | date}}</p>
                                        </div>
                                        <div class="d-flex justify-content-between">
                                            <p class="mb-1" i18n>Commandes cette période</p>
                                            <p><span class="font-weight-bold">{{UserPlan?.nbr_mail_used}}</span><span class="ml-1 mr-1" i18n>sur</span>{{UserPlan?.details?.max_number_invitation}}</p>
                                        </div>
                                    </div>
                                    
                                    <!-- <div *ngIf="UserPlan?.details.plan_type !='FREE'">
                                        <p class="mr-2"><span class="font-weight-bold">{{UserPlan?.nbr_mail_used}}</span><span class="ml-1 mr-1" i18n>sur</span>{{UserPlan?.details?.max_number_invitation}}</p>
                                        <div>
                                            <ngb-progressbar class="bg-blue" height="5px" [value]="UserPlan.percent_nbr_mail_used"></ngb-progressbar>
                                        </div>
                                    </div> -->
                                </div>
    
                            </div>
                        </div>
                    </div>
                </div>


                <div class="col-12" >
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12">
                                    <h3 i18n>Plans</h3>
                                    <p i18n>Choisissez un plan flexible</p>
                                </div>
                                <div class="col-12">
                                    <h5 i18n>Commandes mensuelles estimées</h5>
                                    <ng5-slider (valueChange)="onOrdersChange($event)" [(value)]="orderNumber" [options]="slider.option" ></ng5-slider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 mb-3" *ngIf="Site.provider ==  Provider.wix">
                    <div class="d-flex justify-content-center custom-switches">
                        <h5 class="mr-2 font-weight-bold mt-1" [ngClass]="{'text-dark': billing=='monthly'}" i18n>Mensuel</h5>
                        <ui-switch (change)="onBillingChange($event)" defaultBoColor="#dfdfdf" color="#4a81d4"  class="ml-1"></ui-switch>
                        <h5 class="ml-2 font-weight-bold mt-1" [ngClass]="{'text-dark': billing=='yearly'}" ><span i18n>Annuel</span> <small class="ml-1 save-up" i18n>Économiser jusqu'à 20%</small></h5>
                    </div>
                </div>
                <!-- <div class="col-12" >
                    <div class="row">
                        <div class="col">
                            <div (click)="changePlan('STARTER')" class="card card-plan h-100" [ngClass]="{'card-pricing': planDetails.starter.selected, 'disabled':planDetails.starter.disabled}">
                                <div class="card-body">
                                    <h4 class="text-center mb-3">
                                        <div class="checkbox checkbox-blue checkbox-circle mb-2">
                                            <input id="checkbox-starter" type="checkbox" [checked]="planDetails.starter.selected" disabled>
                                            <label for="checkbox-starter">
                                                <span class="checkbox-label">{{planDetails.starter.name}}</span>
                                            </label>
                                        </div>
                                        
                                    </h4>
                                    <p><span class="mdi mdi-check mr-1 "></span><span i18n>Avis site & produits</span></p> 
                                    <p><span class="mdi mdi-check mr-1 "></span><span i18n>Page de produit</span></p>
                                    <p><span class="mdi mdi-check mr-1 "></span><span i18n>Rich Snippets</span></p>
                                    <p><span class="mdi mdi-check mr-1 "></span><span i18n>Widget produit</span></p> 
                                    <p><span class="mdi mdi-check mr-1 "></span><span i18n>Social media share</span></p>
                                    <p><span class="mdi mdi-check mr-1 "></span><span i18n>Email personnalisable</span></p>
                                    <p><span class="mdi mdi-check mr-1 "></span><span i18n>Support par mail</span></p>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div (click)="changePlan('BOOSTER')" class="card card-plan h-100" [ngClass]="{'card-pricing':  planDetails.booster.selected, 'disabled':planDetails.booster.disabled}">
                                <div class="card-body">
                                    <h4 class="text-center mb-3">
                                        <div class="checkbox checkbox-blue checkbox-circle mb-2">
                                            <input id="checkbox-booster" type="checkbox" [checked]="planDetails.booster.selected" disabled>
                                            <label for="checkbox-booster">
                                                <span class="checkbox-label">{{planDetails.booster.name}}</span>
                                            </label>
                                        </div>
                                    </h4>
                                    <p><span class="mdi mdi-check mr-1 "></span><span i18n>Pack Starter</span></p> 
                                    <p><span class="mdi mdi-check mr-1 "></span><span i18n>Facebook & Instagram Ads</span></p>
                                    <p><span class="mdi mdi-check mr-1 "></span><span i18n>Google my business</span></p>
                                    <p><span class="mdi mdi-check mr-1 "></span><span i18n>Avis Facebook</span></p>
                                    <p><span class="mdi mdi-check mr-1 "></span><span i18n>Collecte Manuelle</span></p>
                                    <p><span class="mdi mdi-check mr-1 "></span><span i18n>Invitations & rappels</span></p>
                                    <p><span class="mdi mdi-check mr-1 "></span><span i18n>Widget produit avec Image</span></p>
                                    <p><span class="mdi mdi-check mr-1 "></span><span i18n>Groupement de produits</span></p>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div (click)="changePlan('PRO')" class="card card-plan h-100" [ngClass]="{'card-pricing': planDetails.pro.selected, 'disabled':planDetails.pro.disabled}">
                                <div class="card-body">
                                    <h4 class="text-center mb-3">
                                        <div class="checkbox checkbox-blue checkbox-circle mb-2">
                                            <input id="checkbox-pro" type="checkbox" [checked]="planDetails.pro.selected" disabled>
                                            <label for="checkbox-pro">
                                                <span class="checkbox-label">{{planDetails.pro.name}}</span>
                                            </label>
                                        </div>
                                    </h4>
                                    <p><span class="mdi mdi-check mr-1"></span><span i18n>Pack booster</span></p> 
                                    <p><span class="mdi mdi-check mr-1 "></span><span i18n>Jusqu'à 3 domaines</span></p>
                                    <p><span class="mdi mdi-check mr-1 "></span><span i18n>Google Ads</span></p> 
                                    <p><span class="mdi mdi-check mr-1 "></span><span i18n>Analyse de donnés</span></p>
                                    <p><span class="mdi mdi-check mr-1 "></span><span i18n>Reporting personnalisé</span></p>
                                    <p><span class="mdi mdi-check mr-1 "></span><span i18n>Support personnalisé et téléphonique</span></p>
                                </div>
                            </div>
                        </div>

                        <div class="col">
                            <div (click)="changePlan('ENTREPRISE')" class="card card-plan h-100" [ngClass]="{'card-pricing': planDetails.entreprise.selected, 'disabled':planDetails.entreprise.disabled}">
                                <div class="card-body">
                                    <h4 class="text-center mb-3">
                                        <div class="checkbox checkbox-blue checkbox-circle mb-2">
                                            <input id="checkbox-entreprise" type="checkbox" [checked]="planDetails.entreprise.selected" disabled>
                                            <label for="checkbox-entreprise">
                                                <span class="checkbox-label">{{planDetails.entreprise.name}}</span>
                                            </label>
                                        </div>
                                    </h4>
                                    <p><span class="mdi mdi-check mr-1"></span><span i18n>Pack pro</span></p>  
                                    <p><span class="mdi mdi-check mr-1 "></span><span i18n>Multi domaines</span></p>
                                    <p><span class="mdi mdi-check mr-1 "></span><span i18n>API access</span></p>
                                    <p><span class="mdi mdi-check mr-1 "></span><span i18n>Intégration Facebook & Google</span></p>
                                    <p><span class="mdi mdi-check mr-1 "></span><span i18n>Expert stratégique dédié</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div> -->

                <!-- <div class="col-12">
                    <ng-template [ngTemplateOutlet]="PlanTable"></ng-template>
                    
                </div> -->
            </div>
            
           

        </div>
        <div class="col-12 col-md-3 col-xl-3">
            <div class="card">
                <div class="card-body">
                    <app-ui-preloader [display]="loading"></app-ui-preloader>  
                    <ng-template [ngTemplateOutlet]="PackForNoWixStore" *ngIf="Site.provider !=  Provider.wix && (Site.provider !=  Provider.shopify || !isNewAppVersion)"></ng-template>
                    <ng-template [ngTemplateOutlet]="PackForWixStore" *ngIf="Site.provider ==  Provider.wix"></ng-template>
                    <ng-template [ngTemplateOutlet]="PackForShopifyStore" *ngIf="Site.provider ==  Provider.shopify && isNewAppVersion"></ng-template>
                </div>
            </div>
        </div>

        <div class="col-12" >
            <div class="row">
                <div class="col-12 col-md-3">
                    <div (click)="changePlan('STARTER')" class="card card-plan h-100" [ngClass]="{'card-pricing': planDetails.starter.selected, 'disabled':planDetails.starter.disabled || loading }">
                        <div class="card-body">
                            <h4 class="text-center mb-3">
                                <div class="checkbox checkbox-blue checkbox-circle mb-2">
                                    <input id="checkbox-starter" type="checkbox" [checked]="planDetails.starter.selected" disabled>
                                    <label for="checkbox-starter">
                                        <span class="checkbox-label">{{planDetails.starter.name}}</span>
                                    </label>
                                </div>
                                
                            </h4>
                            <p><span class="mdi mdi-check mr-1 "></span><span i18n>Avis site & produits</span></p> 
                            <p><span class="mdi mdi-check mr-1 "></span><span i18n>Page Seo (Google & Bing)</span></p>
                            <p><span class="mdi mdi-check mr-1 "></span><span i18n>Importer des avis</span></p>
                            <p><span class="mdi mdi-check mr-1 "></span><span i18n>Widget produit</span></p> 
                            <p><span class="mdi mdi-check mr-1 "></span><span i18n>Code QR</span></p> 
                            <p><span class="mdi mdi-check mr-1 "></span><span i18n>Social media share</span></p>
                            <p><span class="mdi mdi-check mr-1 "></span><span i18n>Email personnalisable</span></p>
                            <p><span class="mdi mdi-check mr-1 "></span><span i18n>Support par mail & chat</span></p>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-3">
                    <div (click)="changePlan('BOOSTER')" class="card card-plan h-100" [ngClass]="{'card-pricing':  planDetails.booster.selected, 'disabled':planDetails.booster.disabled || loading }">
                        <div class="card-body">
                            <h4 class="text-center mb-3">
                                <div class="checkbox checkbox-blue checkbox-circle mb-2">
                                    <input id="checkbox-booster" type="checkbox" [checked]="planDetails.booster.selected" disabled>
                                    <label for="checkbox-booster">
                                        <span class="checkbox-label">{{planDetails.booster.name}}</span>
                                    </label>
                                </div>
                            </h4>
                            <p><span class="mdi mdi-check mr-1 "></span><span i18n>Pack Starter</span></p> 
                            <p><span class="mdi mdi-check mr-1 "></span><span i18n>Meta ads</span></p>
                            <p><span class="mdi mdi-check mr-1 "></span><span i18n>Google my business</span></p>
                            <p><span class="mdi mdi-check mr-1 "></span><span i18n>Avis Facebook</span></p>
                            <p><span class="mdi mdi-check mr-1 "></span><span i18n>Traduction d'avis</span></p>
                            <p><span class="mdi mdi-check mr-1 "></span><span i18n>Générateur de réponses avec IA</span></p>
                            <p><span class="mdi mdi-check mr-1 "></span><span i18n>Flux Google Shopping</span></p>
                            <p><span class="mdi mdi-check mr-1 "></span><span i18n>Groupement de produits</span></p>
                            <p><span class="mdi mdi-check mr-1 "></span><span i18n>Autopilote</span></p>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-3">
                    <div (click)="changePlan('PRO')" class="card card-plan h-100" [ngClass]="{'card-pricing': planDetails.pro.selected, 'disabled':planDetails.pro.disabled || loading }">
                        <div class="card-body">
                            <h4 class="text-center mb-3">
                                <div class="checkbox checkbox-blue checkbox-circle mb-2">
                                    <input id="checkbox-pro" type="checkbox" [checked]="planDetails.pro.selected" disabled>
                                    <label for="checkbox-pro">
                                        <span class="checkbox-label">{{planDetails.pro.name}}</span>
                                    </label>
                                </div>
                            </h4>
                            <p><span class="mdi mdi-check mr-1"></span><span i18n>Pack booster</span></p> 
                            <p><span class="mdi mdi-check mr-1 "></span><span i18n>Jusqu'à 3 domaines</span></p>
                            <p><span class="mdi mdi-check mr-1 "></span><span i18n>Google Ads</span></p> 
                            <p><span class="mdi mdi-check mr-1 "></span><span i18n>Catalogue Pinterest</span></p> 
                            <p><span class="mdi mdi-check mr-1 "></span><span i18n>Catalogue Facebook</span></p> 
                            <p><span class="mdi mdi-check mr-1 "></span><span i18n>Analyse de donnés</span></p>
                            <p><span class="mdi mdi-check mr-1 "></span><span i18n>Reporting personnalisé</span></p>
                            <p><span class="mdi mdi-check mr-1 "></span><span i18n>Support personnalisé et téléphonique</span></p>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-md-3" *ngIf="Site.provider ==  Provider.shopify && isNewAppVersion">
                    <div (click)="changePlan('ENTREPRISE')" class="card card-plan h-100" [ngClass]="{'card-pricing': planDetails.entreprise.selected, 'disabled':planDetails.entreprise.disabled || loading }">
                        <div class="card-body">
                            <h4 class="text-center mb-3">
                                <div class="checkbox checkbox-blue checkbox-circle mb-2">
                                    <input id="checkbox-entreprise" type="checkbox" [checked]="planDetails.entreprise.selected" disabled>
                                    <label for="checkbox-entreprise">
                                        <span class="checkbox-label">{{planDetails.entreprise.name}}</span>
                                    </label>
                                </div>
                            </h4>
                            <p><span class="mdi mdi-check mr-1"></span><span i18n>Pack pro</span></p>  
                            <p><span class="mdi mdi-check mr-1 "></span><span i18n>Multi domaines</span></p>
                            <p><span class="mdi mdi-check mr-1 "></span><span i18n>Accès API</span></p>
                            <p><span class="mdi mdi-check mr-1 "></span><span i18n>Intégration Facebook & Google</span></p>
                            <p><span class="mdi mdi-check mr-1 "></span><span i18n>Expert stratégique dédié</span></p>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        <div class="col-12">
            <ng-template [ngTemplateOutlet]="PlanTable"></ng-template>
            
        </div>



    </div>
    <app-stripe [clientSecret]="clientSecret" [User]="User" [amount]="amount"></app-stripe>
    <app-paypalcheckout  [Site]="Site" [amount]="amount" [name]="planName" (CapturePayPalOrder)="CapturePayPalOrder($event)"></app-paypalcheckout>
</div>

<ng-template #PackForWixStore>
    <div class="row">
        <div class="col-12">
            <h4 i18n>Pack</h4>
            <p>
                <span class="mr-1">{{planName}}</span>
                <span class="mr-1" *ngIf="!planDetails.entreprise.selected">{{orderNumber}}</span>
                <span class="mr-1" *ngIf="planDetails.entreprise.selected">1000+</span>
                <span  i18n>commandes par mois</span>
            </p>
            <div>
                <div class="d-flex justify-content-between align-items-baseline mb-3">
                    <h4 class="m-0"  i18n>Facturation</h4>
                    <p *ngIf="billing=='monthly'" i18n>Mensuel</p>
                    <p *ngIf="billing=='yearly'"  i18n>Annuel</p>
                </div>
                
            </div>
            <div >
                <!-- <div class="d-flex justify-content-between align-items-baseline mb-2"  *ngIf="billing=='yearly'">
                    <h5 class="m-0">Prix</h5>
                    <span class="font-weight-bold text-decoration-line-through">
                        <span class="text-dark">
                            <span *ngIf="currency!='euro'">{{selectedPlan?.price?.monthly?.dollar | number: '1.2-2'}} <span >$</span></span>
                            <span *ngIf="currency=='euro'">{{selectedPlan?.price?.monthly?.euro | number: '1.2-2'}} <span >€</span></span>
                            <span >/</span>
                            <span i18n>mois</span>
                        </span>
                       
                    </span>
                </div> -->
                <!-- <div class="d-flex justify-content-between align-items-baseline mb-2" *ngIf="billing=='yearly'">
                    <h5 class="m-0">Remise</h5>
                    <span class="font-weight-bold">
                        <span>20%</span>
                    </span>
                </div> -->
                <div class="d-flex justify-content-between align-items-baseline mb-2">
                    <h4 class="m-0">Prix</h4>
                    <span class="font-weight-bold">
                        <span class="font-16">{{price | number: '1.2-2'}}<span *ngIf="currency=='euro'">€</span><span *ngIf="currency!='euro'">$</span></span>
                        <span > /</span>
                        <span i18n>mois</span>
                    </span>
                </div>

                
            </div>

            <div class="col-12 mt-5">
                <button (click)="Checkout()" type="button" class="btn btn-block btn-sm btn-success" i18n>Choisir</button>
            </div>


        </div>
    </div>
</ng-template>

<ng-template #PackForShopifyStore>
    <div class="row">
        <div class="col-12">
            <h5 i18n>Pack</h5>
            <p>
                <span class="mr-1">{{planName}}</span>
                <span class="mr-1" *ngIf="!planDetails.entreprise.selected">{{orderNumber}}</span>
                <span class="mr-1" *ngIf="planDetails.entreprise.selected">1000+</span>
                <span  i18n>commandes par mois</span>
            </p>
            <div class="mb-3">
                <div class="d-flex justify-content-between align-items-baseline ">
                    <h5 class="m-0"  i18n>Facturation</h5>
                    <p *ngIf="billing=='monthly'" i18n>Mensuel</p>
                    <p *ngIf="billing=='yearly'"  i18n>Annuel</p>
                </div>
               
            </div>
            <div>
                <div class="d-flex justify-content-between align-items-baseline mb-2">
                    <h5 class="m-0">Prix</h5>
                    <span class="font-weight-bold ">
                        <span>{{price }} <span *ngIf="currency=='euro'">€</span><span *ngIf="currency!='euro'">$</span></span>
                        <span >/</span>
                        <span i18n>mois</span>
                    </span>
                </div>
                
                <div class="d-flex justify-content-between align-items-baseline mb-2">
                    <h5 class="m-0">Remise</h5>
                    <span class="font-weight-bold">
                        <span>20%</span>
                    </span>
                </div>
                <!-- <div class="d-flex justify-content-between align-items-baseline">
                    <h5 class="m-0">Total</h5>
                    <span class="font-weight-bold">
                        <span>{{amount | number: '1.2-2'}} <span *ngIf="currency=='euro'">€</span><span *ngIf="currency!='euro'">$</span></span>
                        <span >/</span>
                        <span i18n>ann</span>
                    </span>
                </div> -->
                <p class="text-muted">*Facturation une fois par an</p>
            </div>

            <div class="col-12 mt-5">
                <button (click)="Checkout()" type="button" class="btn btn-block btn-sm btn-success" i18n>Choisir</button>
            </div>


        </div>
    </div>
</ng-template>

<ng-template #PackForNoWixStore>
    <div class="row">
        <div class="col-12">
            <h5 i18n>Pack</h5>
            <p *ngIf="orderNumber <= 1000">
                <span class="mr-1">{{planName}}</span>
                <span class="mr-1">{{orderNumber}}</span>
                <span  i18n>commandes par mois</span>
            </p>
            <p *ngIf="orderNumber > 1000" >ENTREPRISE</p>
        </div>
        <div class="col-12">
            <div class="d-flex justify-content-between align-items-baseline mb-3">
                <h5 class="m-0"  i18n>Facturation</h5>
                <p *ngIf="billing=='monthly'" i18n>Mensuel</p>
                <p *ngIf="billing=='yearly'"  i18n>Annuel</p>
            </div>
            
        </div>
        <hr>
        <div class="col-12" *ngIf="withDiscount && orderNumber <= 1000">
            <div class="d-flex justify-content-between align-items-baseline mb-2">
                <h5 class="m-0">Prix</h5>
                <span class="font-weight-bold">
                    <span>{{price | number: '1.2-2'}} <span *ngIf="currency=='euro'">€</span><span *ngIf="currency!='euro'">$</span></span>
                    <span >/</span>
                    <span i18n>mois</span>
                </span>
            </div>
            <div class="d-flex justify-content-between align-items-baseline mb-2">
                <h5 class="m-0"  i18n>Remise</h5>
                <span class="font-weight-bold"  i18n>2 mois</span>
            </div>
            <div class="d-flex justify-content-between  align-items-baseline mb-3">
                <h5 class="m-0"  i18n>Total HT</h5>
                <span class="font-weight-bold">
                    {{totalHt}} <span *ngIf="currency=='euro'">€</span><span *ngIf="currency!='euro'">$</span>
                    <span >/</span>
                    <span *ngIf="billing=='monthly'" i18n>mois</span>
                    <span *ngIf="billing=='yearly'" i18n>an</span>
                </span>
            </div>
            <div class="d-flex justify-content-between  align-items-baseline">
                <h5 class="m-0"  i18n>Total TTC</h5>
                <span class="font-weight-bold">
                    {{amount}} <span *ngIf="currency=='euro'">€</span><span *ngIf="currency!='euro'">$</span>
                    <span >/</span>
                    <span *ngIf="billing=='monthly'" i18n>mois</span>
                    <span *ngIf="billing=='yearly'" i18n>an</span>
                </span>
            </div>
        </div>
        <div class="col-12" *ngIf="!withDiscount && orderNumber <= 1000">
            <div class="d-flex justify-content-between align-items-baseline  mb-3">
                <h5 class="m-0">Prix</h5>
                <span class="font-weight-bold">
                    {{price | number: '1.2-2'}} <span *ngIf="currency=='euro'">€</span><span *ngIf="currency!='euro'">$</span>
                    <span >/</span>
                    <span i18n>mois</span>
                </span>
            </div>
            <div class="d-flex justify-content-between align-items-baseline mb-3">
                <h5 class="m-0"  i18n>Total HT</h5>
                <span class="font-weight-bold">
                    {{totalHt}} <span *ngIf="currency=='euro'">€</span><span *ngIf="currency!='euro'">$</span>
                    <span >/</span>
                    <span *ngIf="billing=='monthly'" i18n>mois</span>
                    <span *ngIf="billing=='yearly'" i18n>an</span>
                </span>
            </div>
            <div class="d-flex justify-content-between align-items-baseline">
                <h5 class="m-0"  i18n>Total TTC</h5>
                <span class="font-weight-bold">
                    {{amount}} <span *ngIf="currency=='euro'">€</span><span *ngIf="currency!='euro'">$</span>
                    <span >/</span>
                    <span *ngIf="billing=='monthly'" i18n>mois</span>
                    <span *ngIf="billing=='yearly'" i18n>an</span>
                </span>
            </div>
        </div>

        <!-- <div class="col-12 d-flex justify-content-center align-items-baseline" *ngIf="orderNumber <= 1000">
                <h2>{{price}}</h2>
                <span >/</span>
                <span *ngIf="billing=='monthly'" i18n>mois</span>
                <span *ngIf="billing=='yearly'" i18n>an</span>
        </div> -->
        <div class="col-12 d-flex justify-content-center align-items-baseline" *ngIf="orderNumber > 1000">
            <h2  i18n>Personnalisé</h2>
        </div>
        <div class="col-12 mt-2" *ngIf="orderNumber <= 1000">
            <h5  i18n>Paiement</h5>
            <div class="d-flex justify-content-between">
                    <div class="form-check payment-checkout-input d-flex align-items-center">
                    <input class="form-check-input" type="radio" name="PaymentType" value="paypal" id="paypalInput" (change)="onOptionChange($event.target.value)">
                    <!-- <label class="form-check-label" for="paypalInput">Paypal</label> -->
                    <label for="paypalInput"><img  src="assets/images/cards/paypal.png" height="32"></label>
                  </div>
                  <div class="form-check payment-checkout-input d-flex align-items-center mt-1">
                    <input class="form-check-input" type="radio" name="PaymentType" value="stripe" id="stripeInput" checked (change)="onOptionChange($event.target.value)">
                    <!-- <label class="form-check-label" for="stripeInput">Credit Card</label> -->
                    <label for="stripeInput">
                        <img for="stripeInput" src="assets/images/cards/visa.png"  height="32">
                        <img for="stripeInput" src="assets/images/cards/master.png"  height="32"></label>
                </div>
            </div>
           
        </div>
        
        <div class="col-12 mt-5" *ngIf="orderNumber <= 1000">
            <button (click)="Checkout()" type="button" class="btn btn-block btn-sm btn-success" i18n>Choisir</button>
        </div>
        <div class="col-12 mt-5" *ngIf="orderNumber > 1000">
            <button type="button" class="btn btn-block btn-sm btn-success" i18n>Nous contacter</button>
        </div>
    </div>

</ng-template>


<!-- Center Modal -->
<ng-template #PaymentModal let-modal="close">
    <div class="modal-header">
        <h4 class="modal-title" i18n>Veuillez choisir votre mode de paiement préféré.</h4>
        <button type="button" class="close" (click)="modal('Cross click')" aria-hidden="true">×</button>
    </div>
    <div class="modal-body p-1">
      <div class="text-center mt-4">
        <button id="btnPaypal"   type="button"   i18n>Paypal</button>
        <button id="btnStripe"  type="button" i18n>Credit Card</button>
    </div>
    </div>
  
</ng-template>

<ng-template #PlanTable>
    <h3 class="mb-2 mt-5" i18n>Notre comparaison</h3>
    <div class="table-responsive mt-2 mb-4">
        <table class="table table-borderless">
            <thead>
                <tr style="border-top:0px">
                    <th class="hide"></th>
                    <th class="text-center" [ngClass]="{'bg-blue': planDetails.starter.selected, 'text-white': planDetails.starter.selected}">{{planDetails.starter.name}}</th>
                    <th   class="text-center" [ngClass]="{'bg-blue': planDetails.booster.selected, 'text-white': planDetails.booster.selected}">{{planDetails.booster.name}}</th>
                    <th   class="text-center" [ngClass]="{'bg-blue': planDetails.pro.selected, 'text-white': planDetails.pro.selected}">{{planDetails.pro.name}}</th>
                    <th *ngIf="Site.provider ==  Provider.shopify"   class="text-center" [ngClass]="{'bg-blue': planDetails.entreprise.selected, 'text-white': planDetails.entreprise.selected}">{{planDetails.entreprise.name}}</th>
                </tr>
            </thead>
            <tbody>
                
                <tr>
                    <td class="font-weight-bold title text-blue" i18n>Génération de contenu</td>
                    <td class="text-center" [ngClass]="{'td-selected': planDetails.starter.selected}"></td>
                    <td class="text-center" [ngClass]="{'td-selected': planDetails.booster.selected}"></td>
                    <td class="text-center" [ngClass]="{'td-selected': planDetails.pro.selected}"></td>
                    <td *ngIf="Site.provider ==  Provider.shopify" class="text-center" [ngClass]="{'td-selected': planDetails.entreprise.selected}"></td>
                </tr>
                <tr >
                    <td i18n>Avis site</td>
                    <td class="text-center" [ngClass]="{'td-selected': planDetails.starter.selected}"><span class="mdi mdi-check mr-1  "></span></td>
                    <td class="text-center" [ngClass]="{'td-selected': planDetails.booster.selected}"><span class="mdi mdi-check mr-1  "></span></td>
                    <td class="text-center" [ngClass]="{'td-selected': planDetails.pro.selected}"><span class="mdi mdi-check mr-1  "></span></td>
                    <td *ngIf="Site.provider ==  Provider.shopify" class="text-center" [ngClass]="{'td-selected': planDetails.entreprise.selected}"><span class="mdi mdi-check mr-1  "></span></td>

                </tr>
                <tr>
                    <td i18n>Avis produits</td>
                    <td class="text-center" [ngClass]="{'td-selected': planDetails.starter.selected}"><span class="mdi mdi-check mr-1   "></span></td>
                    <td class="text-center" [ngClass]="{'td-selected': planDetails.booster.selected}"><span class="mdi mdi-check mr-1  "></span></td>
                    <td class="text-center" [ngClass]="{'td-selected': planDetails.pro.selected}"><span class="mdi mdi-check mr-1  "></span></td>
                    <td *ngIf="Site.provider ==  Provider.shopify" class="text-center" [ngClass]="{'td-selected': planDetails.entreprise.selected}"><span class="mdi mdi-check mr-1  "></span></td>
                </tr>
                 
                <tr >
                    <td i18n>Contenu Image</td>
                    <td class="text-center" [ngClass]="{'td-selected': planDetails.starter.selected}"><span class="mdi mdi-check mr-1  "></span></td>
                    <td class="text-center" [ngClass]="{'td-selected': planDetails.booster.selected}"><span class="mdi mdi-check mr-1  "></span></td>
                    <td class="text-center" [ngClass]="{'td-selected': planDetails.pro.selected}"><span class="mdi mdi-check mr-1  "></span></td>
                    <td *ngIf="Site.provider ==  Provider.shopify" class="text-center" [ngClass]="{'td-selected': planDetails.entreprise.selected}"><span class="mdi mdi-check mr-1  "></span></td>

                </tr> 
                <tr >
                    <td i18n>Contenu vidéo</td>
                    <td class="text-center" [ngClass]="{'td-selected': planDetails.starter.selected}"></td>
                    <td class="text-center" [ngClass]="{'td-selected': planDetails.booster.selected}"><span class="mdi mdi-check mr-1  "></span></td>
                    <td class="text-center" [ngClass]="{'td-selected': planDetails.pro.selected}"><span class="mdi mdi-check mr-1  "></span></td>
                    <td *ngIf="Site.provider ==  Provider.shopify" class="text-center" [ngClass]="{'td-selected': planDetails.entreprise.selected}"><span class="mdi mdi-check mr-1  "></span></td>

                </tr> 


                <tr>
                    <td i18>Partage des avis sur Twitter, Facebook</td>
                    <td class="text-center" [ngClass]="{'td-selected': planDetails.starter.selected}"><span class="mdi mdi-check mr-1  "></span></td>
                    <td class="text-center" [ngClass]="{'td-selected': planDetails.booster.selected}"><span class="mdi mdi-check mr-1  "></span></td>
                    <td class="text-center" [ngClass]="{'td-selected': planDetails.pro.selected}"><span class="mdi mdi-check mr-1  "></span></td>
                    <td *ngIf="Site.provider ==  Provider.shopify" class="text-center" [ngClass]="{'td-selected': planDetails.entreprise.selected}"><span class="mdi mdi-check mr-1  "></span></td>

                </tr>

                <tr>
                    <td i18n>Importer des avis</td>
                    <td class="text-center" [ngClass]="{'td-selected': planDetails.starter.selected}"><span class="mdi mdi-check mr-1  "></span></td>
                    <td class="text-center" [ngClass]="{'td-selected': planDetails.booster.selected}"><span class="mdi mdi-check mr-1  "></span></td>
                    <td class="text-center" [ngClass]="{'td-selected': planDetails.pro.selected}"><span class="mdi mdi-check mr-1  "></span></td>
                    <td *ngIf="Site.provider ==  Provider.shopify" class="text-center" [ngClass]="{'td-selected': planDetails.entreprise.selected}"><span class="mdi mdi-check mr-1  "></span></td>

                </tr>
                <tr>
                    <td i18n>Code QR</td>
                    <td class="text-center" [ngClass]="{'td-selected': planDetails.starter.selected}"><span class="mdi mdi-check mr-1  "></span></td>
                    <td class="text-center" [ngClass]="{'td-selected': planDetails.booster.selected}"><span class="mdi mdi-check mr-1  "></span></td>
                    <td class="text-center" [ngClass]="{'td-selected': planDetails.pro.selected}"><span class="mdi mdi-check mr-1  "></span></td>
                    <td *ngIf="Site.provider ==  Provider.shopify" class="text-center" [ngClass]="{'td-selected': planDetails.entreprise.selected}"><span class="mdi mdi-check mr-1  "></span></td>

                </tr>
                <tr>
                    <td i18n>Collecte automatique</td>
                    <td class="text-center" [ngClass]="{'td-selected': planDetails.starter.selected}"><span class="mdi mdi-check mr-1  "></span></td>
                    <td class="text-center" [ngClass]="{'td-selected': planDetails.booster.selected}"><span class="mdi mdi-check mr-1  "></span></td>
                    <td class="text-center" [ngClass]="{'td-selected': planDetails.pro.selected}"><span class="mdi mdi-check mr-1  "></span></td>
                    <td *ngIf="Site.provider ==  Provider.shopify" class="text-center" [ngClass]="{'td-selected': planDetails.entreprise.selected}"><span class="mdi mdi-check mr-1  "></span></td>

                </tr>
                <tr>
                    <td i18n>Collecte manuelle d'avis site</td>
                    <td class="text-center" [ngClass]="{'td-selected': planDetails.starter.selected}"><span class="mdi mdi-check mr-1  "></span></td>
                    <td class="text-center" [ngClass]="{'td-selected': planDetails.booster.selected}"><span class="mdi mdi-check mr-1  "></span></td>
                    <td class="text-center" [ngClass]="{'td-selected': planDetails.pro.selected}"><span class="mdi mdi-check mr-1  "></span></td>
                    <td *ngIf="Site.provider ==  Provider.shopify" class="text-center" [ngClass]="{'td-selected': planDetails.entreprise.selected}"><span class="mdi mdi-check mr-1  "></span></td>

                </tr>
                <tr>
                    <td i18n>Collecte manuelle d'avis produit</td>
                    <td class="text-center" [ngClass]="{'td-selected': planDetails.starter.selected}"><span class="mdi mdi-check mr-1  "></span></td>
                    <td class="text-center" [ngClass]="{'td-selected': planDetails.booster.selected}"><span class="mdi mdi-check mr-1  "></span></td>
                    <td class="text-center" [ngClass]="{'td-selected': planDetails.pro.selected}"><span class="mdi mdi-check mr-1  "></span></td>
                    <td *ngIf="Site.provider ==  Provider.shopify" class="text-center" [ngClass]="{'td-selected': planDetails.entreprise.selected}"><span class="mdi mdi-check mr-1  "></span></td>

                </tr>
                <tr>
                    <td i18n>Rappels de demande d'avis</td>
                    <td class="text-center" [ngClass]="{'td-selected': planDetails.starter.selected}"><span class="mdi mdi-check mr-1  "></span></td>
                    <td class="text-center" [ngClass]="{'td-selected': planDetails.booster.selected}"><span class="mdi mdi-check mr-1  "></span></td>
                    <td class="text-center" [ngClass]="{'td-selected': planDetails.pro.selected}"><span class="mdi mdi-check mr-1  "></span></td>
                    <td *ngIf="Site.provider ==  Provider.shopify" class="text-center" [ngClass]="{'td-selected': planDetails.entreprise.selected}"><span class="mdi mdi-check mr-1  "></span></td>

                </tr>
                <tr>
                    <td i18n>Rapports statistiques</td>
                    <td class="text-center" [ngClass]="{'td-selected': planDetails.starter.selected}"><span class="mdi mdi-check mr-1  "></span></td>
                    <td class="text-center" [ngClass]="{'td-selected': planDetails.booster.selected}"><span class="mdi mdi-check mr-1  "></span></td>
                    <td class="text-center" [ngClass]="{'td-selected': planDetails.pro.selected}"><span class="mdi mdi-check mr-1  "></span></td>
                    <td *ngIf="Site.provider ==  Provider.shopify" class="text-center" [ngClass]="{'td-selected': planDetails.entreprise.selected}"><span class="mdi mdi-check mr-1  "></span></td>

                </tr>
                <tr>
                    <td i18n >Plateforme multi-sites</td>
                    <td class="text-center" [ngClass]="{'td-selected': planDetails.starter.selected}"></td>
                    <td class="text-center" [ngClass]="{'td-selected': planDetails.booster.selected}"></td>
                    <td class="text-center" [ngClass]="{'td-selected': planDetails.pro.selected}"><span class="mdi mdi-check mr-1  "></span></td>
                    <td *ngIf="Site.provider ==  Provider.shopify" class="text-center" [ngClass]="{'td-selected': planDetails.entreprise.selected}"><span class="mdi mdi-check mr-1  "></span></td>
                </tr>
                <tr>
                    <td i18n >Support par mail & chat</td>
                    <td class="text-center" [ngClass]="{'td-selected': planDetails.starter.selected}"><span class="mdi mdi-check mr-1  "></span></td>
                    <td class="text-center" [ngClass]="{'td-selected': planDetails.booster.selected}"><span class="mdi mdi-check mr-1  "></span></td>
                    <td class="text-center" [ngClass]="{'td-selected': planDetails.pro.selected}"><span class="mdi mdi-check mr-1  "></span></td>
                    <td *ngIf="Site.provider ==  Provider.shopify" class="text-center" [ngClass]="{'td-selected': planDetails.entreprise.selected}"><span class="mdi mdi-check mr-1  "></span></td>
                </tr>
                <tr>
                    <td i18n >Support personnalisé et téléphonique </td>
                    <td class="text-center" [ngClass]="{'td-selected': planDetails.starter.selected}"></td>
                    <td class="text-center" [ngClass]="{'td-selected': planDetails.booster.selected}"></td>
                    <td class="text-center" [ngClass]="{'td-selected': planDetails.pro.selected}"><span class="mdi mdi-check mr-1  "></span></td>
                    <td *ngIf="Site.provider ==  Provider.shopify" class="text-center" [ngClass]="{'td-selected': planDetails.entreprise.selected}"><span class="mdi mdi-check mr-1  "></span></td>
                </tr>

                <tr>
                    <td i18n >Expert stratégique dédié</td>
                    <td class="text-center" [ngClass]="{'td-selected': planDetails.starter.selected}"></td>
                    <td class="text-center" [ngClass]="{'td-selected': planDetails.booster.selected}"></td>
                    <td class="text-center" [ngClass]="{'td-selected': planDetails.pro.selected}"></td>
                    <td *ngIf="Site.provider ==  Provider.shopify" class="text-center" [ngClass]="{'td-selected': planDetails.entreprise.selected}"><span class="mdi mdi-check mr-1  "></span></td>
                </tr>
                <ng-template [ngTemplateOutlet]="ContentModeration"></ng-template>
                <ng-template [ngTemplateOutlet]="AdvancedCustomization"></ng-template>
                <ng-template [ngTemplateOutlet]="Marketing"></ng-template>
                <ng-template [ngTemplateOutlet]="OnSiteDisplay"></ng-template>
                <ng-template [ngTemplateOutlet]="PlatformManagement"></ng-template>
                

                
            </tbody>
        </table>
    </div>
    
</ng-template>

<ng-template #ContentModeration>
    <tr>
            <td class="font-weight-bold title  text-blue" i18n>Moderation</td>
            <td class="text-center" [ngClass]="{'td-selected': planDetails.starter.selected}"></td>
            <td class="text-center" [ngClass]="{'td-selected': planDetails.booster.selected}"></td>
            <td class="text-center" [ngClass]="{'td-selected': planDetails.pro.selected}"></td>
            <td *ngIf="Site.provider ==  Provider.shopify" class="text-center" [ngClass]="{'td-selected': planDetails.entreprise.selected}"></td>
    
    </tr>
    <tr>
        <td i18n >Gestion Des Avis</td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.starter.selected}"><span class="mdi mdi-check mr-1  "></span></td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.booster.selected}"><span class="mdi mdi-check mr-1  "></span></td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.pro.selected}"><span class="mdi mdi-check mr-1  "></span></td>
        <td *ngIf="Site.provider ==  Provider.shopify" class="text-center" [ngClass]="{'td-selected': planDetails.entreprise.selected}"><span class="mdi mdi-check mr-1  "></span></td>
    </tr>
    <tr>
        <td i18n >Avis Facebook</td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.starter.selected}"></td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.booster.selected}"><span class="mdi mdi-check mr-1  "></span></td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.pro.selected}"><span class="mdi mdi-check mr-1  "></span></td>
        <td *ngIf="Site.provider ==  Provider.shopify" class="text-center" [ngClass]="{'td-selected': planDetails.entreprise.selected}"><span class="mdi mdi-check mr-1  "></span></td>
    </tr>
    <tr>
        <td i18n >Avis Google</td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.starter.selected}"></td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.booster.selected}"><span class="mdi mdi-check mr-1  "></span></td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.pro.selected}"><span class="mdi mdi-check mr-1  "></span></td>
        <td *ngIf="Site.provider ==  Provider.shopify" class="text-center" [ngClass]="{'td-selected': planDetails.entreprise.selected}"><span class="mdi mdi-check mr-1  "></span></td>
    </tr>
    <tr>
        <td i18n >Répondre au client</td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.starter.selected}"><span class="mdi mdi-check mr-1  "></span></td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.booster.selected}"><span class="mdi mdi-check mr-1  "></span></td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.pro.selected}"><span class="mdi mdi-check mr-1  "></span></td>
        <td *ngIf="Site.provider ==  Provider.shopify" class="text-center" [ngClass]="{'td-selected': planDetails.entreprise.selected}"><span class="mdi mdi-check mr-1  "></span></td>
    </tr>
    <tr>
        <td i18n >Publié automatique d'avis</td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.starter.selected}"><span class="mdi mdi-check mr-1  "></span></td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.booster.selected}"><span class="mdi mdi-check mr-1  "></span></td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.pro.selected}"><span class="mdi mdi-check mr-1  "></span></td>
        <td *ngIf="Site.provider ==  Provider.shopify" class="text-center" [ngClass]="{'td-selected': planDetails.entreprise.selected}"><span class="mdi mdi-check mr-1  "></span></td>
    </tr> 
    <tr>
        <td i18n>Générateur de réponses avec IA</td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.starter.selected}"><span class="mdi mdi-check mr-1  "></span></td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.booster.selected}"><span class="mdi mdi-check mr-1  "></span></td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.pro.selected}"><span class="mdi mdi-check mr-1  "></span></td>
        <td *ngIf="Site.provider ==  Provider.shopify" class="text-center" [ngClass]="{'td-selected': planDetails.entreprise.selected}"><span class="mdi mdi-check mr-1  "></span></td>
    </tr>

</ng-template>

<ng-template #AdvancedCustomization>
    <tr>
            <td class="font-weight-bold title  text-blue" i18n>Personnalisation avancée</td>
            <td class="text-center" [ngClass]="{'td-selected': planDetails.starter.selected}"></td>
            <td class="text-center" [ngClass]="{'td-selected': planDetails.booster.selected}"></td>
            <td class="text-center" [ngClass]="{'td-selected': planDetails.pro.selected}"></td>
            <td *ngIf="Site.provider ==  Provider.shopify" class="text-center" [ngClass]="{'td-selected': planDetails.entreprise.selected}"></td>    </tr>
    <tr>
        <td i18n>Éditeur d'invitation</td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.starter.selected}"><span class="mdi mdi-check mr-1  "></span></td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.booster.selected}"><span class="mdi mdi-check mr-1  "></span></td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.pro.selected}"><span class="mdi mdi-check mr-1  "></span></td>
        <td *ngIf="Site.provider ==  Provider.shopify" class="text-center" [ngClass]="{'td-selected': planDetails.entreprise.selected}"><span class="mdi mdi-check mr-1  "></span></td>
    </tr>
    <tr>
        <td i18n >Éditeur widget</td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.starter.selected}"><span class="mdi mdi-check mr-1  "></span></td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.booster.selected}"><span class="mdi mdi-check mr-1  "></span></td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.pro.selected}"><span class="mdi mdi-check mr-1  "></span></td>
        <td *ngIf="Site.provider ==  Provider.shopify" class="text-center" [ngClass]="{'td-selected': planDetails.entreprise.selected}"><span class="mdi mdi-check mr-1  "></span></td>
    </tr> 
    <tr>
        <td i18n>Accès à l'API RESTful</td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.starter.selected}"></td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.booster.selected}"></td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.pro.selected}"></td>
        <td *ngIf="Site.provider ==  Provider.shopify" class="text-center" [ngClass]="{'td-selected': planDetails.entreprise.selected}"><span class="mdi mdi-check mr-1  "></span></td>
    </tr>
</ng-template>

<ng-template #Marketing >
    <tr>
            <td class="font-weight-bold title  text-blue" i18n>Marketing</td>
            <td class="text-center" [ngClass]="{'td-selected': planDetails.starter.selected}"></td>
            <td class="text-center" [ngClass]="{'td-selected': planDetails.booster.selected}"></td>
            <td class="text-center" [ngClass]="{'td-selected': planDetails.pro.selected}"></td>
            <td *ngIf="Site.provider ==  Provider.shopify" class="text-center" [ngClass]="{'td-selected': planDetails.entreprise.selected}"></td>
    </tr>
    <tr>
        <td i18n >Google Rich Snippets</td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.starter.selected}"><span class="mdi mdi-check mr-1  "></span></td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.booster.selected}"><span class="mdi mdi-check mr-1  "></span></td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.pro.selected}"><span class="mdi mdi-check mr-1  "></span></td>
        <td *ngIf="Site.provider ==  Provider.shopify" class="text-center" [ngClass]="{'td-selected': planDetails.entreprise.selected}"><span class="mdi mdi-check mr-1  "></span></td>
    </tr>
    <tr>
        <td i18n >Flux Google shopping</td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.starter.selected}"></td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.booster.selected}"><span class="mdi mdi-check mr-1  "></span></td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.pro.selected}"><span class="mdi mdi-check mr-1  "></span></td>
        <td *ngIf="Site.provider ==  Provider.shopify" class="text-center" [ngClass]="{'td-selected': planDetails.entreprise.selected}"><span class="mdi mdi-check mr-1  "></span></td>
    </tr>
    <tr>
        <td i18n >Meta ads</td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.starter.selected}"></td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.booster.selected}"><span class="mdi mdi-check mr-1  "></span></td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.pro.selected}"><span class="mdi mdi-check mr-1  "></span></td>
        <td *ngIf="Site.provider ==  Provider.shopify" class="text-center" [ngClass]="{'td-selected': planDetails.entreprise.selected}"><span class="mdi mdi-check mr-1  "></span></td>
    </tr>
    <tr>
        <td i18n >Catalogue Pinterest</td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.starter.selected}"></td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.booster.selected}"><span class="mdi mdi-check mr-1  "></span></td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.pro.selected}"><span class="mdi mdi-check mr-1  "></span></td>
        <td *ngIf="Site.provider ==  Provider.shopify" class="text-center" [ngClass]="{'td-selected': planDetails.entreprise.selected}"><span class="mdi mdi-check mr-1  "></span></td>
    </tr>
    <tr>
        <td i18n >Catalogue Facebook</td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.starter.selected}"></td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.booster.selected}"><span class="mdi mdi-check mr-1  "></span></td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.pro.selected}"><span class="mdi mdi-check mr-1  "></span></td>
        <td *ngIf="Site.provider ==  Provider.shopify" class="text-center" [ngClass]="{'td-selected': planDetails.entreprise.selected}"><span class="mdi mdi-check mr-1  "></span></td>
    </tr>
</ng-template>


<ng-template #OnSiteDisplay>
    <tr>
            <td class="font-weight-bold title  text-blue" i18n>Affichage sur site</td>
            <td class="text-center" [ngClass]="{'td-selected': planDetails.starter.selected}"></td>
            <td class="text-center" [ngClass]="{'td-selected': planDetails.booster.selected}"></td>
            <td class="text-center" [ngClass]="{'td-selected': planDetails.pro.selected}"></td>
            <td *ngIf="Site.provider ==  Provider.shopify" class="text-center" [ngClass]="{'td-selected': planDetails.entreprise.selected}"></td>
    </tr>
    <tr>
        <td i18n>Star ratings</td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.starter.selected}"><span class="mdi mdi-check mr-1  "></span></td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.booster.selected}"><span class="mdi mdi-check mr-1  "></span></td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.pro.selected}"><span class="mdi mdi-check mr-1  "></span></td>
        <td *ngIf="Site.provider ==  Provider.shopify" class="text-center" [ngClass]="{'td-selected': planDetails.entreprise.selected}"><span class="mdi mdi-check mr-1  "></span></td>
    </tr>

    <tr >
        <td i18n>Page de profil</td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.starter.selected}"><span class="mdi mdi-check mr-1  "></span></td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.booster.selected}"><span class="mdi mdi-check mr-1  "></span></td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.pro.selected}"><span class="mdi mdi-check mr-1  "></span></td>
        <td *ngIf="Site.provider ==  Provider.shopify" class="text-center" [ngClass]="{'td-selected': planDetails.entreprise.selected}"><span class="mdi mdi-check mr-1  "></span></td>
    </tr>
    <tr>
        <td i18n>Affichez vos avis avec nos Widgets</td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.starter.selected}"><span class="mdi mdi-check mr-1  "></span></td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.booster.selected}"><span class="mdi mdi-check mr-1  "></span></td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.pro.selected}"><span class="mdi mdi-check mr-1  "></span></td>
        <td *ngIf="Site.provider ==  Provider.shopify" class="text-center" [ngClass]="{'td-selected': planDetails.entreprise.selected}"><span class="mdi mdi-check mr-1  "></span></td>
    </tr>
    <tr>
        <td i18n >Badge d'avis</td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.starter.selected}"><span class="mdi mdi-check mr-1  "></span></td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.booster.selected}"><span class="mdi mdi-check mr-1  "></span></td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.pro.selected}"><span class="mdi mdi-check mr-1  "></span></td>
        <td *ngIf="Site.provider ==  Provider.shopify" class="text-center" [ngClass]="{'td-selected': planDetails.entreprise.selected}"><span class="mdi mdi-check mr-1  "></span></td>
    </tr>
    <tr>
        <td i18n >Carrousel d'avis</td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.starter.selected}"><span class="mdi mdi-check mr-1  "></span></td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.booster.selected}"><span class="mdi mdi-check mr-1  "></span></td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.pro.selected}"><span class="mdi mdi-check mr-1  "></span></td>
        <td *ngIf="Site.provider ==  Provider.shopify" class="text-center" [ngClass]="{'td-selected': planDetails.entreprise.selected}"><span class="mdi mdi-check mr-1  "></span></td>

    </tr>
    <tr>
        <td i18n >Formulaire poster d'avis</td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.starter.selected}"><span class="mdi mdi-check mr-1  "></span></td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.booster.selected}"><span class="mdi mdi-check mr-1  "></span></td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.pro.selected}"><span class="mdi mdi-check mr-1  "></span></td>
        <td *ngIf="Site.provider ==  Provider.shopify" class="text-center" [ngClass]="{'td-selected': planDetails.entreprise.selected}"><span class="mdi mdi-check mr-1  "></span></td>
    </tr>
</ng-template>

<ng-template #PlatformManagement>
    <tr>
        <td class="font-weight-bold title  text-blue" i18n>Gestion de la plateforme</td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.starter.selected}"></td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.booster.selected}"></td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.pro.selected}"></td>
        <td *ngIf="Site.provider ==  Provider.shopify" class="text-center" [ngClass]="{'td-selected': planDetails.entreprise.selected}"></td>
    </tr>
    <tr>
        <td i18n >Liste de blocage des produits</td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.starter.selected}"></td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.booster.selected}"><span class="mdi mdi-check mr-1  "></span></td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.pro.selected}"><span class="mdi mdi-check mr-1  "></span></td>
        <td *ngIf="Site.provider ==  Provider.shopify" class="text-center" [ngClass]="{'td-selected': planDetails.entreprise.selected}"><span class="mdi mdi-check mr-1  "></span></td>
    </tr>
    <tr>
        <td i18n >Groupement de produits</td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.starter.selected}"></td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.booster.selected}"><span class="mdi mdi-check mr-1  "></span></td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.pro.selected}"><span class="mdi mdi-check mr-1  "></span></td>
        <td *ngIf="Site.provider ==  Provider.shopify" class="text-center" [ngClass]="{'td-selected': planDetails.entreprise.selected}"><span class="mdi mdi-check mr-1  "></span></td>
    </tr>
    <tr>
        <td i18n >Jusqu'à 3 domaines</td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.starter.selected}"></td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.booster.selected}"></td>
        <td class="text-center" [ngClass]="{'td-selected': planDetails.pro.selected}"><span class="mdi mdi-check mr-1  "></span></td>
        <td *ngIf="Site.provider ==  Provider.shopify" class="text-center" [ngClass]="{'td-selected': planDetails.entreprise.selected}"><span class="mdi mdi-check mr-1  "></span></td>
    </tr>
</ng-template>
