import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CookieService } from '../cookie.service';
@Injectable({
  providedIn: 'root'
})
export class MemberService {
  apiUrl      = environment.apiUrl;
  constructor(private http: HttpClient, private cookieService: CookieService, 
    private router: Router) { 
      this.apiUrl      = environment.apiUrl +`/${this.cookieService.getCurentLocale()}/api`;
    }
    getMembersettings(profile_id): Observable<any> {  
      return this.http.get(`${this.apiUrl}/member/profile/${profile_id}/`);
    }
    UpdateMembersettings(profile_id, data): Observable<any> {  
      return this.http.put(`${this.apiUrl}/member/profile/${profile_id}/`,data);
    }
    desactivate(data): Observable<any> {  
      return this.http.post(`${this.apiUrl}/member/deactivate_account/`,data);
    }
    activate(data): Observable<any> {  
      return this.http.post(`${this.apiUrl}/member/activate_account/`,data);
    }
    RemoveReviews(pk): Observable<any> {  
      return this.http.delete(`${this.apiUrl}/member/review/${pk}/`);
    }
    getReviewList(params): Observable<any> {  
      return this.http.get(`${this.apiUrl}/member/review/`,{params});
    }
    getStatisticCount(params): Observable<any> {  
      return this.http.get(`${this.apiUrl}/member/review/statistics`,{params});
    }
    updateReview(pk ,data): Observable<any> {  
      return this.http.put(`${this.apiUrl}/member/review/${pk}/`,data);
    }

    GetUnsubscribed(pk): Observable<any> { 
      return this.http.get(`${this.apiUrl}/member/profile/${pk}/is_unsubscribed/`)
    }
    Subscribed(pk ,data): Observable<any> {  
      return this.http.put(`${this.apiUrl}/member/profile/${pk}/is_unsubscribed/`,data);
    }



}