<footer class="footer">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6">
       <b ><span  class="mr-1">Copyright  © 2017 - {{date.getFullYear()}}</span> <b style="color:#58c59e">Certishopping.com</b>. <span class="ml-1" i18n>Tous les droits réservés</span></b> 
       <!-- <b i18n> Copyright  © 2017 - 2020 <b style="color:#58c59e">Pulptech</b>. Tous les droits réservés</b> -->
      </div>
      <!-- <div class="col-md-6">
        <div class="text-md-right footer-links d-none d-sm-block">
          <a href="javascript:void(0);" (click)="switchLang('fr')" [ngClass]="{'text-primary':'fr' == CurentLocale}">Français (France)</a>
          <a href="javascript:void(0);" (click)="switchLang('en')" [ngClass]="{'text-primary':'en' == CurentLocale}">English (US)</a>
        </div>
      </div> -->
    </div>
  </div>
</footer>